import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import SurveyModal from "./SurveyModal"; // Import the SurveyModal component

function AdminSurvey() {
  return <h1>admin survey</h1>;
}

export default AdminSurvey;
