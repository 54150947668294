import React from "react";
import { DataGrid, GridToolbar, roRO } from "@mui/x-data-grid";

const MuiXDataGrid = ({ columns, rows, onRowClick }) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        onRowClick={onRowClick}
        className="custom-data-grid"
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          ...rows.initialState,
          pagination: { paginationModel: { pageSize: 100 } },
        }}
        pageSizeOptions={[5, 10, 25, 100, rows.length]}
        autoHeight
      />
    </div>
  );
};

export default MuiXDataGrid;
