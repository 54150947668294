import React from "react";

export function checkRoles(array) {
  let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
  for (let i = 0; i < array.length; i++) {
    if (activeRoles.includes(array[i])) {
      return true;
    }
  }
  return false;
}
