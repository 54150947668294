import React, { useState, useEffect } from "react";
import Session from "./../../Components/Session.js";
import axios from "axios";
import { styled } from "@mui/material/styles";
import "./gpsVehicles.css";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import pathAPI from "../../pathAPI.js";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "20%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function GpsDriver() {
  const [GpsDriver, setGpsDriver] = useState([]);

  const getGpsVehicles = () => {
    axios
      .post(pathAPI + "dashboard/gps/getGpsDriver", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        setGpsDriver(res.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getGpsVehicles();
  }, []);

  var auth = false;
  if (Session([999, 800])) {
    auth = true;
  }

  return (
    <div>
      {auth ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  #
                </TableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Nume
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Prenume
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Added Date
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                  align="center"
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {GpsDriver.map((GpsDriver, index) => (
                <StyledTableRow key={GpsDriver.id}>
                  <StyledTableCell style={{ width: "5%" }}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{GpsDriver.nume}</StyledTableCell>
                  <StyledTableCell>{GpsDriver.prenume}</StyledTableCell>
                  <StyledTableCell>{GpsDriver.addDate}</StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      <DeleteForeverIcon
                        sx={{ marginRight: "2rem" }}
                        onClick={() => ""}
                        style={{
                          color: "red",
                          fontSize: 30,
                          cursor: "pointer",
                        }}
                      />
                      <EditIcon
                        style={{
                          color: "blue",
                          fontSize: 30,
                          cursor: "pointer",
                        }}
                      ></EditIcon>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  );
}
export default GpsDriver;
