import React, { useState, useEffect, useRef, useCallback } from "react";
import "./TicketDetailsCss.css"; // Import your CSS file
import DescriptionIcon from "@mui/icons-material/Description";
import SendIcon from "@mui/icons-material/Send";
import PaletteIcon from "@mui/icons-material/Palette";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import QuilEditor from "../../../Components/QuilEditor/QuilEditor";
import QuillTextArea from "../../../Components/QuilEditor/QuillTextArea";
import QuillToolBar from "../../../Components/QuilEditor/QuillToolBar";
import Quill from "quill/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  Autocomplete,
} from "@mui/material";
import CustomizedHook from "../autocompleteCustom";
import DetailsCard from "./DetailsCard";
// import MyEditor2 from "./EditorComponentQuill";
import pathAPI from "../../../pathAPI";
import { checkRoles } from "../../../Components/Roles/CheckRoles";
import { setComment } from "../../../Actions/Ticketing/commentActions";
import { useDispatch } from "react-redux";
import MyEditor from "../../../Components/DraftJsEditor/EditorDraft";
import { EditorState, convertToRaw } from "draft-js";
import RichTextEditor from "../../../Components/DraftJsEditor/EditorDraft";
///////////////////////////////////////////////////////////////////////////
import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];
const text =
  "The toolbar above the editor can be used for formatting text, as in conventional static editors  …";

const TicketDetailsModal = ({
  isOpen,
  ticketNumber,
  onClose,
  ticketData,
  ticketComments,
  options,
  selectedTicketCCs,
  uploadsAttachTichet,
  openDeleteAttachamentOpen,
  handleFileChange,
  note,
  setNote,
  updateNote,
  uploadsNote,
  deleteFileNota,
  handleInputChange,
  formData,
  handleSubmit,
  statusRights,
  status,
  subjectEditAux,
  subjects,
  subjectEdit,
  setSubjectEdit,
  setSubjectId,
  subjectEditRef,
  setSubjectEditAux,
  editSubject,
  titleEditAux,
  titleEditRef,
  titleEdit,
  setTitleEdit,
  setTitleEditAux,
  editTitle,
  handleInputChangeQuill,
  statusTicket,
  setStatusTicket,
  isNotMobile,
  setFormData,
  shouldResetQuill,
  handleGetCommentToSend,
  resetEditor,
}) => {
  const quillRef = useRef();
  const chatRef = useRef(null);
  const [readOnly, setReadOnly] = useState(false);

  const [showQuili, setShowQuili] = useState(false);
  const [ticketDetailsOpen, setTicketDetailsOpen] = useState(false);
  const myEmail = localStorage.getItem("email");
  const [isMobile, setIsMobile] = useState(false);
  const fileRefTicket = useRef(null);
  const fileRefTicket1 = useRef();
  const fileRefTicket2 = useRef();
  const [commentToSend, setCommentToSend] = useState();
  const dispatch = useDispatch();

  ////////////////////////////////////////////////////////
  const [editorState, setEditorState] = useState(
    createEditorStateWithText(text)
  );
  const editorRef = useRef(null);

  /////////////////////////////////////
  const fileRef = useRef();
  const [isStatusSelected, setIsStatusSelected] = useState(null);

  const handleEditorChange = (content) => {
    setCommentToSend(content);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTicketDetailsOpen(isNotMobile);
    console.log("costelbiju", isNotMobile);
  }, [isOpen]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  });
  useEffect(() => {
    // Call handleSubmit only when statusTicket changes and isStatusSelected is true
    if (isStatusSelected) {
      handleSubmit();
    }
  }, [isStatusSelected]);

  function extrageInitiale(nume) {
    // Separă numele într-un array folosind spațiu și cratimă ca delimitatori
    let parti = nume.split(/[\s-]+/);
    let initiale = "";

    // Ia doar primele două elemente din array pentru a gestiona cazurile cu mai mult de două prenume
    parti = parti.slice(0, 2);

    // Parcurge array-ul și adaugă prima literă din fiecare element la șirul de inițiale
    parti.forEach((parte) => {
      initiale += parte[0].toUpperCase();
    });

    return initiale;
  }
  function extrageOraSiMinut(dataString) {
    // Creează un obiect de tip Date folosind șirul de caractere primit
    let data = new Date(dataString);

    // Extrage ora și minutul, asigurându-te că sunt întotdeauna două cifre
    let ora = data.getHours().toString().padStart(2, "0");
    let minut = data.getMinutes().toString().padStart(2, "0");

    // Returnează ora și minutul formatate
    return `${ora}:${minut}`;
  }
  function genereazaCuloareDinInitiale(initiale) {
    // Asigură-te că avem exact două inițiale pentru simplificare
    if (initiale.length < 2) {
      initiale += "XX"; // Adaugă X ca valoare implicită dacă nu sunt suficiente inițiale
    }

    initiale = initiale.substring(0, 2); // Folosește doar primele două caractere

    let componenteCuloare = initiale.split("").map(function (caracter) {
      // Normalizează fiecare caracter la o valoare între 0 și 255
      return Math.floor((caracter.charCodeAt(0) % 26) * (255 / 25));
    });

    // Generează componente RGB bazate pe codurile ASCII ale inițialelor
    let r = componenteCuloare[0];
    let g = componenteCuloare[1];
    let b = (r + g) % 255; // O abordare simplă pentru a adăuga varietate culorii

    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  }
  function extrageExtensia(numeFisier) {
    if (numeFisier) {
      // Verifică dacă numele fișierului este valid
      if (numeFisier.includes(".")) {
        // Separă numele fișierului în părți folosind punctul ca delimitator și returnează ultima parte
        return numeFisier.split(".").pop();
      } else {
        // Dacă nu există un punct în numele fișierului, presupunem că nu are o extensie
        return "";
      }
    }
  }

  function closeTicketModal() {
    setTicketDetailsOpen(false);
  }

  function openTicketDetails() {
    setTicketDetailsOpen(true);
  }

  function closeTicketDetails() {
    setTicketDetailsOpen(false);
    console.log("fasfas");
  }

  function showHideQuili() {
    setShowQuili((prevState) => !prevState);
  }
  const uploadAttachTicket2 = () => {
    fileRefTicket2.current.click();
  };

  const sendComment = () => {
    dispatch(setComment(commentToSend));
    handleGetCommentToSend(commentToSend);
  };
  const uploadClick = () => {
    fileRef.current.click();
  };
  const handleStatusChange = (event, newValue) => {
    setStatusTicket(newValue);
    setIsStatusSelected(newValue);
  };
  const handleAttachFileClick = () => {
    fileRefTicket.current.click();
  };
  const handleAttachFileClick1 = () => {
    fileRefTicket1.current.click();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      className="dialogBody ticketingBodyDialog"
      open={isOpen}
      onClose={() => {
        if (window.innerWidth >= 768) {
          setTicketDetailsOpen(true);
        } else {
          setTicketDetailsOpen(false);
        }
        onClose();
      }}
    >
      {ticketData !== null && (
        <div className="boxChat" id="ticketModal">
          <div
            className={`${ticketDetailsOpen ? "ticketDetailsTrue " : ""}phone`}
            id="phoneContainer"
            style={{ height: "100%" }}
          >
            <div className="message-header">
              <div
                className="go-back-btn"
                onClick={ticketDetailsOpen ? closeTicketDetails : onClose}
              >
                {ticketDetailsOpen && closeTicketDetails ? (
                  // Render back arrow icon when ticketDetailsOpen is true and closeTicketDetails is available
                  <ArrowBackIcon />
                ) : (
                  // Otherwise render 'B'
                  "B"
                )}
              </div>
              <div
                className="info-ticket"
                id="info-ticket"
                onClick={openTicketDetails}
              >
                <p className="info-ticket-title">{ticketData?.summary}</p>
                <p>{ticketData?.ticket_number}</p>
              </div>
            </div>
            <div className="messages" ref={chatRef}>
              {ticketComments
                ?.slice() // Create a shallow copy of the array
                .reverse() // Reverse the copied array
                .map(
                  (row, index) =>
                    row?.COMMENT !== "<p><br></p>" &&
                    row?.COMMENT !== "null" &&
                    !(
                      row?.type === "file" &&
                      row?.file_name?.startsWith("Nota_interna")
                    ) && (
                      <>
                        <div
                          key={index}
                          className={`messageContainerBox ${row?.user_email === myEmail ? "sentByMe" : ""
                            }`}
                        >
                          <Tooltip title={row?.user_name}>
                            {row?.avatar ? (
                              <div className="messageAvatar">
                                <img
                                  src={
                                    pathAPI + "uploads/profile/avatar/" +
                                    row?.avatar
                                  }
                                ></img>
                              </div>
                            ) : (
                              <div
                                style={{
                                  background: genereazaCuloareDinInitiale(
                                    extrageInitiale(row?.user_name)
                                  ),
                                }}
                                className="messageAvatar"
                              >
                                {extrageInitiale(row?.user_name)}
                              </div>
                            )}
                          </Tooltip>
                          {/* <Tooltip title="Ataseaza un nou fisier tichetului"></Tooltip> */}
                          <div className="messageContainerChatTickets">
                            {row?.user_email === myEmail ? (
                              <></>
                            ) : (
                              <p className="userNameInContainer">
                                {row?.user_name}
                              </p>
                            )}
                            {row.type === "file" ? (
                              <div
                                className="fileNameWithIcon"
                                onClick={() => {
                                  window.open(
                                    pathAPI +
                                    "uploads/ticketing/" +
                                    row.file_name
                                  );
                                }}
                              >
                                {extrageExtensia(row?.file_name) === "jpg" ||
                                  extrageExtensia(row?.file_name) === "png" ||
                                  extrageExtensia(row?.file_name) === "jpeg" ||
                                  extrageExtensia(row?.file_name) === "gif" ? (
                                  <div className="messageImageContainer">
                                    <img
                                      src={
                                        pathAPI +
                                        "uploads/ticketing/" +
                                        row?.file_name
                                      }
                                    />
                                  </div>
                                ) : (
                                  <DescriptionIcon className="fileIconOnChat"></DescriptionIcon>
                                )}
                                {row?.file_name ? (
                                  <p>{row?.file_name}</p>
                                ) : (
                                  <p>Fisierul a fost sters.</p>
                                )}
                              </div>
                            ) : (
                              <div
                                className="comment"
                                dangerouslySetInnerHTML={{
                                  __html: row?.COMMENT,
                                }}
                              ></div>
                            )}
                            <Tooltip title={row?.comment_date}>
                              <p className="commentDate">
                                {extrageOraSiMinut(row?.comment_date)}
                              </p>
                            </Tooltip>
                          </div>
                        </div>
                      </>
                    )
                )}
            </div>

            <div class="bottomSendText">
              <div class="icon-container" onClick={handleAttachFileClick1}>
                <Tooltip title="Ataseaza un nou fisier tichetului">
                  <IconButton>
                    <AttachFileIcon />
                  </IconButton>
                </Tooltip>

                <input
                  ref={fileRefTicket1}
                  class="file-input"
                  type="file"
                  onChange={(e) => handleFileChange(e, "Ticket")}
                />
              </div>

              <div className="richTextEditor">
                <RichTextEditor
                  onContentChange={handleEditorChange}
                  resetEditor={resetEditor}
                  showToolbar={showQuili}
                ></RichTextEditor>
              </div>

              <div class="icon-container2" onClick={showHideQuili}>
                <PaletteIcon
                  sx={{ color: "#f00" }}
                  class="palette-icon"
                ></PaletteIcon>
              </div>
              <div class="icon-container" onClick={sendComment}>
                <SendIcon sx={{ color: "#f00" }} class="send-icon"></SendIcon>
              </div>
            </div>
            <div className="info-ticket-details" id="info-ticket-details">
              <div className="details-top">
                <p>Detalii Ticket</p>
              </div>
              <div className="details-body">
                <div className="details-header"></div>
                <div class="container">
                  <div class="card">
                    <div class="container-card bg-green-box">
                      <p class="card-title">Optiuni</p>
                      <p class="card-description">
                        <div className="rowDiv">
                          {ticketData.summary !== titleEditAux ? (
                            <p className="ticket-title">{ticketData.summary}</p>
                          ) : (
                            <div className="commEditMode">
                              <TextField
                                inputRef={titleEditRef}
                                value={titleEdit}
                                size="small"
                                multiline
                                fullWidth
                                onChange={(e) => setTitleEdit(e.target.value)}
                              />
                              <IconButton
                                onClick={() => setTitleEditAux(null)}
                                color="error"
                              >
                                <CancelIcon />
                              </IconButton>
                              <IconButton
                                color="success"
                                onClick={() => editTitle(ticketData.ticket_id)}
                              >
                                <CheckCircleOutlineIcon />
                              </IconButton>
                            </div>
                          )}

                          <IconButton
                            onClick={() => {
                              setTitleEdit(ticketData.summary);
                              setTitleEditAux(ticketData.summary);
                              titleEditRef.current.focus();
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                        <div className="rowDiv">
                          {ticketData.subject_name !== subjectEditAux ? (
                            <p className="ticket-title">
                              {ticketData.subject_name}
                            </p>
                          ) : (
                            <div className="commEditMode">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Autocomplete
                                  options={subjects.map(
                                    (subjects) => subjects.NAME
                                  )}
                                  value={subjectEdit}
                                  onChange={(event, newValue) => {
                                    setSubjectEdit(newValue);
                                    const selectedSubject = subjects.find(
                                      (subject) => subject.NAME === newValue
                                    );
                                    if (selectedSubject) {
                                      const subjectId = selectedSubject.ID;
                                      setSubjectId(subjectId);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{ width: "150px" }}
                                      {...params}
                                      inputRef={subjectEditRef}
                                      size="small"
                                      multiline
                                      fullWidth
                                      value={subjectEdit}
                                      onChange={(e) =>
                                        setSubjectEdit(e.target.value)
                                      }
                                    />
                                  )}
                                />
                                <IconButton
                                  onClick={() => setSubjectEditAux(null)}
                                  color="error"
                                >
                                  <CancelIcon />
                                </IconButton>
                                <IconButton
                                  color="success"
                                  onClick={() =>
                                    editSubject(ticketData.ticket_id)
                                  }
                                >
                                  <CheckCircleOutlineIcon />
                                </IconButton>
                              </div>
                            </div>
                          )}
                          {checkRoles([999, 409]) ? (
                            <IconButton
                              onClick={() => {
                                setSubjectEdit(ticketData.subject_name);
                                setSubjectEditAux(ticketData.subject_name);
                                subjectEditRef.current.focus();
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="card">
                    <div class="container-card bg-green-box">
                      <p class="card-title">Persoane in CC</p>
                      <p class="card-description ticketingCCinput">
                        <CustomizedHook
                          options={options}
                          selectedTicketCCs={selectedTicketCCs}
                          ticketId={ticketData.ticket_id}
                          ticketData={ticketData}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <DetailsCard ticketData={ticketData} />

                <div class="container">
                  <div class="card">
                    <div class="container-card bg-green-box">
                      <p class="card-title">Status</p>
                      <p class="card-description">
                        <Autocomplete
                          size="small"
                          name="status"
                          id="status"
                          options={status}
                          value={statusTicket}
                          disabled={statusRights}
                          getOptionLabel={(option) => option.NAME}
                          renderInput={(params) => (
                            <TextField {...params} label="Stare tichet" />
                          )}
                          onChange={handleStatusChange}
                          isOptionEqualToValue={(option, value) =>
                            option.ID === value.ID
                          }
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="card">
                    <div class="container-card bg-green-box">
                      <p class="card-title">Fisiere</p>
                      <div class="card-description">
                        <div className="ticket-details-box">
                          <div className="columnDiv">
                            <div className="crbTopUpload">
                              <Typography>
                                Fisiere atasate tichetului:
                              </Typography>
                              <div className="uploadNote">
                                <Tooltip title="Ataseaza un nou fisier tichetului">
                                  <IconButton onClick={uploadAttachTicket2}>
                                    <AttachFileIcon />
                                  </IconButton>
                                </Tooltip>
                                <input
                                  ref={fileRefTicket2}
                                  className="file-input"
                                  type="file"
                                  onChange={(e) =>
                                    handleFileChange(e, "Ticket")
                                  }
                                />
                              </div>
                            </div>
                            <div className="rowDiv crbAtasamente">
                              {uploadsAttachTichet && uploadsAttachTichet !== []
                                ? uploadsAttachTichet.map((obj, index) => (
                                  <Tooltip title={obj.FILE_NAME}>
                                    <div
                                      className="fileUploaded"
                                      onClick={() => {
                                        window.open(
                                          pathAPI +
                                          "uploads/ticketing/" +
                                          obj.FILE_NAME
                                        );
                                      }}
                                    >
                                      <DescriptionIcon color="primary" />
                                      <Typography variant="subtitle2">
                                        <b>
                                          {obj.FILE_NAME.replace(
                                            "Ticket_",
                                            ""
                                          )
                                            .replace(/\d{15}/, "")
                                            .replace(/\d{14}/, "")
                                            .replace(/\d{10}/, "")}
                                        </b>
                                      </Typography>
                                      <Tooltip title="Eliminati fisierul">
                                        <button
                                          className="xButton"
                                          onClick={
                                            (e) => {
                                              e.stopPropagation();
                                              openDeleteAttachamentOpen(
                                                e,
                                                obj.FILE_NAME
                                              );
                                            }
                                            // deleteFileNota(e, obj.FILE_NAME)
                                          }
                                        >
                                          <span className="buttonFileText">
                                            x
                                          </span>
                                        </button>
                                      </Tooltip>
                                    </div>
                                  </Tooltip>
                                ))
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="card">
                    <div class="container-card bg-green-box">
                      <p class="card-title">Note Interne</p>
                      <div class="card-description">
                        <div className="ticket-details-box">
                          {localStorage.getItem("email") ===
                            ticketData.from_email ? (
                            <div className="columnDiv">
                              <div className="crbTopUpload">
                                <TextField
                                  size="small"
                                  name="note"
                                  label="Notă internă"
                                  value={note}
                                  fullWidth
                                  onChange={(e) => setNote(e.target.value)}
                                  onBlur={updateNote}
                                  multiline
                                />
                                <div className="uploadNote">
                                  <Tooltip title="Ataseaza un nou fisier notei interne">
                                    <IconButton onClick={uploadClick}>
                                      <AttachFileIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <input
                                    ref={fileRef}
                                    className="file-input"
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(e, "Nota_interna")
                                    }
                                  />
                                </div>
                              </div>
                              <div className="rowDiv crbAtasamente">
                                {uploadsNote && uploadsNote !== []
                                  ? uploadsNote.map((obj, index) => (
                                    <Tooltip title={obj.FILE_NAME}>
                                      <div
                                        className="fileUploaded"
                                        onClick={() => {
                                          window.open(
                                            pathAPI +
                                            "uploads/ticketing/" +
                                            obj.FILE_NAME
                                          );
                                        }}
                                      >
                                        <DescriptionIcon color="success" />
                                        <Typography variant="subtitle2">
                                          <b>
                                            {obj.FILE_NAME.replace(
                                              "Ticket_",
                                              ""
                                            )
                                              .replace(/\d{15}/, "")
                                              .replace(/\d{14}/, "")
                                              .replace(/\d{10}/, "")}
                                          </b>
                                        </Typography>
                                        <Tooltip title="Eliminati fisierul">
                                          <button
                                            className="xButton"
                                            onClick={
                                              (e) => {
                                                e.stopPropagation();
                                                openDeleteAttachamentOpen(
                                                  e,
                                                  obj.FILE_NAME
                                                );
                                              }
                                              // deleteFileNota(e, obj.FILE_NAME)
                                            }
                                          >
                                            <span className="buttonFileText">
                                              x
                                            </span>
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </Tooltip>
                                  ))
                                  : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="details-info">{/* <div></div> */}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default TicketDetailsModal;
