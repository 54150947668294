// teamReducer.js

import {
  GET_ALL_TEAMS_SUCCESS,
  GET_ALL_TEAMS_FAILURE,
  GET_TEAM_BY_ID_SUCCESS,
  GET_TEAM_BY_ID_FAILURE,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,
  ASSIGN_USER_TO_TEAM_SUCCESS,
  ASSIGN_USER_TO_TEAM_FAILURE,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
} from "../../../Actions/actionTypes";

const initialState = {
  team: null,
  error: null,
  roles: [],
  teams: [],
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
        error: null,
      };
    case GET_ALL_TEAMS_FAILURE:
      return {
        ...state,
        teams: [],
        error: action.payload,
      };
    case GET_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        team: action.payload,
        error: null,
      };
    case GET_TEAM_BY_ID_FAILURE:
      return {
        ...state,
        team: null,
        error: action.payload,
      };
    case DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        message: action.payload,
        error: null,
      };
    case ASSIGN_USER_TO_TEAM_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        error: null,
      };
    case GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        roles: [],
        error: action.payload,
      };
    case DELETE_TEAM_MEMBER_FAILURE:
    case ASSIGN_USER_TO_TEAM_FAILURE:
    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default teamReducer;
