import {
  GET_TESA_SUCCES,
  GET_TESA_FAILURE,
  UPDATE_TESA_SUCCESS,
  UPDATE_TESA_FAILURE,
  DELETE_TESA_SUCCESS,
  DELETE_TESA_FAILURE,
} from "../../Actions/actionTypes";

const initialState = {
  tesaData: null,
  error: null,
};

const tesaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TESA_SUCCES:
      return {
        ...state,
        tesaData: action.payload,
        error: null,
      };
    case GET_TESA_FAILURE:
      return {
        ...state,
        tesaData: null,
        error: action.payload,
      };
    case UPDATE_TESA_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case UPDATE_TESA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_TESA_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case DELETE_TESA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default tesaReducer;
