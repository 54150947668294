import React from "react";
import Session from "../../Components/Session.js";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Newsletters from "./NewsLettersTable/newsletters.jsx";
import NewsLettersOptions from "./NewsLettersOptions/NewsLettersOptions.jsx";
import Transportatori from "./NewsLettersTable/transportatori.jsx";

const NewslettersMain = () => {
  var auth = false;
  if (Session([999, 500, 509])) {
    auth = true;
  }

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {auth ? (
        <div className="box">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="Tab salarizare">
                {checkRoles([999]) ? (
                  <Tab label="Newsletters" value="1" />
                ) : null}
                {checkRoles([999]) ? <Tab label="Import" value="2" /> : null}
                {checkRoles([999]) ? (
                  <Tab label="Transportatori" value="3" />
                ) : null}
              </TabList>
            </Box>

            {checkRoles([999, 509]) ? (
              <TabPanel value="1">
                <Newsletters />
              </TabPanel>
            ) : null}
            {checkRoles([999, 1]) ? (
              <TabPanel value="2">
                <NewsLettersOptions></NewsLettersOptions>
              </TabPanel>
            ) : null}
            {checkRoles([999]) ? (
              <TabPanel value="3">
                <Transportatori />
              </TabPanel>
            ) : null}
          </TabContext>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default NewslettersMain;
