import {
  getNewsletterData,
  insertClientsEmails,
  insertNewsletterData,
} from "../../../Actions/Newsletters/Newsletters";
import React, { useState } from "react";
import { useDispatch } from "react-redux"; // Import useDispatch hook
import Button from "@mui/material/Button";
import "./NewsLettersUpload.css";
import Box from "@mui/material/Box";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SnackbarComponent from "../../../Components/Alerts/SnackbarComponent";
import { getTransportatori } from "../../../Actions/Transportatori/Transportatori";
export default function NewsLettersOptions() {
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [uploading1, setUploading1] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const handleFileChange1 = (e) => {
    setFile1(e.target.files[0]);
  };
  const handleFileChange2 = (e) => {
    setFile2(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (file1) {
      setUploading1(true);
      const formData = new FormData();
      formData.append("csv_file", file1);

      try {
        dispatch(insertNewsletterData(formData)).then((res) => {
          setSnackbarSeverity("success");
          setSnackbarMessage("Inserare reușită!");
          setSnackbarOpen(true);
          dispatch(getNewsletterData());
          setUploading1(false);
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No file selected");
    }
  };
  const handleClientsUpload = async () => {
    if (file2) {
      setUploading2(true);
      const formData = new FormData();
      formData.append("csv_file", file2);

      try {
        await dispatch(insertClientsEmails(formData)).then((res) => {
          setSnackbarSeverity("success");
          setSnackbarMessage("Inserare reușită!");
          setSnackbarOpen(true);
          dispatch(getTransportatori());

          setUploading2(false);
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No file selected");
    }
  };
  return (
    <div className="uploadContainer">
      <Box
        htmlFor="file-input1"
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          backgroundColor: "#5e636a52",
          justifyContent: "center",
          cursor: "pointer",
          position: "relative",
          padding: "20px",
          borderRadius: "4px",
        }}
      >
        <p style={{ color: "white" }}>Newsletter CSV File</p>
        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          <FileUploadIcon
            sx={{
              ml: "10px",
              color: "white",
              fontSize: 60,
              margin: "0 auto",
            }}
          />
          <h4 style={{ margin: "0 auto" }}>
            Drag & Drop / Click to Upload File
          </h4>
        </Box>
        <input
          id="file-input1"
          type="file"
          onChange={handleFileChange1}
          style={{
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        {file1 && (
          <>
            <p
              style={{
                marginTop: "0.5rem",
                fontWeight: 800,
              }}
            >
              {file1.name}
            </p>
            <Button
              variant="contained"
              onClick={handleUpload}
              disabled={uploading1}
            >
              Upload
            </Button>
          </>
        )}
      </Box>
      <Box
        htmlFor="file-input1"
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          backgroundColor: "#5e636a52",
          justifyContent: "center",
          cursor: "pointer",
          position: "relative",
          padding: "20px",
          borderRadius: "4px",
        }}
      >
        <p style={{ color: "white" }}>Transportatori CSV File</p>
        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          <FileUploadIcon
            sx={{
              ml: "10px",
              color: "white",
              fontSize: 60,
              margin: "0 auto",
            }}
          />
          <h4 style={{ margin: "0 auto" }}>
            Drag & Drop / Click to Upload File
          </h4>
        </Box>
        <input
          id="file-input1"
          type="file"
          onChange={handleFileChange2}
          style={{
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        {file2 && (
          <>
            <p
              style={{
                marginTop: "0.5rem",
                fontWeight: 800,
              }}
            >
              {file2.name}
            </p>
            <Button
              variant="contained"
              onClick={handleClientsUpload}
              disabled={uploading2}
            >
              Upload
            </Button>
          </>
        )}
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </div>
  );
}
