import { TextField } from "@mui/material";

export const additionalFieldsConfig = {
  "Anulare comanda de catre client": [
    {
      name: "DenumireaClientului",
      label: "Denumirea clientului",
      placeholder: "Denumirea clientului",
    },
    {
      name: "AdresaClientului",
      label: "Adresa clientului",
      placeholder: "Adresa clientului",
    },
    {
      name: "ValoareaInscrisaPeFacturaTransmisaDeCatreNoi",
      label: "Valoarea inscrisa pe factura transmisa de catre noi",
      placeholder: "Valoarea inscrisa pe factura transmisa de catre noi",
    },
    {
      name: "NumarulComenziiDeTransportAClientului",
      label: "Numarul comenzii de transport a clientului",
      placeholder: "Numarul comenzii de transport a clientului",
    },
  ],
  "Anulare comanda de catre fz": [
    {
      name: "DenumireaFurnizorului",
      label: "Denumirea furnizorului",
      placeholder: "Denumirea furnizorului",
    },
    {
      name: "AdresaFurnizorului",
      label: "Adresa furnizorului",
      placeholder: "Adresa furnizorului",
    },
    {
      name: "NumarulComenziiTransmisaFurnizorului",
      label: "Numarul comenzii transmise furnizorului",
      placeholder: "Numarul comenzii transmise furnizorului",
    },
    {
      name: "DataComenziiTransmisaFurnizorului",
      label: "Data comenzii transmise furnizorului",
      placeholder: "Data comenzii transmise furnizorului",
      type: "date",
    },
  ],
  "Costuri trimise furnizorilor": [
    {
      name: "DenumireaFurnizorului",
      label: "Denumirea furnizorului",
      placeholder: "Denumirea furnizorului",
    },
    {
      name: "AdresaFurnizorului",
      label: "Adresa furnizorului",
      placeholder: "Adresa furnizorului",
    },
    {
      name: "NumarulFacturiiTransmisaDeCatreNoi",
      label: "Numarul facturii transmise de noi",
      placeholder: "Numarul facturii transmise de noi",
    },
    {
      name: "NumarulComenziiTransmisaFurnizorului",
      label: "Numarul comenzii transmise furnizorului",
      placeholder: "Numarul comenzii transmise furnizorului",
    },
    {
      name: "DataComenziiTransmisaFurnizorului",
      label: "Data comenzii transmise furnizorului",
      placeholder: "Data comenzii transmise furnizorului",
      type: "date",
    },
  ],
  "Intarziere la descarcare": [
    {
      name: "DenumireaFurnizorului",
      label: "Denumirea furnizorului",
      placeholder: "Denumirea furnizorului",
    },
    {
      name: "AdresaFurnizorului",
      label: "Adresa furnizorului",
      placeholder: "Adresa furnizorului",
    },
    {
      name: "NumarulComenziiTransmisaFurnizorului",
      label: "Numarul comenzii transmise furnizorului",
      placeholder: "Numarul comenzii transmise furnizorului",
    },
    {
      name: "DataComenziiTransmisaFurnizorului",
      label: "Data comenzii transmise furnizorului",
      placeholder: "Data comenzii transmise furnizorului",
      type: "date",
    },
  ],
  "Intarziere la incarcare": [
    {
      name: "DenumireaFurnizorului",
      label: "Denumirea furnizorului",
      placeholder: "Denumirea furnizorului",
    },
    {
      name: "AdresaFurnizorului",
      label: "Adresa furnizorului",
      placeholder: "Adresa furnizorului",
    },
    {
      name: "NumarulComenziiTransmisaFurnizorului",
      label: "Numarul comenzii transmise furnizorului",
      placeholder: "Numarul comenzii transmise furnizorului",
    },
    {
      name: "DataComenziiTransmisaFurnizorului",
      label: "Data comenzii transmise furnizorului",
      placeholder: "Data comenzii transmise furnizorului",
      type: "date",
    },
    {
      name: "ValoareaInscrisaPeFacturaTransmisaDeCatreNoi",
      label: "Valoarea inscrisa pe  factura transima de noi",
      placeholder: "Valoarea inscrisa pe  factura transima de noi",
    },
  ],
  "Refuz facturi amenzi greutate": [
    {
      name: "DenumireaFurnizorului",
      label: "Denumirea furnizorului",
      placeholder: "Denumirea furnizorului",
    },
    {
      name: "AdresaFurnizorului",
      label: "Adresa furnizorului",
      placeholder: "Adresa furnizorului",
    },
    {
      name: "NumarulFacturiiTransmiseDeCatreFurnizor",
      label: "Numarul facturii transmise decatre furnizor",
      placeholder: "Numarul facturii transmise decatre furnizor",
    },
  ],
  "Dauna marfa lipsa marfa": [
    {
      name: "DenumireaFurnizorului",
      label: "Denumirea furnizorului",
      placeholder: "Denumirea furnizorului",
    },
    {
      name: "AdresaFurnizorului",
      label: "Adresa furnizorului",
      placeholder: "Adresa furnizorului",
    },
    {
      name: "NumarulComenziiTransmisaFurnizorului",
      label: "Numarul comenzii transmise furnizorului",
      placeholder: "Numarul comenzii transmise furnizorului",
    },
    {
      name: "DataComenziiTransmisaFurnizorului",
      label: "Data comenzii transmise furnizorului",
      placeholder: "Data comenzii transmise furnizorului",
      type: "date",
    },
  ],
  "Somatii de plata": [
    {
      name: "DenumireaDebitorului",
      label: "Denumirea debitorului",
      placeholder: "Denumirea debitorului",
    },
    {
      name: "AdresaDebitorului",
      label: "Adresa debitorului",
      placeholder: "Adresa debitorului",
    },
    {
      name: "NumarulDeZileDeScadentaAFacturiiDinGETEX",
      label: "Numarul de zile de scadenta a facturii din GETEX",
      placeholder: "Numarul de zile de scadenta a facturii din GETEX",
    },
    {
      name: "NumarulFacturiiTransmisaDeCatreNoi",
      label: "Numarul facturii transmisa decatre noi",
      placeholder: "Numarul facturii transmisa decatre noi",
    },
    {
      name: "ObiectulFacturii",
      label: "Obiectul facturii",
      placeholder: "Obiectul facturii",
    },
    {
      name: "ValoareaInscrisaPeFacturaTransmisaDeCatreNoi",
      label: "Valoarea inscrisa pe  factura transima de noi",
      placeholder: "Valoarea inscrisa pe  factura transima de noi",
    },
  ],
};
