import {
  GET_TESA_NEW_SUCCES,
  GET_TESA_NEW_FAILURE,
  UPDATE_TESA_NEW_FAILURE,
  UPDATE_TESA_NEW_SUCCESS,
  DELETE_TESA_NEW_SUCCESS,
  DELETE_TESA_NEW_FAILURE,
  INSERT_TESA_NEW_SUCCES,
  INSERT_TESA_NEW_FAILURE,
  GET_TESA_DATA_SUCCESS,
  GET_TESA_DATA_FAILURE,
  GET_TESA_HISTORY_SUCCESS,
  GET_TESA_HISTORY_FAILURE,
} from "../actionTypes";
import pathAPI from "../../pathAPI";
import axiosInstance from "../../Helpers/axiosInstance";
import axios from "axios";
import dayjs from "dayjs";
import { idID } from "@mui/material/locale";

export const getTesaNewSucces = (tesa) => ({
  type: GET_TESA_NEW_SUCCES,
  payload: tesa,
});

export const getTesaNewFailure = (error) => ({
  type: GET_TESA_NEW_FAILURE,
  payload: error,
});

export const updateTesaNewSuccess = (updatedTesa) => ({
  type: UPDATE_TESA_NEW_SUCCESS,
  payload: updatedTesa,
});

export const updateTesaNewFailure = (error) => ({
  type: UPDATE_TESA_NEW_FAILURE,
  payload: error,
});
export const deleteTesaNewSuccess = () => ({
  type: DELETE_TESA_NEW_SUCCESS,
});

export const deleteTesaNewFailure = (error) => ({
  type: DELETE_TESA_NEW_FAILURE,
  payload: error,
});
export const insertTesaNewSucces = (tesa) => ({
  type: INSERT_TESA_NEW_SUCCES,
  payload: tesa,
});

export const insertTesaNewFailure = (error) => ({
  type: INSERT_TESA_NEW_FAILURE,
  payload: error,
});
export const getTesaDataSuccess = (data) => ({
  type: GET_TESA_DATA_SUCCESS,
  payload: data,
});

export const getTesaDataFailure = (error) => ({
  type: GET_TESA_DATA_FAILURE,
  payload: error,
});

export const getTesaHistorySuccess = (data) => ({
  type: GET_TESA_HISTORY_SUCCESS,
  payload: data,
});

export const getTesaHistoryFailure = (error) => ({
  type: GET_TESA_HISTORY_FAILURE,
  payload: error,
});
export const updateTesaNew = (updatedData) => {
  return async (dispatch) => {
    console.log(updatedData);
    try {
      await axiosInstance.post(`dashboard/salarizareNew/updateTesaNEWUsers`, {
        id: updatedData.id,
        salariu_net: updatedData.salariu_net,
      });
      dispatch(updateTesaNewSuccess());
    } catch (error) {
      dispatch(updateTesaNewFailure(error.message));
    }
  };
};
export const getTesaNew = (yearmonth) => {
  const adjustedDate = dayjs(yearmonth).startOf("month").format("YYYY-MM-DD");
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/salarizareNew/getTesaNEW`,
        {
          yearMonth: adjustedDate,
        }
      );
      console.log(response);
      dispatch(getTesaNewSucces(response.data));
    } catch (error) {
      dispatch(getTesaNewFailure(error.message));
    }
  };
};

export const deleteTesaNew = (transportatorId) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`dashboard/salarizareNew/deleteTesaNEW/`, {
        id: transportatorId,
      });

      dispatch(deleteTesaNewSuccess());
    } catch (error) {
      dispatch(deleteTesaNewFailure(error.message));
    }
  };
};
export const insertTesaNew = (formData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${pathAPI}dashboard/salarizareNew/inserTesaNEW`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(insertTesaNewSucces());
    } catch (error) {
      dispatch(insertTesaNewFailure(error.message));
    }
  };
};
export const getTesaData = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/users/getUserTesaDetails`,
        {
          id: userId,
        }
      );

      dispatch(getTesaDataSuccess(response.data));
    } catch (error) {
      dispatch(getTesaDataFailure(error.message));
    }
  };
};

export const getTesaHistory = (userId, yearMonth = null) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/users/getTesaHistory`,
        {
          id: userId,
          yearMonth: yearMonth
            ? dayjs(yearMonth).startOf("month").format("YYYY-MM-DD")
            : null,
        }
      );

      dispatch(getTesaHistorySuccess(response.data));
    } catch (error) {
      dispatch(getTesaHistoryFailure(error.message));
    }
  };
};
