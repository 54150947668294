import { TextField, Autocomplete, Button } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Filters = ({
  ticketsFilter,
  titluFilter,
  subiectFilter,
  emitatorFilter,
  receptorFilter,
  statusFilter,
  raportatFilter,
  ccFilter,
  personFilter,
  selectedTicket,
  selectedTitlu,
  selectedSubject,
  selectedEmitator,
  selectedReceptor,
  selectedStatus,
  selectedRaportat,
  selectedCC,
  selectedPerson,
  setSelectedTicket,
  setSelectedTitlu,
  setSelectedSubject,
  setSelectedEmitator,
  setSelectedReceptor,
  setSelectedStatus,
  setSelectedRaportat,
  setSelectedCC,
  setSelectedPerson,
  isCCVisible,
  isResponsabilFilterVisible,
  isDeschisDeFilterVisible,
  distinctSubject,
}) => {
  return (
    <div
      className="ticketingFilters"
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      <Autocomplete
        size="small"
        name="ticketFilter"
        id="ticketFilter"
        sx={{ width: "200px" }}
        options={ticketsFilter}
        value={selectedTicket}
        getOptionLabel={(ticket) => ticket.ticket_number}
        renderInput={(params) => <TextField {...params} label="Tichet" />}
        onChange={(event, newValue) => {
          setSelectedTicket(newValue);
        }}
      />
      <Autocomplete
        size="small"
        name="titluFilter"
        id="titluFilter"
        options={titluFilter}
        sx={{ width: "200px" }}
        value={selectedTitlu}
        getOptionLabel={(t) => t.summary}
        renderInput={(params) => <TextField {...params} label="Titlu" />}
        onChange={(event, newValue) => {
          setSelectedTitlu(newValue);
        }}
      />
      <Autocomplete
        size="small"
        name="subiectFilter"
        id="subiectFilter"
        options={subiectFilter}
        sx={{ width: "200px" }}
        value={selectedSubject}
        getOptionLabel={(sub) => sub.NAME}
        renderInput={(params) => <TextField {...params} label="Subiect" />}
        onChange={(event, newValue) => {
          setSelectedSubject(newValue);
        }}
      />
      <Autocomplete
        size="small"
        name="statusFilter"
        id="statusFilter"
        options={statusFilter}
        sx={{ width: "200px" }}
        value={selectedStatus}
        getOptionLabel={(sub) => sub.NAME}
        renderInput={(params) => <TextField {...params} label="Status" />}
        onChange={(event, newValue) => {
          setSelectedStatus(newValue);
        }}
      />
      {isDeschisDeFilterVisible ? (
        <Autocomplete
          size="small"
          name="emitatorFilter"
          id="emitatorFilter"
          options={emitatorFilter}
          sx={{ width: "200px" }}
          value={selectedEmitator}
          getOptionLabel={(e) => e.name}
          renderInput={(params) => <TextField {...params} label="Deschis de" />}
          onChange={(event, newValue) => {
            setSelectedEmitator(newValue);
          }}
        />
      ) : (
        ""
      )}
      <Autocomplete
        size="small"
        name="receptorFilter"
        id="receptorFilter"
        options={receptorFilter}
        sx={{ width: "200px" }}
        value={selectedReceptor}
        getOptionLabel={(rec) => rec.NAME}
        renderInput={(params) => <TextField {...params} label="Departament" />}
        onChange={(event, newValue) => {
          setSelectedReceptor(newValue);
        }}
      />
      {isResponsabilFilterVisible ? (
        <Autocomplete
          size="small"
          name="raportatFilter"
          id="raportatFilter"
          options={raportatFilter}
          sx={{ width: "200px" }}
          value={selectedRaportat}
          getOptionLabel={(e) => e.name}
          renderInput={(params) => (
            <TextField {...params} label="Responsabil" />
          )}
          onChange={(event, newValue) => {
            setSelectedRaportat(newValue);
          }}
        />
      ) : (
        ""
      )}
      {isCCVisible ? (
        <Autocomplete
          size="small"
          name="ccFilter"
          id="ccFilter"
          options={ccFilter}
          sx={{ width: "200px" }}
          value={selectedCC}
          getOptionLabel={(e) => e.fullname}
          renderInput={(params) => <TextField {...params} label="CC" />}
          onChange={(event, newValue) => {
            setSelectedCC(newValue);
          }}
        />
      ) : (
        ""
      )}

      <Autocomplete
        size="small"
        name="personFilter"
        id="personFilter"
        options={personFilter}
        sx={{ width: "200px" }}
        value={selectedPerson}
        getOptionLabel={(e) => e.name}
        renderInput={(params) => <TextField {...params} label="Persoana" />}
        onChange={(event, newValue) => {
          setSelectedPerson(newValue);
        }}
      />

      {/* <Button size = "small" variant="contained" style = {{backgroundColor: '#0075ba', color: 'white', width: '3vw'}}
                onClick = {()=>{
                    setSelectedTicket(null)
                    setSelectedTitlu(null)
                    setSelectedSubject(null)
                    setSelectedEmitator(null)
                    setSelectedReceptor(null)
                }}
            >
                Clear
            </Button> */}
    </div>
  );
};

export default Filters;
