import { Button } from "@mui/material";
import { DataGrid, GridToolbar, roRO } from "@mui/x-data-grid";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import pathAPI from "../../../pathAPI";
import UserDetails from "./UserDetails";
import "./Users.css";

const COLUMN_MAPPING = {
  name: "Nume",
  // fullname: "Nume complet",
  email: "Email",
  Functie: "Functie",
  userGetex: "Utilizator Getex",
  token: "token",
};

export default function Users() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [sentEmails, setSentEmails] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);

  const handleRowClick = (params) => {
    setSelectedUser(params.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };
  useEffect(() => {
    axios
      .post(pathAPI + "dashboard/users/getAllUsers/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((response) => {
        const userRows = response.data.map((item, index) => ({
          id: index + 1,
          ...item,
        }));
        setRows(userRows);
        setLoading(false);
      })
      .catch(function (error) {
        console.log("Request failed.");
        setLoading(false);
      });
  }, []);

  const handleEditRowsModelChange = (newModel) => {
    setEditRowsModel(newModel);
  };
  const handleClientSubmit = (emailToSend) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    const payload = {
      token,
      email,
      emailToSend,
    };
    axios
      .post(pathAPI + "dashboard/users/sendRecoveryEmail", payload)
      .then((response) => {
        setSentEmails((prevEmails) => [...prevEmails, email]);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const columns = [
    ...Object.keys(COLUMN_MAPPING).map((field) => ({
      field: field,
      headerName: COLUMN_MAPPING[field],
      flex: 1,
      renderCell: (params) => {
        if (field === "name") {
          return (
            <button
              className="nameButton"
              onClick={() => handleRowClick(params)}
            >
              {params.value}
            </button>
          );
        }
        return params.value;
      },
    })),
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        if (sentEmails.includes(params.row.email)) {
          return "Email Sent";
        }
        return (
          <Button onClick={() => handleClientSubmit(params.row.email)}>
            Reset Password
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        loading={loading}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        components={{
          Toolbar: GridToolbar,
        }}
        initialState={{
          ...rows.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        autoHeight
      />{" "}
      {selectedUser && (
        <UserDetails
          open={open}
          handleClose={handleClose}
          user={selectedUser}
        />
      )}
    </>
  );
}
