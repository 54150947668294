import {
    TextField,
    Button
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import pathAPI from "../../../../pathAPI";

export default function AdaugaCurs() {
    const initialFormData = {
        nume_curs: null,
        descriere: null
    };
    const [formData, setFormData] = useState(initialFormData);
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const adaugaCurs = () => {
        if (formData.nume_curs && formData.descriere)
            axios
                .post(pathAPI + "dashboard/cursuri/adaugaCurs", {
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                    nume_curs: formData?.nume_curs,
                    descriere: formData?.descriere
                })
                .then(function (response) {
                    // console.log(response)
                    setFormData(initialFormData);
                })
                .catch(function (error) {
                    console.error("Request failed:", error);
                });
    }
    return (
        <div className="containerForControl">
            <div className="headerForControl">
                Adauga Curs
            </div>
            <div className="bodyForControl">
                <TextField
                    size="small"
                    name="nume_curs"
                    label="Nume curs"
                    placeholder="Nume curs"
                    value={formData.nume_curs ?? ""}
                    onChange={handleInputChange}
                    required
                />
                <TextField
                    size="small"
                    name="descriere"
                    label="Descriere"
                    placeholder="Descriere"
                    value={formData.descriere ?? ""}
                    onChange={handleInputChange}
                    required
                />
                <Button
                    variant="contained"
                    type="submit"
                    onClick={adaugaCurs}
                >
                    Adauga Curs
                </Button>
            </div>
        </div>

    );
}
