import axios from "axios";
import React, { useEffect, useState } from "react";
import pathAPI from "../../pathAPI";
import Papa from "papaparse";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Button } from "@mui/material";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const ImportGetex = () => {
  const [data, setData] = useState([]);
  const [datePicked, setDatePicked] = useState(dayjs().format("YYYY-MM-DD"));

  function readCSV(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const csvData = event.target.result;

        Papa.parse(csvData, {
          complete: (results) => {
            resolve(results.data);
          },
          error: (error) => {
            reject(error);
          },
        });
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  }

  function praseMoney(money) {
    return parseFloat(money.replace(/,/g, "").trim());
  }

  function handleFileUpload(event) {
    const file = event.target.files[0];

    readCSV(file)
      .then((data) => {
        const parsedData = data
          .filter((row) => {
            const value = row[2];
            return value == "Da";
          })
          .map((row) => {
            const modifiedRow = [...row];

            modifiedRow[3] = praseMoney(modifiedRow[3]);
            modifiedRow[4] = praseMoney(modifiedRow[4]);
            modifiedRow[5] = praseMoney(modifiedRow[5]);

            return modifiedRow;
          });

        axios
          .post(pathAPI + "/dashboard/salarizare/importGetex/", {
            token: localStorage.getItem("token"),
            email: localStorage.getItem("email"),
            parsedData: parsedData,
            yearMonth: datePicked,
          })
          .then(function (response) {})
          .catch(function (error) {
            console.log("Request failed.");
          });

        setData(parsedData);

        console.table(parsedData);
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      <div className="importContainer">
        <div className="importArea">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date From"
              views={["year", "month", "day"]}
              value={datePicked}
              onChange={(newValue) => {
                setDatePicked(newValue);
              }}
              format="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <label for="importGetex" className="uploadBox">
            <input
              type="file"
              id="importGetex"
              className="hideForUpload"
              onChange={handleFileUpload}
            />
            <div className="uploadHeader">
              <p>
                Trage și plasează fișierele aici sau apasă pentru a începe
                încărcarea
              </p>
            </div>
          </label>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="Praguri salarizare">
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Dispecer</TableCell>
                <TableCell>Activ</TableCell>
                <TableCell>Profit individual</TableCell>
                <TableCell>Profit colaborare</TableCell>
                <TableCell>Total general</TableCell>
                <TableCell>Moneda</TableCell>
                <TableCell>Moneda ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row[0]}</TableCell>
                  <TableCell>{row[1]}</TableCell>
                  <TableCell>{row[2]}</TableCell>
                  <TableCell>{row[3]}</TableCell>
                  <TableCell>{row[4]}</TableCell>
                  <TableCell>{row[5]}</TableCell>
                  <TableCell>{row[6]}</TableCell>
                  <TableCell>{row[7]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default ImportGetex;
