import axios from "axios";
import React, { useEffect, useState } from "react";
import pathAPI from "../../pathAPI";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MuiXDataGrid from "../../Components/Table/DataGrid";

const Praguri2 = () => {
  const [praguri, setPraguri] = useState([]);

  const getData = () => {
    axios
      .post(pathAPI + "/dashboard/salarizare/getAllFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "SALARIZARE_PRAGURI_NEW",
      })
      .then(function (response) {
        setPraguri(response.data.SALARIZARE_PRAGURI_NEW);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <MuiXDataGrid
        columns={[
          {
            field: "prag",
            headerName: "Prag",
            flex: 2,
            headerClassName: "super-app-theme--header",
          },
          {
            field: "baza",
            headerName: "Baza",
            flex: 2,
          },
          {
            field: "comision",
            headerName: "Comision",
            flex: 1,
          },
          {
            field: "total",
            headerName: "Total",
            flex: 1,
          },
        ]}
        rows={praguri}
      />
    </>
  );
};
export default Praguri2;
