import { useEffect, useState } from "react";
import axios from "axios";
import Session from "../Components/Session.js";
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { IconButton } from "@mui/material";
import pathAPI from "../pathAPI.js";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import axiosInstance from "../Helpers/axiosInstance.jsx";
const defaultTheme = createTheme();

const Register = (props) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [generatedPassword, setGeneratedPassword] = useState("");
  const [functiiAngajati, setfunctiiAngajati] = useState([]);
  const [selectedFunctie, setSelectedFunctie] = useState(null);

  useEffect(() => {
    axios
      .post(pathAPI + "dashboard/users/getFunctiiAngajati/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((response) => {
        setfunctiiAngajati(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  }, []);

  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?";
    let randomPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomPassword += charset[randomIndex];
    }
    setGeneratedPassword(randomPassword);
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = generatedPassword;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    alert("Password copied to clipboard!");
  };

  const onSubmit = (data) => {
    const formData = {
      name: data.name,
      fullname: data.fullname,
      userGetex: data.userGetex,
      type: selectedFunctie ? selectedFunctie.id : "",
      email: data.email,
      password: data.password,
      iban: data.iban,
      data_angajare: data.data_angajare,
    };
    axios.post(`${pathAPI}dashboard/app/register`, formData).then((result) => {
      console.log(result);
      if (result.data.user.status === 1) {
        console.log(result.data);
        alert("Te-ai inregistrat cu succes!");

        setGeneratedPassword("");
        setSelectedFunctie(null);
        setValue("name", "");
        setValue("fullname", "");
        setValue("userGetex", "");
        setValue("type", null);
        setValue("email", "");
        setValue("password", "");
        setValue("iban", "");
        setValue("data_angajare", "");
      }
      if (result.data.user.status === 0) {
        alert("Exista deja un user cu acest mail");
      }
    });
  };

  var auth = false;
  if (Session([999, 950])) {
    auth = true;
  }

  return (
    <>
      {auth ? (
        <div className="box">
          <div className="boxHeader">
            <h2>Register</h2>
          </div>
          <div className="boxContent">
            <ThemeProvider theme={defaultTheme}>
              <Container component="main" maxWidth="xs">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: 4,
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Name is required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id="name"
                              label="Name"
                              autoComplete="given-name"
                              error={!!errors.name}
                              helperText={errors.name && errors.name.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="fullname"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Full Name is required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id="fullname"
                              label="Full Name"
                              autoComplete="family-name"
                              error={!!errors.fullname}
                              helperText={
                                errors.fullname && errors.fullname.message
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="userGetex"
                          control={control}
                          defaultValue=""
                          rules={{ required: "User Getex is required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id="userGetex"
                              label="User Getex"
                              autoComplete="family-name"
                              error={!!errors.userGetex}
                              helperText={
                                errors.userGetex && errors.userGetex.message
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="type"
                          control={control}
                          defaultValue={null}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={functiiAngajati}
                              getOptionLabel={(option) => option.name}
                              value={selectedFunctie}
                              onChange={(event, newValue) =>
                                setSelectedFunctie(newValue)
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Functie" />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="data_angajare"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Data de angajare este obligatorie",
                          }}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                {...field}
                                label="Data Angajare"
                                format="DD-MM-YYYY-HH:mm:ss"
                                renderInput={(params) => (
                                  <TextField
                                    size="large"
                                    {...params}
                                    required
                                    sx={{ width: "100%" }}
                                    error={!!errors.data_angajare}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="iban"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Iban este obligatoriu" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id="iban"
                              label="Iban*"
                              autoComplete="family-name"
                              error={!!errors.iban}
                              helperText={errors.iban && errors.iban.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Email Address is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email format",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              id="email"
                              label="Adresa de email*"
                              autoComplete="email"
                              error={!!errors.email}
                              helperText={errors.email && errors.email.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Password is required" }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="new-password"
                              error={!!errors.password}
                              helperText={
                                errors.password && errors.password.message
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      onClick={generatePassword}
                      sx={{ marginTop: 3, p: 1 }}
                    >
                      Generate Random Password
                    </Button>
                    {generatedPassword && (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          border="1px solid #ccc"
                          borderRadius="5px"
                        >
                          <Typography
                            variant="body2"
                            color="text.primary"
                            align="center"
                            flexGrow={1}
                          >
                            Generated Password:{" "}
                            <p
                              style={{
                                display: "inline",
                                borderRadius: "3px",
                                background: "rgb(0 255 200 / 27%)",
                                padding: "0.1rem 0.3rem 0.1rem 0.3rem",
                              }}
                            >
                              {generatedPassword}
                            </p>
                          </Typography>
                          <IconButton
                            onClick={copyToClipboard}
                            aria-label="Copy to Clipboard"
                            color="primary"
                          >
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </Box>
                      </>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3 }}
                    >
                      Submit
                    </Button>

                    <Grid container justifyContent="flex-end"></Grid>
                  </form>
                </Box>
              </Container>
            </ThemeProvider>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Register;
