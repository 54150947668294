import "./Cursuri.css";
import React, { useState } from "react";
import Loader from "../../Helpers/Loader";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ListaCursuri from "./ListaCursuri/ListaCursuri.jsx";
import ControlOptiuni from "./ControlOptiuni/ControlOptiuni.jsx";

export default function Cursuri() {
    const [value, setValue] = React.useState("1");
    const [loader, setLoader] = useState(false);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function checkRoles(array) {
        let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
        for (let i = 0; i < array.length; i++) {
            if (activeRoles.includes(array[i])) {
                return true;
            }
        }
        return false;
    }

    return (
        <div className="box">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                    >
                        <Tab label="Cursuri" value="1" />
                        {checkRoles([999, 859]) && (
                            <Tab label="Control opțiuni" value="2" />
                        )}

                    </TabList>
                </Box>
                <TabPanel value="1">
                    <ListaCursuri />
                </TabPanel>
                {checkRoles([999, 859]) && (
                    <TabPanel value="2">
                        <ControlOptiuni />
                    </TabPanel>
                )}

            </TabContext>

            <Loader loading={loader} />
        </div >
    );
}
