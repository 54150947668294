import Session from "../../Components/Session";
import React, { useEffect, useState } from "react";
import axios from "axios";
import pathAPI from "../../pathAPI";
import "./MainDash.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField, Tooltip } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MuiXDataGrid from "../../Components/Table/DataGrid";
import DataGridOtherRowId from "../../Components/Table/DataGridFilesWithOtherNameOnRowID";
import VisibilityIcon from "@mui/icons-material/Visibility";

const MainDash = () => {
  const [dateSalarii, setCurrentSallary] = useState([]);
  const [topAgents, setTopAgents] = useState([]);
  const [agentPenalizariTrimise, setAgentPenalizariTrimise] = useState([]);
  const [userRoleFiles, setUserRoleFiles] = useState([]);
  const [currentProgress, setCurrentProgress] = useState([]);
  const [emailTarget, setEmailTarget] = useState({
    email: null,
    fullname: null,
  });
  const [agents, setAgents] = useState([]);
  const [datePicked, setDatePicked] = useState(new Date());
  const [avaiableDate, setAvaiableDate] = useState([]);
  const [vechime, setVechime] = useState([]);
  const [tempInfoSalarii, setTempInfoSalarii] = useState([]);
  const [progressLineWidth, setProgressLineWidth] = useState("0%");

  var auth = false;
  if (Session([1, 999])) {
    auth = true;
  }

  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles) {
        if (activeRoles.includes(array[i])) {
          return true;
        }
      }
    }
    return false;
  }

  const getCurrentSallary = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getCurrentSallary/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: datePicked,
      })
      .then(function (response) {
        setCurrentSallary(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getCurrentSallaryAdmin = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getCurrentSallaryAdmin/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        target_email: emailTarget.email,
        yearMonth: datePicked,
      })
      .then(function (response) {
        setCurrentSallary(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getTopAgents = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getTopAgents/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setTopAgents(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getAgentPenalizariTrimise = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getAgentPenalizariTrimise/", {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        token: localStorage.getItem("token"),
      })
      .then(function (response) {
        setAgentPenalizariTrimise(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getUserRoleFiles = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getUserRoleFiles/", {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        token: localStorage.getItem("token"),
      })
      .then(function (response) {
        console.log("albalalala", response.data);
        setUserRoleFiles(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getProgress = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getProgress/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        yearMonth: datePicked,
      })
      .then(function (response) {
        setCurrentProgress(response.data[0]);
        // console.log(response.data)
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getAgents = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getAgents/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setAgents(response.data);
        setEmailTarget({
          email: response.data[0].email,
          fullname: response.data[0].fullname,
        });
        console.log(response.data[0].email);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getProgressAdmin = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getProgressAdmin/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        target_email: emailTarget.email,
        yearMonth: datePicked,
      })
      .then(function (response) {
        setCurrentProgress(response.data[0]);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getAvaiableDate = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getAvaiableDate/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setAvaiableDate(response.data[0]);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getAvaiableDateAdmin = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getAvaiableDateAdmin/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        target_email: emailTarget.email,
      })
      .then(function (response) {
        setAvaiableDate(response.data[0]);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getVechime = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getVechime/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setVechime(response.data[0]);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getVechimeAdmin = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getVechimeAdmin/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        target_email: emailTarget.email,
      })
      .then(function (response) {
        setVechime(response.data[0]);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getTempInfoSalarii = () => {
    axios
      .post(pathAPI + "/dashboard/mainDash/getTempInfoSalarii/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setTempInfoSalarii(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    if (!checkRoles([999, 900])) {
      getCurrentSallary();
      getProgress();
    }
  }, [datePicked]);

  useEffect(() => {
    if (!checkRoles([999, 900])) {
      getAvaiableDate();
      getVechime();
    }
  }, []);

  useEffect(() => {
    if (checkRoles([999, 900])) {
      if (emailTarget.email) {
        getCurrentSallaryAdmin();
        getProgressAdmin();
        getAvaiableDateAdmin();
        getVechimeAdmin();
      }
    }
  }, [emailTarget, datePicked]);

  useEffect(() => {
    if (checkRoles([999, 900])) {
      getAgents();
    }
  }, []);

  useEffect(() => {
    getTopAgents();
    getAgentPenalizariTrimise();
    getUserRoleFiles();
    getTempInfoSalarii();
  }, []);

  useEffect(() => {
    if (currentProgress) {
      if (currentProgress.prag == currentProgress.pragCurent) {
        setProgressLineWidth("100%");
      } else {
        setProgressLineWidth(
          Math.round(
            ((currentProgress.totalGeneral - currentProgress.pragCurent) /
              (currentProgress.prag - currentProgress.pragCurent)) *
              100
          ) + "%"
        );
      }
    }
  }, [currentProgress]);

  return (
    <>
      {auth ? (
        <>
          {dateSalarii.length != 0 || checkRoles([999, 900]) ? (
            <div className="box">
              <div className="boxHeader">
                <h2>
                  <span>Salariu</span>
                  {dateSalarii.length > 0 ? (
                    <span>{dateSalarii[0].totalBrut} Lei brut</span>
                  ) : (
                    <></>
                  )}
                </h2>
              </div>
              <div className="boxContent gridIsh">
                {currentProgress ? (
                  <div className="currentProgress">
                    <p>
                      Cu o vechime de <strong>{vechime.vechime}</strong>, pragul
                      minim pentru a primi comision este{" "}
                      <strong>{vechime.pragCurent}</strong>.
                      {vechime.pragCurent != vechime.urmatorulPrag ? (
                        <>
                          <br />
                          <br />
                          Peste <strong>{vechime.timpPrag}</strong>, următorul
                          prag minim este de{" "}
                          <strong>{vechime.urmatorulPrag}</strong>.
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                    {checkRoles([999, 900]) ? (
                      <span>
                        <Autocomplete
                          size="small"
                          name="utilizator"
                          id="utilizator"
                          options={agents}
                          value={emailTarget}
                          getOptionLabel={(option) => option.fullname}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Utilizator"
                              sx={{ width: "240px" }}
                            />
                          )}
                          onChange={(event, newValue) =>
                            setEmailTarget({
                              email: newValue.email,
                              fullname: newValue.fullname,
                            })
                          }
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                    <span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Luna / anul"
                          views={["year", "month"]}
                          value={datePicked}
                          onChange={(newValue) => {
                            const newDate = new Date(newValue);
                            setDatePicked(newDate);
                          }}
                          minDate={
                            new Date(avaiableDate ? avaiableDate.minDate : "")
                          }
                          maxDate={new Date()}
                          format="DD-MM-YYYY"
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              required
                              sx={{ width: "240px" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </span>
                    <div className="progressBar">
                      {currentProgress.prag == currentProgress.pragCurent ? (
                        <h1>Felicitari, ai atins pragul maxim!</h1>
                      ) : (
                        <span className="progressLabel progressNow">
                          <span className="progressInfo">
                            <span className="progressInfoTitle">Total net</span>
                            <span className="progressInfoValue">
                              <span>{currentProgress.totalNet}</span>
                            </span>
                          </span>
                          <span className="progressInfo">
                            <span className="progressInfoTitle">Prag</span>
                            <span className="progressInfoValue">
                              <span>{currentProgress.pragCurent}</span>
                            </span>
                          </span>
                          <span className="progressInfo">
                            <span className="progressInfoTitle">Baza</span>
                            <span className="progressInfoValue">
                              <span>{currentProgress.bazaCurenta}</span>
                            </span>
                          </span>
                          <span className="progressInfo">
                            <span className="progressInfoTitle">Comision</span>
                            <span className="progressInfoValue">
                              <span>{currentProgress.comisionNet}</span>
                            </span>
                          </span>
                        </span>
                      )}
                      <span className="progressLine">
                        <span className="progressValue">
                          {currentProgress.totalGeneral} total general
                        </span>
                        <span
                          className="progressComplete"
                          style={{ width: progressLineWidth }}
                        ></span>
                      </span>
                      <span className="progressLabel">
                        <span className="progressInfo">
                          <span className="progressInfoValue">
                            <span>
                              {currentProgress.prag ==
                              currentProgress.pragCurent
                                ? currentProgress.totalNet
                                : currentProgress.total}
                            </span>
                          </span>
                          <span className="progressInfoTitle">Total net</span>
                        </span>
                        <span className="progressInfo">
                          <span className="progressInfoValue">
                            <span>
                              {currentProgress.prag ==
                              currentProgress.pragCurent
                                ? currentProgress.pragCurent
                                : currentProgress.prag}
                            </span>
                          </span>
                          <span className="progressInfoTitle">Prag</span>
                        </span>
                        <span className="progressInfo">
                          <span className="progressInfoValue">
                            <span>
                              {currentProgress.prag ==
                              currentProgress.pragCurent
                                ? currentProgress.bazaCurenta
                                : currentProgress.baza}
                            </span>
                          </span>
                          <span className="progressInfoTitle">Baza</span>
                        </span>
                        <span className="progressInfo">
                          <span className="progressInfoValue">
                            <span>
                              {currentProgress.prag ==
                              currentProgress.pragCurent
                                ? currentProgress.comisionNet
                                : currentProgress.comision}
                            </span>
                          </span>
                          <span className="progressInfoTitle">Comision</span>
                        </span>
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <TableContainer component={Paper}>
                  <Table aria-label="Salariu curent">
                    <TableHead>
                      <TableRow>
                        <TableCell>Denumire</TableCell>
                        <TableCell>Valoare</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dateSalarii ? (
                        <>
                          {dateSalarii.map((row) => (
                            <>
                              <TableRow key={1}>
                                <TableCell>Profit Colaborare</TableCell>
                                <TableCell>{row.profitColaborare}</TableCell>
                              </TableRow>
                              <TableRow key={2}>
                                <TableCell>Profit Individual</TableCell>
                                <TableCell>{row.profitIndividual}</TableCell>
                              </TableRow>
                              <TableRow key={3}>
                                <TableCell>Total general</TableCell>
                                <TableCell>{row.totalGeneral}</TableCell>
                              </TableRow>
                              <TableRow key={4}>
                                <TableCell>Prag</TableCell>
                                <TableCell>{row.prag}</TableCell>
                              </TableRow>
                              <TableRow key={5}>
                                <TableCell>Baza net</TableCell>
                                <TableCell>{row.baza}</TableCell>
                              </TableRow>
                              <TableRow key={6}>
                                <TableCell>Comision net</TableCell>
                                <TableCell>{row.comisionNet}</TableCell>
                              </TableRow>
                              <TableRow key={7}>
                                <TableCell>Bonus top 3</TableCell>
                                <TableCell>{row.topBonus}</TableCell>
                              </TableRow>
                              {row.extraBonus != 0 ? (
                                <TableRow key={8}>
                                  <TableCell>Extra bonus</TableCell>
                                  <TableCell>{row.extraBonus}</TableCell>
                                </TableRow>
                              ) : null}
                              {row.incasari_sistem_vechi ? (
                                <TableRow key={9}>
                                  <TableCell>Incasari s.vechi</TableCell>
                                  <TableCell>
                                    {row.incasari_sistem_vechi}
                                  </TableCell>
                                </TableRow>
                              ) : null}
                              {row.corectie ? (
                                <TableRow key={10}>
                                  <TableCell>Corectie</TableCell>
                                  <TableCell>{row.corectie}</TableCell>
                                </TableRow>
                              ) : null}
                              {row.avans ? (
                                <TableRow key={11}>
                                  <TableCell>Avans</TableCell>
                                  <TableCell>{row.avans}</TableCell>
                                </TableRow>
                              ) : null}
                              {row.bonus ? (
                                <TableRow key={12}>
                                  <TableCell>Bonus</TableCell>
                                  <TableCell>{row.bonus}</TableCell>
                                </TableRow>
                              ) : null}
                              <TableRow key={13}>
                                <TableCell>Total net</TableCell>
                                <TableCell> {row.totalNet}</TableCell>
                              </TableRow>
                              <TableRow key={15}>
                                <TableCell>
                                  <span className="mareGros">Total BRUT</span>
                                </TableCell>
                                <TableCell>
                                  <span className="mareGros">
                                    {" "}
                                    {row.totalBrut}
                                  </span>
                                </TableCell>
                              </TableRow>
                              <TableRow key={14}>
                                <TableCell>Ultima actualizare</TableCell>
                                <TableCell>{row.yearMonth}</TableCell>
                              </TableRow>
                            </>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ) : (
            <></>
          )}
          {userRoleFiles.length !== 0 && checkRoles([999, 1, 900]) && (
            <div className="box">
              <div className="boxHeader">
                <h2>Fisiere distribuite</h2>
              </div>
              <div className="boxContent">
                {/* {userRoleFiles.map((row) => (
                  <p>{row?.file_name}</p>
                ))} */}
                <DataGridOtherRowId
                  columns={[
                    {
                      field: "name",
                      headerName: "Denumire fisier",
                      flex: 2,
                    },
                    {
                      field: "file_name",
                      headerName: "Nume Fisier",
                      flex: 2,
                      headerClassName: "super-app-theme--header",
                      renderCell: (params) => {
                        const fileUrl = params.row.file_name;
                        const truncatedFileName = params.value.slice(0, 20);

                        const handleFileNameClick = () => {
                          window.open(
                            pathAPI + "uploads/FisiereAngajati/" + fileUrl
                          );
                        };

                        return (
                          <div className="flex dataGridSimpleText">
                            <Tooltip title={params.value}>
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {truncatedFileName}
                              </div>
                            </Tooltip>
                            <Tooltip title="Vizualizeaza fisierul">
                              <button
                                className=""
                                onClick={handleFileNameClick}
                              >
                                <VisibilityIcon className="ml-2" />
                              </button>
                            </Tooltip>
                          </div>
                        );
                      },
                    },
                    {
                      field: "uploaded_by",
                      headerName: "Incarcat de",
                      flex: 2,
                    },
                    {
                      field: "upload_date",
                      headerName: "Data Incarcare",
                      flex: 2,
                    },
                  ]}
                  rows={userRoleFiles}
                />
              </div>
            </div>
          )}
          <div className="box">
            <div className="boxHeader">
              <h2>Top 3 agenti</h2>
            </div>
            <div className="boxContent">
              <TableContainer component={Paper}>
                <Table aria-label="Top Agenti">
                  <TableHead>
                    <TableRow>
                      <TableCell>Agent</TableCell>
                      <TableCell>Total general</TableCell>
                      <TableCell>Bonus</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topAgents.map((row) => (
                      <TableRow>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.totalGeneral}</TableCell>
                        <TableCell>{row.topBonus}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          {agentPenalizariTrimise.length !== 0 && checkRoles([999, 1, 900]) && (
            <div className="box">
              <div className="boxHeader">
                <h2>Penalizari Trimise</h2>
              </div>
              <div className="boxContent">
                <MuiXDataGrid
                  columns={[
                    {
                      field: "tipPenalizare",
                      headerName: "Tip Penalizare",
                      flex: 2,
                      headerClassName: "super-app-theme--header",
                    },
                    { field: "client", headerName: "Client", flex: 1 },
                    { field: "dosar", headerName: "Dosar", flex: 1 },
                    { field: "factura", headerName: "Factura", flex: 1 },
                    {
                      field: "dataFactura",
                      headerName: "Data Factura",
                      flex: 1,
                    },
                    {
                      field: "sumaPenalizare",
                      headerName: "Suma Penalizare",
                      flex: 1,
                    },
                    { field: "moneda", headerName: "Moneda", flex: 1 },
                    { field: "dovezi", headerName: "Dovezi", flex: 1 },
                    { field: "agent", headerName: "Agent", flex: 1 },
                    { field: "status", headerName: "Status", flex: 1 },
                    { field: "tichetNr", headerName: "Tichet Nr", flex: 1 },
                    {
                      field: "addDate",
                      headerName: "Adaugat la Data",
                      flex: 2,
                    },
                  ]}
                  rows={agentPenalizariTrimise}
                />
              </div>
            </div>
          )}
          {/* {tempInfoSalarii.length != 0 ? */}

          <div className="box">
            <div className="boxHeader">
              <h2>
                <span>Incasari din sistemul anterior</span>
                {/* <span>{tempInfoSalarii[0].suma} Lei</span> */}
              </h2>
            </div>
            <div className="boxContent">
              <div className="infoRapid">
                <p>Drag/a tovaras/a,</p>
                <br></br>
                <p>
                  Avem o veste importanta pentru tine! Incepand cu luna Iunie,
                  sistemul de plata a salariilor a fost imbunatatit, iar
                  comisioanele profitului realizat se vor achita integral in
                  luna imediat urmatoare{" "}
                  <span>
                    (ex: productia lunii Iunie se plateste in 20 Iulie).
                  </span>
                </p>
                <p>
                  Acum poti vedea noul USER KPI in DASHBOARD-ul platformei
                  noastre interne. Poti regasi informatia salariala atat pentru
                  luna in curs{" "}
                  <span>(cu update o data pe zi a profitului din Getex)</span>{" "}
                  cat si pentru luna anterioara.
                </p>
                <p>
                  De asemenea, facem public ca suntem mandri de TOP 3 agenti ai
                  nostri{" "}
                  <span>
                    (care beneficiaza de un bonus pentru aceasta performanta)
                  </span>
                  , iar acest top se va actualiza si el zilnic. -{" "}
                  <span>DO YOUR BEST TO STAY ON TOP!</span>
                </p>
                <p>
                  Regasesti si un mic grafic care iti arata cat de aproape esti
                  de urmatorul tau PRAG si salariul corespondent.
                </p>
                <p>
                  Probabil ca te intrebi si ce se intampla cu incasarile tale
                  pentru dosarele din vechiul sistem{" "}
                  <span>(adica dosarele realizate pana in 31.05.2023)</span>: Ei
                  bine, poti sta linistit/a, ne-am gandit si la asta si vei avea
                  afisajul lor si aici{" "}
                  <span>
                    (insa nu in timp real ci mereu doar pentru luna anterioara,
                    odata cu finalizarea calculelor salariale, inainte de plata
                    acestora).
                  </span>
                </p>
                <p>
                  Din lista de facturi incasate pe care o regasesti in GETEX
                  USER KPI se vor extrage doar incasarile ce apartin dosarelor
                  realizate pana la data de <span>31.05.2023.</span>
                </p>
                <p>
                  Speram ca aceste vesti ti-au adus zambetul pe fata si ti-au
                  dat curajul de a-ti depasi limitele!
                </p>
                <br></br>
                <p>
                  <span>Spor la bani!</span> 🚀🚀🚀🚀
                </p>
                <br></br>
                <p>
                  P.S: Pentru orice intrebari si sugestii referitoare la
                  Salarizare sau afisajul din DASHBOARD poti contacta{" "}
                  <span>plati@euroteamgb.com</span> intr-un tiket!
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MainDash;
