import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import pathAPI from "../../../pathAPI";

const COLUMN_MAPPING = {
    type: "Tip",
    marca: "Marca",
    model: "Model",
    serie: "Serie",
    data_achizitie: "Data Achizitie",
    pret_achizitie_net: "Pret Achizitie Net",
    furnizor: "Furnizor",
};

const typeMapping = {
    1: "Monitor",
    2: "Unitate",
    3: "Laptop",
    4: "Tastatura",
    5: "Mouse",
    6: "Telefon",
};

export default function ListaEchipamente() {
    const [echipamente, setEchipamente] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .post(pathAPI + "dashboard/users/echipamenteSlots/", {
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
            })
            .then((response) => {
                if (response.data.success === "true") {
                    const echipamenteData = response.data.echipamenteSlots.map((item, index) => ({
                        id: index + 1,
                        ...item,
                    }));
                    setEchipamente(echipamenteData);
                } else {
                    console.error("Failed to fetch echipamente.");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching echipamente:", error);
                setLoading(false);
            });
    }, []);

    const columns = Object.keys(COLUMN_MAPPING).map((field) => ({
        field: field,
        headerName: COLUMN_MAPPING[field],
        flex: 1,
        valueGetter: (params) => {
            if (field === "type") {
                return typeMapping[params.row[field]] || "Unknown";
            }
            return params.row[field];
        },
    }));

    return (
        <div style={{ height: 600, width: "100%" }}>
            {echipamente.length > 0 ? (
                <DataGrid rows={echipamente} columns={columns} loading={loading} autoHeight />
            ) : (
                <p>No equipment data available.</p>
            )}
        </div>
    );
}
