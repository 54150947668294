import axios from "axios";
import {
  GET_NEWSLETTER_DATA_SUCCESS,
  GET_NEWSLETTER_DATA_FAILURE,
  INSERT_NEWSLETTER_DATA_SUCCESS,
  INSERT_NEWSLETTER_DATA_FAILURE,
  SEND_NEWSLETTER_EMAILS_SUCCESS,
  SEND_NEWSLETTER_EMAILS_FAILURE,
  INSERT_CLIENTS_EMAILS_SUCCESS,
  INSERT_CLIENTS_EMAILS_FAILURE,
} from "../actionTypes";
import pathAPI from "../../pathAPI";
import axiosInstance from "../../Helpers/axiosInstance";

export const getNewsletterDataSuccess = (data) => ({
  type: GET_NEWSLETTER_DATA_SUCCESS,
  payload: data,
});

export const getNewsletterDataFailure = (error) => ({
  type: GET_NEWSLETTER_DATA_FAILURE,
  payload: error,
});

export const getNewsletterData = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/newsletter_comenzi/getNewsLetterData`
      );
      console.log(response.data);
      dispatch(getNewsletterDataSuccess(response.data));
    } catch (error) {
      dispatch(getNewsletterDataFailure(error.message));
    }
  };
};

export const insertNewsletterDataSuccess = () => ({
  type: INSERT_NEWSLETTER_DATA_SUCCESS,
});

export const insertNewsletterDataFailure = (error) => ({
  type: INSERT_NEWSLETTER_DATA_FAILURE,
  payload: error,
});

export const insertNewsletterData = (formData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${pathAPI}dashboard/newsletter_comenzi/insertNewsLetterData`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(insertNewsletterDataSuccess());
    } catch (error) {
      dispatch(insertNewsletterDataFailure(error.message));
    }
  };
};
export const sendNewsletterEmailsSuccess = () => ({
  type: SEND_NEWSLETTER_EMAILS_SUCCESS,
});

export const sendNewsletterEmailsFailure = (error) => ({
  type: SEND_NEWSLETTER_EMAILS_FAILURE,
  payload: error,
});
export const sendNewsletterEmails = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${pathAPI}dashboard/newsletter_comenzi/sendNewsletterEmails`
      );
      dispatch(sendNewsletterEmailsSuccess());
    } catch (error) {
      dispatch(sendNewsletterEmailsFailure(error.message));
    }
  };
};
export const insertClientsEmailsSuccess = () => ({
  type: INSERT_CLIENTS_EMAILS_SUCCESS,
});

export const insertClientsEmailsFailure = (error) => ({
  type: INSERT_CLIENTS_EMAILS_FAILURE,
  payload: error,
});

export const insertClientsEmails = (formData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${pathAPI}dashboard/newsletter_comenzi/insertClientEmails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(insertClientsEmailsSuccess());
    } catch (error) {
      dispatch(insertClientsEmailsFailure(error.message));
    }
  };
};
