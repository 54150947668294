// UserModal.js
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTesaNew,
  getTesaData,
  getTesaHistory,
  updateTesaNew,
} from "../../../Actions/Tesa/TesaActionNew";
import { getUserById } from "../../../Actions/Users/usersAction";
import SnackbarComponent from "../../../Components/Alerts/SnackbarComponent";
import DataGridReusableCrud, {
  renderCellExpand,
} from "../../../Components/DataGridCrud/DataGridCrud";
import pathAPI from "../../../pathAPI";
import "./Users.css";

const UserDetails = ({ open, handleClose, user }) => {
  const users = useSelector((state) => state?.users?.user?.user);
  const salariuTesa = useSelector((state) => state.tesaNew.userTesaDetails);
  const salariiTesa = useSelector(
    (state) => state.tesaNew.userTesaHistoryDetails
  );
  const [dataToInsert, setDataToInsert] = useState({});
  const [userSetups, setUserSetups] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const dispatch = useDispatch();
  const parentColumns = [
    {
      field: "salariu_net",
      headerName: "Salariu Net",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "iban",
      headerName: "Iban",
      flex: 1,
      renderCell: () => {
        return <p>{users?.iban}</p>;
      },
    },

    {
      field: "data_angajare",
      headerName: "Data Angajare",
      flex: 1,
      type: "date",
      filterable: true,
      valueGetter: (params) => {
        return new Date(users?.data_angajare);
      },
    },
    {
      field: "yearMonth",
      headerName: "Year month",
      flex: 1,
      editable: false,
      type: "date",
      filterable: true,
      valueGetter: (params) => {
        return new Date(params.value);
      },
    },
  ];
  useEffect(() => {
    dispatch(getUserById(user?.id));
    dispatch(getTesaData(user?.id));
    dispatch(getTesaHistory(user?.id));
    if (user?.id) {
      setDataToInsert({
        user_id: user?.id,
      });
    }
  }, [dispatch, user]);
  //   const shouldRefresh = () => {
  //     dispatch(getUserById(user?.id));
  //     dispatch(getTesaData(user?.id));
  //     dispatch(getTesaHistory(user?.id));
  //   };
  const handleUpdate = (requestData) => {
    dispatch(updateTesaNew(requestData)).then((res) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Actualizare reușită!");
      setSnackbarOpen(true);
      dispatch(getUserById(user?.id));
      dispatch(getTesaData(user?.id));
      dispatch(getTesaHistory(user?.id));
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteTesaNew(id)).then((res) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Actualizare reușită!");
      setSnackbarOpen(true);
      dispatch(getUserById(user?.id));
      dispatch(getTesaData(user?.id));
      dispatch(getTesaHistory(user?.id));
    });
  };


  const getUserSetups = useCallback(() => {
    axios
      .post(pathAPI + "dashboard/evidenta_echipamente/getUserSetups", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_user: user?.id
      })
      .then(function (response) {
        console.log("User setups", response.data);
        setUserSetups(response.data.usersSetups)
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  }, [user]);

  useEffect(() => {
    if (user) {
      getUserSetups();
    }
  }, [user]);

  // Group setups by the setup name
  const groupedSetups = userSetups?.reduce((acc, userSetup) => {
    if (!acc[userSetup.setup]) {
      acc[userSetup.setup] = [];
    }
    acc[userSetup.setup].push(userSetup);
    return acc;
  }, {});

  // Mapping of type numbers to their names
  const typeMapping = {
    1: 'Monitor',
    2: 'Unitate',
    3: 'Laptop',
    4: 'Tastatura',
    5: 'Mouse',
    6: 'Telefon'
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      className="userDetailsDialog"
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            padding: "12px",
            width: "100%",
          },
        },
      }}
    >
      {salariiTesa?.user ? (
        <DataGridReusableCrud
          initialRows={salariiTesa?.user}
          parentColumns={parentColumns}
          updateDataCallBack={handleUpdate}
          deleteDataCallBack={handleDelete}
          insertDataAxios={pathAPI + "/dashboard/salarizareNew/inserTesaNEW/"}
          shouldRefresh={"shouldRefresh"}
          dataToInsert={dataToInsert}
        ></DataGridReusableCrud>
      ) : (
        <h1>Nu sunt date disponibile despre acest utilizator in Tesa</h1>
      )}
      {userSetups ? (
        <>
          {Object.keys(groupedSetups).map((setup) => (
            <div key={setup}>
              <h3>Detalii Setup</h3>
              <p>Acest utilizator este asociat la: {setup}</p>
              <h3>Lista echipamente</h3>
              {groupedSetups[setup]
                .sort((a, b) => a.type - b.type)
                .map((userSetup) => (
                  <div key={userSetup.id}>
                    {userSetup.marca && (
                      <>
                        <p>{typeMapping[userSetup.type] + ": " + userSetup.marca + " " + userSetup.model + " " + (userSetup.extra_details ? + userSetup.extra_details : "")}</p>
                      </>
                    )}
                  </div>
                ))}
            </div>
          ))}
        </>
      ) : (
        <h1>Nu exista echipamente</h1>
      )}

      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
      {
        <SnackbarComponent
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      }
    </Dialog>
  );
};

export default UserDetails;
