import React from "react";
import Session from "../../Components/Session.js";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import GpsVehicles from "./GpsVehicles.js";
import GpsDriver from "./GpsDriver.js";
import GpsTrailers from "./GpsTrailers.js";
const MainGPS = () => {
  var auth = false;
  if (Session([999, 800])) {
    auth = true;
  }

  const [value, setValue] = React.useState("3");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {auth ? (
        <div className="box">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="GPS Trailers" value="3" />
                <Tab label="Control Optiuni" value="1" />
              </TabList>
            </Box>

            <TabPanel value="3">{<GpsTrailers activeTab="3" />}</TabPanel>
            <TabPanel value="1">{<GpsTrailers activeTab="1" />}</TabPanel>
          </TabContext>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MainGPS;
