import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import CanvasJSReact from './canvasjs.react';
import pathAPI from '../../pathAPI';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Statistics = () => {

    const [dataChart, setDataChart] = useState([])

    const getStatistics = () => {
        axios.post(pathAPI + '/dashboard/ticketing/getStatistics/').then((res) => {
            setDataChart(res.data)
        }) 
    }
    useEffect(()=>{
        getStatistics()
    }, [])
    const options = {
        animationEnabled: true,	
        title:{
            text: "Tichete"
        },
        axisY : {
            title: "Activitate",
            interval: 1
        },
        toolTip: {
            shared: true
        },
        data: dataChart
    }

    return (
    <div>
        <CanvasJSChart options = {options} 
            /* onRef={ref => this.chart = ref} */
        />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
    );

}
export default Statistics;