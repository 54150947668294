import { TextField, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import pathAPI from "../../../../pathAPI";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Autocomplete } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { CheckCircle } from "@mui/icons-material";

export default function ControlCurs() {
  const [listaCursuri, setListaCursuri] = useState(null);
  const [users, setUsers] = useState(null);
  const [cursSelectat, setCursSelectat] = useState(null);
  const [useriCurs, setUseriCurs] = useState(null);
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState(null);
  const [selectedUserToEdit, setSelectedUserToEdit] = useState(null);

  const getAllCursuri = () => {
    axios
      .post(pathAPI + "dashboard/cursuri/getAllCursuri", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setListaCursuri(response.data.listaCursuri);
        // console.log(response.data)
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const getAllUsers = () => {
    axios
      .post(pathAPI + "dashboard/cursuri/getAllUsers", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setUsers(response.data.users);
        // console.log(response.data.users)
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const handleSetectCurs = (event, newValue) => {
    setCursSelectat(newValue);
  };

  const handleSetectUsersToAdd = (event, newValue) => {
    setSelectedUsersToAdd(newValue);
  };

  const insertUserToCurs = () => {
    axios
      .post(pathAPI + "dashboard/cursuri/insertUserToCurs", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_curs: cursSelectat.id,
        id_user: selectedUsersToAdd.id,
      })
      .then(function (response) {
        // console.log(response);
        setSelectedUsersToAdd(null);
        selectAllUsersFromCurs();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const deleteUserFromCurs = (id_user) => {
    axios
      .post(pathAPI + "dashboard/cursuri/deleteUserFromCurs", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_curs: cursSelectat.id,
        id_user: id_user,
      })
      .then(function (response) {
        // console.log(response);
        setSelectedUserToEdit(null);
        selectAllUsersFromCurs();
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  const selectAllUsersFromCurs = () => {
    axios
      .post(pathAPI + "dashboard/cursuri/selectAllUsersFromCurs", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id_curs: cursSelectat.id,
      })
      .then(function (response) {
        setUseriCurs(response.data.useriCurs);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });
  };

  useEffect(() => {
    if (cursSelectat) {
      selectAllUsersFromCurs();
    }
  }, [cursSelectat]);

  useEffect(() => {
    getAllCursuri();
    getAllUsers();
  }, []);
  return (
    <div className="containerForControl">
      <div className="headerForControl">Control Curs</div>
      <div className="bodyForControl">
        <Autocomplete
          size="small"
          name="selectedCurs"
          id="selectedCurs"
          autoComplete
          options={listaCursuri ? listaCursuri : ""}
          getOptionLabel={(option) => option.nume_curs}
          includeInputInList
          renderInput={(params) => (
            <TextField {...params} label="Alege cursul" required />
          )}
          onChange={(event, newValue) => handleSetectCurs(event, newValue)}
        />

        {useriCurs && cursSelectat && (
          <>
            <div className="tableCrudForContainerControl">
              <div className="tcfcc-header">
                <div className="tcfcc-cell">Participanti</div>
                <div className="tcfcc-cell">Editeaza</div>
              </div>
              <div className="tcfcc-body">
                {useriCurs.map((userCurs) => {
                  return (
                    <div className="tcfcc-body-row">
                      <div className="tcfcc-cell">
                        {userCurs.email} | {userCurs.name}
                      </div>
                      <div className="tcfcc-cell">
                        <IconButton
                          onClick={() => setSelectedUserToEdit(userCurs)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
                <div className="tcfcc-body-row">
                  <div className="tcfcc-cell">
                    <Autocomplete
                      size="small"
                      name="cc"
                      id="tags-outlined"
                      autoComplete
                      // options={users ? users : ''}
                      getOptionLabel={(user) => user.email + " | " + user.name}
                      filterSelectedOptions
                      options={users.filter(
                        (option) =>
                          !useriCurs.some(
                            (userCurs) => userCurs.id === option.id
                          )
                      )}
                      value={selectedUsersToAdd}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Alege utilizatorul"
                          placeholder="Alege utilizatorul"
                          required
                        />
                      )}
                      onChange={(event, newValue) =>
                        handleSetectUsersToAdd(event, newValue)
                      }
                    />
                  </div>
                  <div className="tcfcc-cell">
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={insertUserToCurs}
                    >
                      Adauga cursant
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        className="ticket-dialog"
        open={selectedUserToEdit}
        onClose={() => setSelectedUserToEdit(null)}
      >
        {selectedUserToEdit && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi valoarea: {selectedUserToEdit.email}</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteUserFromCurs(selectedUserToEdit.id)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSelectedUserToEdit(null)}
              >
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}
