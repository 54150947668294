import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import axios from "axios";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
// import "quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import pathAPI from "../../pathAPI";

function EmailStructureDialog({ open, onClose }) {
  const [emailStructures, setEmailStructures] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedStructure, setSelectedStructure] = useState(null);
  const [editedSubject, setEditedSubject] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [newSubject, setNewSubject] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [insertOpen, setInsertOpen] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [newContent, setNewContent] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleEditOpen = (structure) => {
    setSelectedStructure(structure);
    setEditedSubject(structure.subject);
    setEditedContent(structure.description);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setSelectedStructure(null);
  };

  const handleDeleteOpen = (structure) => {
    setSelectedStructure(structure);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedStructure(null);
  };
  const handleInsertOpen = () => {
    setInsertOpen(true);
  };

  const handleInsertClose = () => {
    setInsertOpen(false);
  };
  const getEmailsData = () => {
    axios
      .post(pathAPI + "dashboard/surveys/getEmailStructures", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        setEmailStructures(res.data.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getEmailsData();
  }, []);

  const handleEditSave = () => {
    if (selectedStructure) {
      const updatedStructure = {
        id: selectedStructure.id,
        subject: editedSubject,
        description: DOMPurify.sanitize(editedContent), // Sanitize the HTML content
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      };

      axios
        .post(
          pathAPI + "dashboard/surveys/editEmailStructure",
          updatedStructure
        )
        .then((res) => {
          if (res.data.status === 1) {
            setEmailStructures((prevStructures) =>
              prevStructures.map((structure) =>
                structure.id === selectedStructure.id
                  ? updatedStructure
                  : structure
              )
            );
          } else {
            console.error("Edit failed: " + res.data.message);
          }
        })
        .catch((error) => {
          console.error("Edit failed: " + error.message);
        })
        .finally(() => {
          setEditOpen(false);
          setSelectedStructure(null);
        });
    }
  };
  const handleDeleteConfirm = () => {
    if (selectedStructure) {
      const requestData = {
        id: selectedStructure.id,
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      };

      axios
        .post(
          pathAPI + "dashboard/surveys/deleteEmailStructure",
          requestData
        )
        .then((res) => {
          if (res.data.status === 1) {
            setEmailStructures((prevStructures) =>
              prevStructures.filter(
                (structure) => structure.id !== selectedStructure.id
              )
            );
          } else {
            console.error("Deletion failed: " + res.data.message);
          }
        })
        .catch((error) => {
          console.error("Deletion failed: " + error.message);
        })
        .finally(() => {
          setDeleteOpen(false);
          setSelectedStructure(null);
        });
    }
  };
  const handleInsert = () => {
    const newStructure = {
      subject: newSubject,
      description: newContent,
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
    };

    axios
      .post(
        pathAPI + "dashboard/surveys/insertEmailStructure",
        newStructure
      )
      .then((res) => {
        setNewContent("");
        setNewSubject("");

        setInsertOpen(false);
        getEmailsData();
      })
      .catch((error) => {
        console.error("Insertion failed: " + error.message);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <Button variant="outlined" onClick={() => handleInsertOpen()}>
          Insert
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emailStructures.map((structure) => (
              <TableRow key={structure.id}>
                <TableCell>{structure.id}</TableCell>
                <TableCell>{structure.subject}</TableCell>
                <TableCell>
                  {parse(structure.description, {
                    replace: (node) => {
                      if (node.type === "tag" && node.name === "button") {
                        return <Button text={node.children[0].data} />;
                      }
                    },
                  })}
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleEditOpen(structure)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteOpen(structure)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Dialog>
      {/* Edit Dialog */}
      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Edit Email Structure</DialogTitle>
        <DialogContent className="createSurvey">
          <TextField
            sx={{ mt: 2 }}
            label="Subject"
            variant="outlined"
            fullWidth
            value={editedSubject}
            onChange={(e) => setEditedSubject(e.target.value)}
          />
          {/* <ReactQuill
            value={editedContent}
            onChange={(value) => setEditedContent(value)}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["clean"],
                [{ color: [] }],
                ["emoji"], 
                ["link"],
              ],
              "emoji-toolbar": true,
              "emoji-shortname": true, 
            }}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "color",

              "bullet",
              "emoji",
            ]}
          /> */}
          <Button variant="outlined" onClick={handleEditSave}>
            Save
          </Button>
        </DialogContent>
      </Dialog>
      {/* Delete Dialog */}
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Confirma stergerea</DialogTitle>
        <DialogContent>
          Esti sigur ca vrei sa stergi structura de email?
          <Button variant="outlined" onClick={handleDeleteConfirm}>
            Sterge
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={insertOpen} onClose={handleInsertClose}>
        <DialogTitle>Insereaza Structura pentru email</DialogTitle>
        <DialogContent>
          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            value={newSubject}
            onChange={(e) => setNewSubject(e.target.value)}
          />
          {/* <ReactQuill
            value={newContent}
            onChange={(value) => setNewContent(value)}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["clean"],
                [{ color: [] }],
                ["emoji"], 
              ],
              "emoji-toolbar": true, 
              "emoji-shortname": true,
            }}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "color",
              "bullet",
              "emoji", // Add the emoji format
            ]}
          /> */}
          <Button variant="outlined" onClick={handleInsert}>
            Insert
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EmailStructureDialog;
