// ChoiceForm.jsx
import React from "react";
import { TextField, Button, Divider, Typography, Dialog } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

function ChoiceForm({ question, setQuestions, questions, qIndex }) {
  const updateChoice = (choiceText, cIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].choices[cIndex] = choiceText;
    setQuestions(updatedQuestions);
  };

  const addChoice = () => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].choices.push("");
    setQuestions(updatedQuestions);
  };

  const removeChoice = (cIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].choices.splice(cIndex, 1);
    setQuestions(updatedQuestions);
  };

  return (
    <div>
      <h2 className="choicesForm">
        Add Choices for Question: {question.questionText}
      </h2>
      {question.choices.map((choice, cIndex) => (
        <div className="choiceForm" key={cIndex}>
          <TextField
            type="text"
            placeholder="Choice Text"
            value={choice}
            onChange={(e) => updateChoice(e.target.value, cIndex)}
          />
          <Button onClick={() => removeChoice(cIndex)}>
            <DeleteIcon />
          </Button>
        </div>
      ))}
      <Button onClick={addChoice}>
        <AddIcon />
      </Button>
    </div>
  );
}

export default ChoiceForm;
