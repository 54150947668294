import axios from "axios";
import React, { useEffect, useState } from "react";
import pathAPI from "../../pathAPI";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import MuiXDataGrid from "../../Components/Table/DataGrid";
import DataGridReusableCrud, {
  renderCellExpand,
} from "../../Components/DataGridCrud/DataGridCrud";
import { useDispatch } from "react-redux";
import {
  deleteTesaNew,
  getTesaNew,
  updateTesaNew,
} from "../../Actions/Tesa/TesaActionNew";
import { useSelector } from "react-redux";
import { TextField, Autocomplete } from "@mui/material";
import { getAllUsers } from "../../Actions/Users/usersAction";
import SnackbarComponent from "../../Components/Alerts/SnackbarComponent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const TesaNew = () => {
  const dispatch = useDispatch();
  const salariiTesa = useSelector((state) => state.tesaNew.tesaData);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [users, setUsers] = useState([]);
  const [datePicked, setDatePicked] = useState(dayjs().format("YYYY-MM-DD"));

  useEffect(() => {
    dispatch(getTesaNew(datePicked));
    axios
      .post(
        pathAPI + "dashboard/salarizare/getAllUsersFullName/",
        {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
        }
      )
      .then(function (response) {
        setUsers(response.data);
      });
  }, [dispatch, datePicked]);

  const parentColumns = [
    {
      field: "nume_prenume",
      headerName: "Nume",
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        return (
          <Autocomplete
            sx={{ width: "100%" }}
            id="tags-standard"
            options={users}
            getOptionLabel={(option) => option.fullName || ""}
            onChange={(event, newValue) => {
              const selectedFullName = newValue ? newValue.fullName : "";
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedFullName,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Favorites"
                value={params.value ? params.value.fullName : ""}
                sx={{ width: "100%" }}
              />
            )}
          />
        );
      },
    },
    {
      field: "salariu_net",
      headerName: "Salariu Net",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "iban",
      headerName: "Iban",
      flex: 1,
      renderCell: renderCellExpand,
    },

    {
      field: "data_angajare",
      headerName: "Data Angajare",
      flex: 1,
      type: "date",
      filterable: true,
      valueGetter: (params) => {
        return new Date(params.value);
      },
    },
    {
      field: "yearMonth",
      headerName: "Year month",
      flex: 1,
      editable: true,
      type: "date",
      filterable: true,
      valueGetter: (params) => {
        return new Date(params.value);
      },
    },
  ];
  const handleUpdate = (requestData) => {
    dispatch(updateTesaNew(requestData)).then((res) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Actualizare reușită!");
      setSnackbarOpen(true);
      dispatch(getTesaNew(datePicked));
    });
  };
  const handleDelete = (transportatorId) => {
    console.log(transportatorId);
    dispatch(deleteTesaNew(transportatorId)).then((res) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Actualizare reușită!");
      setSnackbarOpen(true);
      dispatch(getTesaNew(datePicked));
    });
  };
  const shouldRefresh = () => {
    dispatch(getTesaNew()).then((res) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Actualizare reușită!");
      setSnackbarOpen(true);
      dispatch(getTesaNew(datePicked));
    });
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Luna / anul"
          views={["year", "month"]}
          value={datePicked}
          onChange={(newValue) => {
            setDatePicked(newValue);
          }}
          format="DD-MM-YYYY"
          renderInput={(params) => (
            <TextField size="small" {...params} required />
          )}
        />
      </LocalizationProvider>
      {salariiTesa ? (
        <DataGridReusableCrud
          initialRows={salariiTesa}
          parentColumns={parentColumns}
          updateDataCallBack={handleUpdate}
          deleteDataCallBack={handleDelete}
          insertDataAxios={pathAPI + "/dashboard/salarizare/inserTesaNEW/"}
          shouldRefresh={"shouldRefresh"}
        ></DataGridReusableCrud>
      ) : (
        ""
      )}
      {
        <SnackbarComponent
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      }
    </>
  );
};
export default TesaNew;
