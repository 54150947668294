import {
  GET_NEWSLETTER_DATA_SUCCESS,
  GET_NEWSLETTER_DATA_FAILURE,
  INSERT_NEWSLETTER_DATA_SUCCESS,
  INSERT_NEWSLETTER_DATA_FAILURE,
  GET_TRANSPORTATORI_SUCCES,
  GET_TRANSPORTATORI_FAILURE,
  UPDATE_TRANSPORTATOR_SUCCESS,
  UPDATE_TRANSPORTATOR_FAILURE,
  DELETE_TRANSPORTATOR_SUCCESS,
  DELETE_TRANSPORTATOR_FAILURE,
  GET_COUNTRY_CODES_SUCCESS,
  GET_COUNTRY_CODES_FAILURE,
} from "../../Actions/actionTypes";

const initialNewsletterState = {
  data: null,
  error: null,
  transportatori: null,
  countries: [],
};

const newsletterReducer = (state = initialNewsletterState, action) => {
  switch (action.type) {
    case GET_NEWSLETTER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
      };
    case GET_NEWSLETTER_DATA_FAILURE:
    case GET_TRANSPORTATORI_SUCCES:
      return {
        ...state,
        transportatori: action.payload,
        error: null,
      };
    case GET_TRANSPORTATORI_FAILURE:
      return {
        ...state,
        transportatori: null,
        error: action.payload,
      };
    case INSERT_NEWSLETTER_DATA_FAILURE:
      return {
        ...state,
        data: null,
        error: action.payload,
      };
    case INSERT_NEWSLETTER_DATA_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case UPDATE_TRANSPORTATOR_SUCCESS:
      // Update the transportatori list with the updated transportator data
      const updatedTransportator = action.payload;
      const updatedTransportatori = state.transportatori.map((transportator) =>
        transportator.id === updatedTransportator.id
          ? updatedTransportator
          : transportator
      );
      return {
        ...state,
        transportatori: updatedTransportatori,
        error: null,
      };
    case UPDATE_TRANSPORTATOR_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_TRANSPORTATOR_SUCCESS:
      const deletedTransportatorId = action.payload;
      const updatedTransportatoriAfterDelete = state.transportatori.filter(
        (transportator) => transportator.id !== deletedTransportatorId
      );
      return {
        ...state,
        transportatori: updatedTransportatoriAfterDelete,
        error: null,
      };
    case DELETE_TRANSPORTATOR_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_COUNTRY_CODES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        error: null,
      };
    case GET_COUNTRY_CODES_FAILURE:
      return {
        ...state,
        countries: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default newsletterReducer;
