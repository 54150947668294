import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import pathAPI from "../pathAPI.js";
import "./login.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Login = (props) => {
  let navigate = useNavigate();
  const [isntLoggedIn, setIsntLoggedIn] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");

  useEffect(() => {
    const checkData = {
      email: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
    };
    if (checkData.email && checkData.token) {
      setIsntLoggedIn(false);
    }
  }, []);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const submitForm = (e) => {
    e.preventDefault();
    const sendData = {
      email: data.email,
      password: data.password,
    };

    axios.post(pathAPI + "dashboard/login.php", sendData).then((result) => {
      if (result.data.user.status === 0) {
        setEmailError("E-mailul nu exista !");
      }
      if (result.data.user.status === 1) {
        window.localStorage.setItem("name", result.data.user.name);
        window.localStorage.setItem("email", result.data.user.email);
        window.localStorage.setItem("token", result.data.user.token);
        window.localStorage.setItem("user_id", result.data.user.user_id);
        window.localStorage.setItem("avatar", result.data.user.avatar);

        const intervalId = setInterval(() => {
          clearInterval(intervalId);
          setIsntLoggedIn(false);
          navigate(`/`);
          window.location.reload(false);
        });
      }
      if (result.data.user.status === 2) {
        setPasswordError("Parola nu exista !");
      }
    });
  };
  return (
    <>
      {isntLoggedIn ? (
        <>
          <div className="loginPage">
            <div className="box">
              <div class="boxHeader">
                <h2>Sign in</h2>
              </div>
              <div class="boxContent">
                <Box component="form" onSubmit={submitForm} noValidate>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                    value={data.email}
                    error={!!emailError}
                    helperText={emailError}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChange}
                    value={data.password}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    value="Login"
                    name="submit"
                    disabled={loginMessage}
                  >
                    Sign In
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Login;
