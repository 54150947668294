import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import axiosInstance from "../../Helpers/axiosInstance";
import pathAPI from "../../pathAPI";

const FeedbackChart = ({ feedbacks1 }) => {
  const [feedbacks, setFeedbacks] = React.useState([]);

  const getFeedbacks = () => {
    let params = new FormData();
    if (feedbacks1) {
      params.append("fb_to", feedbacks1.fb_to);
      params.append("limit", "none");
    }
    axiosInstance
      .post(`${pathAPI}dashboard/feedback/getFeedbacks`, params)
      .then((res) => {
        setFeedbacks(res.data.feedbacks);
      })
      .catch((err) => console.error(err));
  };

  const getMonthlyFeedbackData = () => {
    let monthlyFeedbackData = [];
    let currentDate = new Date();

    for (let i = 0; i < 6; i++) {
      let currentMonthFeedbacks = feedbacks.filter((feedback) => {
        let feedbackDate = new Date(feedback.date);
        return (
          feedbackDate.getMonth() === currentDate.getMonth() &&
          feedbackDate.getFullYear() === currentDate.getFullYear()
        );
      });

      let currentMonthStarsSum = currentMonthFeedbacks.reduce(
        (total, feedback) => total + parseInt(feedback.stars),
        0
      );
      let currentMonthFeedbackCount = currentMonthFeedbacks.length;

      let currentMonthAvgStars =
        currentMonthFeedbacks.length > 0
          ? (currentMonthStarsSum / currentMonthFeedbacks.length).toFixed(2)
          : 0;

      monthlyFeedbackData.unshift({
        month: currentDate.toLocaleString("default", { month: "short" }),
        avgStars: currentMonthAvgStars,
        totalStars: currentMonthFeedbackCount,
      });

      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    return monthlyFeedbackData;
  };

  const data = {
    series: [
      {
        name: "Avg Stars",
        data: getMonthlyFeedbackData().map((item) => item.avgStars),
      },
      {
        name: "Total Reviews",
        data: getMonthlyFeedbackData().map((item) => item.totalStars),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: "auto",
        toolbar: {
          show: true,
        },
      },
      fill: {
        colors: ["#008ADA", "#33FF99"],
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          format: "MMM yyyy",
        },
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: getMonthlyFeedbackData().map((item) => item.month),
      },
      yaxis: {
        show: true,
      },
    },
  };
  React.useEffect(() => {
    getFeedbacks();
  }, []);

  return (
    <div>
      <p style={{ color: "#0075ba", fontWeight: 700 }}>{feedbacks1.fb_to}</p>
      <Chart options={data.options} series={data.series} type="bar" />
    </div>
  );
};

export default FeedbackChart;
