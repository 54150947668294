import React, { useState, useEffect } from "react";
import { TextField, Button, IconButton } from "@mui/material";
import { Autocomplete } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import "./Team.css";

import {
  assignUserToTeam,
  createRole,
  createTeam,
  deleteMemberFromTeam,
  deleteRole,
  deleteTeam,
  getAllRoles,
  getAllTeams,
  getTeamById,
  updateRole,
  updateTeam,
} from "../../../Actions/Users/Teams/teamActions";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteWithCrud from "../../../Components/AutocompleteWithCrud/AutocompleteWithCrud";
import { getAllUsers } from "../../../Actions/Users/usersAction";
import GlobalAlert from "../../../Helpers/Alert";
const TeamManager = () => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const teamDetails = useSelector((state) => state.teamDetails);
  const users = useSelector((state) => state.users.users);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [openAlert, setOpenAlert] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);

  const [selectedMemberToEdit, setSelectedMemberToEdit] = useState(null);
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllTeams());
    dispatch(getAllRoles());
  }, []);
  const handleSelectTeam = (event, newValue) => {
    dispatch(getTeamById(newValue.id));
  };
  const handleTeamInsert = (event, data) => {
    event.preventDefault();
    dispatch(createTeam(data?.NAME)).then((res) => {
      dispatch(getAllTeams());
    });
  };
  const handleRoleInsert = (event, data) => {
    event.preventDefault();
    dispatch(createRole(data.NAME)).then((res) => {
      dispatch(getAllRoles());
    });
  };
  const handleDeleteMemberFromTeam = (memberId) => {
    dispatch(deleteMemberFromTeam(teamDetails.team?.team_id, memberId)).then(
      (res) => {
        setSelectedMemberToEdit(null);
        dispatch(getTeamById(teamDetails.team?.team_id));
      }
    );
  };
  const handleAssignUserToTeam = () => {
    if (selectedUser && selectedRole) {
      dispatch(
        assignUserToTeam(
          teamDetails.team?.team_id,
          selectedUser.id,
          selectedRole.id
        )
      ).then(() => {
        setSelectedRole(null);
        setSelectedUser(null);
        dispatch(getTeamById(teamDetails.team?.team_id));
      });
    }
  };
  const handleDeleteDataFromAutocomplete = (option) => {
    dispatch(deleteTeam(option.id)).then((res) => {
      dispatch(getAllTeams());
    });
  };
  const handleDeleteRole = (option) => {
    dispatch(deleteRole(option.id)).then((res) => {
      dispatch(getAllRoles());
    });
  };
  const handleSelectedOption = (option) => {
    dispatch(getTeamById(option));
  };
  const handleUpdateAutoComplete = (id, data) => {
    dispatch(updateTeam(id, data?.NAME)).then((res) => {
      dispatch(getAllTeams());
    });
  };
  const handleUpdateRole = (updatedId, updatedData) => {
    dispatch(updateRole(updatedId, updatedData.NAME, updatedData.Level)).then(
      (res) => {
        dispatch(getAllRoles());
      }
    );
  };
  return (
    <div className="gridIsh">
      <div className="containerForControl">
        <div className="headerForControl">Control Echipe</div>

        <div className="bodyForControl">
          <AutocompleteWithCrud
            data={teamDetails.teams}
            optionName="team_name"
            setSelectedValue={setSelectedTeam}
            onSelectedOptionChange={handleSelectedOption}
            handleOptionInsert={handleTeamInsert}
            onUpdate={handleUpdateAutoComplete}
            handleDeleteData={handleDeleteDataFromAutocomplete}
            label="Alege Echipa*"
            fields={["NAME"]} // Example fields array
          ></AutocompleteWithCrud>

          {teamDetails && (
            <>
              <div className="tableCrudForContainerControl">
                <div className="tcfcc-header">
                  <div className="tcfcc-cell">Participanti</div>
                  <div className="tcfcc-cell">Role</div>

                  <div className="tcfcc-cell">Editeaza</div>
                </div>
                <div className="tcfcc-body">
                  {teamDetails?.team?.members?.map((member) => {
                    return (
                      <div className="tcfcc-body-row">
                        <div className="tcfcc-cell">{member?.user_name}</div>
                        <div className="tcfcc-cell">{member?.role_name}</div>

                        <div className="tcfcc-cell">
                          <IconButton
                            onClick={() => setSelectedMemberToEdit(member)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                  <div className="tcfcc-body-row">
                    <div className="tcfcc-cell">
                      {users && (
                        <>
                          <Autocomplete
                            className="autoCompleteMember"
                            size="small"
                            name="selectedUser"
                            value={selectedUser}
                            id="tags-outlined"
                            autoComplete
                            options={users}
                            getOptionLabel={(user) => user.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Alege utilizatorul"
                                placeholder="Alege utilizatorul"
                                required
                              />
                            )}
                            onChange={(event, newValue) =>
                              setSelectedUser(newValue)
                            }
                          />
                          <AutocompleteWithCrud
                            data={teamDetails.roles}
                            optionName="name"
                            setSelectedValue={setSelectedRole}
                            // onSelectedOptionChange={handleSelectedOption} this is optional
                            selectedValue={selectedRole}
                            handleOptionInsert={handleRoleInsert}
                            onUpdate={handleUpdateRole}
                            handleDeleteData={handleDeleteRole}
                            label="Alege Rolul*"
                            fields={["NAME", "Level"]}
                          ></AutocompleteWithCrud>
                        </>
                      )}
                    </div>
                    <div className="tcfcc-cell">
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={
                          !selectedUser || !selectedRole
                          // !teamDetails.team?.team_id
                        }
                        onClick={handleAssignUserToTeam}
                      >
                        Adauga membru
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Dialog
        className="ticket-dialog"
        open={selectedMemberToEdit}
        onClose={() => setSelectedMemberToEdit(null)}
      >
        {selectedMemberToEdit && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>
                Urmează să ștergi valoarea: {selectedMemberToEdit.user_name}
              </p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  handleDeleteMemberFromTeam(selectedMemberToEdit.user_id)
                }
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSelectedMemberToEdit(null)}
              >
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      {/* <GlobalAlert openAlert={openAlert} message={alertMessage}></GlobalAlert> */}
    </div>
  );
};

export default TeamManager;
