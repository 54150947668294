import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import "./Juridic.css";
import SnackbarComponent from "../../Components/Alerts/SnackbarComponent";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  roRO,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import pathAPI from "../../pathAPI";
import DeleteDialog from "../../Components/Dialogs/DeleteDialog";
import { TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../../Helpers/Loader";
import { additionalFieldsConfig } from "../Ticketing/aditionalFieldsConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { isEnterKey } from "@mui/x-data-grid/utils/keyboardUtils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Cursor from "quill/blots/cursor";
import LoadingButton from "@mui/lab/LoadingButton";
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      className="custom-box"
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

function EditToolbar({ setRows, setRowModesModel }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  return (
    <>
      <div>
        {" "}
        <GridToolbar></GridToolbar>
      </div>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}

export default function Juridic({ }) {
  const [selectedTicketJuridic, setSelectedTicketJuridic] =
    React.useState(null);

  const initialFormData = selectedTicketJuridic || {
    NUME_FIRMA: "",
    ADRESA_FIRMA: "",
    EMAIL_FIRMA: "",
    NR_COMANDA: "",
    DATA_COMANDA: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    console.log(selectedTicketJuridic);
    setFormData(selectedTicketJuridic || initialFormData);
    console.log(selectedTicketJuridic);
  }, [selectedTicketJuridic]);
  const [rows, setRows] = useState([]);
  const [juridicSubjects, setJuridicSubjects] = useState([]);
  const fileRefTicket = useRef();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [limbaDraft, setLimbaDraft] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [datePicked, setDatePicked] = useState(dayjs().format("YYYY-MM-DD"));
  const [uploadsAttachTichet, setUploadsAttachTichet] = useState(null);
  const [generazaClicked, setGenerazaClicked] = useState(false);
  const [disableTrimiteButton, setDisableTrimiteButton] = useState(false);
  const isTrimiteDisabled = !generazaClicked || disableTrimiteButton;
  const [isGenereazaDisabled, setIsGenereazaDisabled] = useState(false);
  const [pdfFilename, setPdfFilename] = useState("");
  const [loader, setLoader] = useState(false);
  const [isSendMailLoading, setIsSendMailLoading] = useState(false);

  const uploadAttachTicket = () => {
    fileRefTicket.current.click();
  };
  const handleCheckboxLanguageChange = (event) => {
    setLimbaDraft(event.target.checked);
  };

  const handleGenerazaClick = () => {
    setIsGenereazaDisabled(true);
    axios
      .post(pathAPI + "/dashboard/juridic/generatePdf/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id: formData.ID,
        draftChoice: limbaDraft
          ? formData.status_name_eng
          : formData.status_name_ro,
        Numaruldeinregistrare: formData.NumarulDeInregistrare || "",
        Datadeintregistrare: formData.DataDeInregistrare || "",
        Denumireaclientului: formData.DenumireaClientului || "",
        Adresaclientului: formData.AdresaClientului || "",
        Valoareainscrisapefacturatransmisadecatrenoi:
          formData.ValoareaInscrisaPeFacturaTransmisaDeCatreNoi || "",
        Numarulcomenziidetransportaclientului:
          formData.NumarulComenziiDeTransportAClientului || "",
        Denumireafurnizorului: formData.DenumireaFurnizorului || "",
        Adresafurnizorului: formData.AdresaFurnizorului || "",
        Numarulcomenziitransmisafurnizorului:
          formData.NumarulComenziiTransmisaFurnizorului || "",
        Datacomenziitransmisafurnizorului:
          formData.DataComenziiTransmisaFurnizorului || "",
        Numarulfacturiitransmisadecatrenoi:
          formData.NumarulFacturiiTransmisaDeCatreNoi || "",
        Numarulfacturiitransmisedecatrefurnizor:
          formData.NumarulFacturiiTransmiseDeCatreFurnizor || "",
        Denumireadebitorului: formData.DenumireaDebitorului || "",
        Adresadebitorului: formData.AdresaDebitorului || "",
        NumaruldeziledescadentaafacturiidinGETEX:
          formData.NumarulDeZileDeScadentaAFacturiiDinGETEX || "",
        obiectulfacturii: formData.ObiectulFacturii || "",
      })
      .then(function (response) {
        // setRows(response.data);
        setTimeout(() => {
          setIsGenereazaDisabled(false);
        }, 5000);
        getPdfFile();
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const getPdfFile = () => {
    if (selectedTicketJuridic) {
      const { ticketID } = selectedTicketJuridic;

      axios
        .post(pathAPI + "/dashboard/juridic/getPdfFile/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          id: formData.ID,
        })
        .then(function (response) {
          setGenerazaClicked(true);
          console.log(response?.data.data?.name);
          setPdfFilename(response?.data.data?.name);
        })
        .catch(function (error) {
          console.log("Request failed.");
        });
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDateChangeDetaliiJuridic = (newValue, name) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");

    setFormData({
      ...formData,
      [name]: formattedDate,
    });
  };

  const getJuridicData = () => {
    setLoader(true);

    axios
      .post(pathAPI + "/dashboard/juridic/getJuridicData/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setRows(response.data);
        setLoader(false);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const selectJuridicFiles = (juridicId, ticketingFiles, Checked) => {
    axios
      .post(pathAPI + "/dashboard/juridic/selectJuridicFile/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        ticketingFiles,
        juridicId,
        checked: Checked,
      })
      .then(function (response) {
        setSnackbarSeverity("success");
        if (response.data === "Value inserted") {
          setSnackbarMessage("Fisierul a fost selectat cu succes!");
        }
        if (response.data === "Value deleted") {
          setSnackbarMessage("Fisierul a fost deselectat cu succes!");
        }
        setSnackbarOpen(true);
        getJuridicFiles();
        getPdfFile();
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };
  const getJuridicSubjects = () => {
    axios
      .post(pathAPI + "/dashboard/juridic/getJuridicSubjects/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setJuridicSubjects(response.data);
        console.loog(juridicSubjects);
      })
      .catch(function (error) { });
  };
  const deletePenalizariTrimise = (id) => {
    const postData = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: id,
    };

    axios
      .post(
        pathAPI + "/dashboard/penalizari/deletePenalizariTrimise/",
        postData
      )
      .then(function (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Ștergerea a fost finalizată cu succes!");
        setSnackbarOpen(true);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getJuridicData();
    getJuridicSubjects();
  }, [datePicked]);

  const [rowModesModel, setRowModesModel] = useState({});

  const handleEditClick = (id) => () => {
    if (rowModesModel[id]?.mode === "edit") {
      return;
    }

    setRowModesModel({ ...rowModesModel, [id]: { mode: "edit" } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: "view" } });
  };

  const handleDeleteConfirm = () => {
    if (deleteItemId) {
      deletePenalizariTrimise(deleteItemId);
      setRows((prevRows) => prevRows.filter((row) => row.id !== deleteItemId));
    }
    setDeleteItemId(null);
    setDeleteDialogOpen(false);
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: "view", ignoreModifications: true },
    });

    const editedRow = rows && rows.find((row) => row.ID === id);
    if (editedRow.isNew) {
      deletePenalizariTrimise(id);
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const updateJuridicTable = (updatedRow) => {
    const postData = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      subject: updatedRow.SUBJECT,
      summary: updatedRow.SUMMARY,
      ticket_number: updatedRow.ticket_number,
      JURIDIC_STATUS: updatedRow.JURIDIC_STATUS,
      id: updatedRow.ID,
    };

    axios
      .post(pathAPI + "dashboard/juridic/updateJuridic/", postData)
      .then(function (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Actualizare reușită!");
        setSnackbarOpen(true);
        getJuridicData();
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.ID === newRow.ID ? updatedRow : row)));
    updateJuridicTable(updatedRow);
    return updatedRow;
  };
  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }
  const handleTicketClick = (params) => {
    const { row, field, colDef } = params;
    const { ID } = row;
    if (colDef.field === "JURIDIC_STATUS") {
      return;
    }
    if (field === "actions") {
      return;
    }
    if (rowModesModel[ID]?.mode === "edit") {
      return;
    }
    const isInEditMode = rowModesModel[ID]?.mode === "edit";

    if (!isInEditMode || field !== "actions") {
      setSelectedTicketJuridic(row);
    }
  };

  const getJuridicFiles = () => {
    if (selectedTicketJuridic) {
      const { ticketID } = selectedTicketJuridic;
      axios
        .post(pathAPI + "dashboard/juridic/getJuridicFiles", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          juridicId: ticketID,
        })
        .then(function (response) {
          setUploadsAttachTichet(response.data);
        })
        .catch(function (error) {
          console.error("Request failed:", error);
        });
    }
  };

  const handleFileChange = (e, field) => {
    console.log("ss");
    if (e.target.files) {
      if (selectedTicketJuridic) {
        const { ticketID } = selectedTicketJuridic;
        let params = new FormData();
        params.append(field + "-0", e.target.files[0]);
        params.append("token", localStorage.getItem("token"));
        params.append("email", localStorage.getItem("email"));
        params.append("ticketID", ticketID);
        axios
          .post(
            pathAPI + "dashboard/ticketing/uploadNotaInterna/",
            params
          )
          .then(function (response) {
            getJuridicFiles();
          })
          .catch(function (error) { });
      }
    }
  };
  const handleFormSubmit = () => {
    if (
      !formData.EMAIL_FIRMA ||
      !formData.NumarulDeInregistrare ||
      !formData.DataDeInregistrare
    ) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Va rugam completati toate campurile.");
      setSnackbarOpen(true);
      return;
    }

    axios
      .post(pathAPI + "dashboard/juridic/updateJuridicData", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        ...formData,
      })
      .then(function (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Modificarile au fost trimise cu succes!");
        setSnackbarOpen(true);
        getJuridicData();
      })
      .catch(function (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage("Form submission failed. Please try again.");
        setSnackbarOpen(true);
        console.error("Request failed:", error);
      });
  };
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormData({
      ...formData,
      ["DataDeInregistrare"]: formattedDate, // Wrap property key in quotes
    });
  };

  useEffect(() => {
    getJuridicFiles();
    getPdfFile();
  }, [selectedTicketJuridic]);

  const trimiteRaport = () => {
    setIsSendMailLoading(true);
    let isEnglish = "0";
    if (limbaDraft) {
      isEnglish = "1";
    }
    axios
      .post(pathAPI + "/dashboard/juridic/sendMail/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        ticket_id: formData.ticketID,
        juridic_id: formData.ID,
        EMAIL_FIRMA: formData.EMAIL_FIRMA,
        draftChoice: limbaDraft
          ? formData.status_name_eng
          : formData.status_name_ro,
        isEnglish: isEnglish,
        senderEmail: formData.USER_FROM_NAME,
        Numarulcomenziitransmisafurnizorului:
          formData.NumarulComenziiTransmisaFurnizorului,
      })
      .then(function (response) {
        setIsSendMailLoading(false);
        setDisableTrimiteButton(true);
        setSnackbarOpen(true);
        setSnackbarMessage("Mesajul a fost trimis cu succes !");
      })
      .catch(function (error) {
        console.log("Request failed.");
        setSnackbarMessage("Mesajul nu a fost trimis!");
      });
  };
  const colors = {
    blue: "#2196F3",
    red: "#F44336",
    yellow: "#FFEB3B",
    green: "#4CAF50",
    orange: "#FF9800",
    grey: "#9E9E9E",
    brown: "#795548",
  };
  const statuses = [
    { ID: "1", NAME: "Rezolvat" },
    { ID: "2", NAME: "Trimis" },
    { ID: "9", NAME: "Netrimis" },
  ];

  const statusOptions = {};

  const getStatusInfo = (status) => {
    switch (status) {
      case "2":
        return {
          label: "Trimis",
          icon: (
            <RadioButtonUncheckedIcon
              style={{ fill: colors.blue, fontSize: "30" }}
            />
          ),
        };
      case "1":
        return {
          label: "Rezolvat",
          icon: (
            <CheckCircleIcon style={{ fill: colors.green, fontSize: "30" }} />
          ),
        };
      case "9":
        return {
          label: "Netrimis",
          icon: <ClearIcon style={{ fill: colors.yellow, fontSize: "30" }} />,
        };

      default:
        return {
          label: "Netrimis",
          icon: <ClearIcon style={{ fill: colors.yellow, fontSize: "30" }} />,
        };
    }
  };

  const valueOptions = statuses.map((item) => {
    const { ID, NAME } = item;
    const { label, icon } = getStatusInfo(ID);
    statusOptions[ID] = {
      value: ID,
      label: NAME,
      icon,
    };
    return {
      value: ID,
      label: (
        <React.Fragment>
          {icon}
          {NAME}
        </React.Fragment>
      ),
    };
  });
  const columns = [
    {
      field: "JURIDIC_STATUS",
      headerName: "Status",
      width: 100,
      type: "singleSelect",
      valueOptions,
      value: "JURIDIC_STATUS",
      editable: true,
      renderCell: (params) => {
        const { value: status } = params;
        const { icon, label } = statusOptions[status] || {};
        return (
          <React.Fragment>
            {icon && <Tooltip title={label}>{icon}</Tooltip>}
          </React.Fragment>
        );
      },
    },
    {
      field: "ticket_number",
      headerName: "Nume Ticket",
      flex: 1,
      renderCell: renderCellExpand,
    },

    {
      field: "CREATE_DATE",
      headerName: "CREATED DATE",
      flex: 1,
      editable: false,
      renderCell: renderCellExpand,
    },
    {
      field: "SUMMARY",
      headerName: "Titlu",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },

    {
      field: "SUBJECT",
      headerName: "SUBJECT",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: juridicSubjects.map((item) => ({
        label: item.NAME,
        value: item.NAME,
      })),
      value: "ID",
      renderCell: renderCellExpand,
    },
    {
      field: "USER_FROM_NAME",
      headerName: "From Email",
      flex: 1,
      editable: true,
      renderCell: renderCellExpand,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actiuni",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === "edit";

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];
  const renderAdditionalFields = (subject) => {
    return (
      <>
        {additionalFieldsConfig[subject].map((field, index) => (
          <React.Fragment key={index}>
            {field.type === "date" ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={field.label}
                  value={formData[field.name] ?? null}
                  onChange={(newValue) =>
                    handleDateChangeDetaliiJuridic(newValue, field.name)
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            ) : (
              <TextField
                key={index}
                size="small"
                name={field.name}
                label={field.label}
                placeholder={field.placeholder}
                value={formData[field.name] ?? ""}
                onChange={handleInputChange}
                required
                onInvalid={(F) =>
                  F.target.setCustomValidity("Câmpul este obligatoriu")
                }
                onInput={(F) => F.target.setCustomValidity("")}
              />
            )}
          </React.Fragment>
        ))}
      </>
    );
  };
  return (
    <div className="box">
      <div className="boxHeader">
        <h2>Juridic</h2>
      </div>
      <div className="boxContent">
        <Box
          sx={{
            height: "full",
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            onCellClick={handleTicketClick}
            rows={rows}
            columns={columns}
            editMode="row"
            getRowId={(row) => row.ID}
            rowModesModel={rowModesModel}
            onRowEditStart={(params) => { }}
            onRowEditStop={(params) => { }}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              console.error("Error occurred while updating a row:", error);
            }}
            localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[5, 10, 25, 100]}
          />
          <DeleteDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onConfirm={handleDeleteConfirm}
            onCancel={() => setDeleteDialogOpen(false)}
          />
          <SnackbarComponent
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
          />
        </Box>
      </div>
      <Dialog
        className="ticket-dialog"
        open={selectedTicketJuridic !== null}
        onClose={() => {
          setSelectedTicketJuridic(null);
          setGenerazaClicked(false);
          setPdfFilename("");
          setDisableTrimiteButton(false);
        }}
      >
        <SnackbarComponent
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
        {selectedTicketJuridic && (
          <div
            id="authentication-modal"
            tabindex="-1"
            aria-hidden="true"
            class="modalContainerJuridic"
          >
            <div class="custom-container">
              <div class="juridicHeader ">
                <div class="juridicContent ">
                  <span class="ticketDetailsSpan">Detalii ticket juridic</span>
                  <span
                    style={{ cursor: "pointer" }}
                    class="ticketDetailsSpan"
                    onClick={() => {
                      window.open(
                        "https://ls.euroteamgb.com/ticketing/?ticket=" +
                        selectedTicketJuridic.ticket_number
                      );
                    }}
                  >
                    Ticket: {selectedTicketJuridic.ticket_number}
                  </span>

                  <button
                    type="button"
                    class="juridicButton"
                    data-modal-hide="authentication-modal"
                    onClick={() => {
                      setSelectedTicketJuridic(null);
                    }}
                  >
                    <svg
                      class="closeSvg"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span class="closeModalButton">Close modal</span>
                  </button>
                </div>

                <div className="juridicFormContainer">
                  <form
                    className="juridicFormContent"
                    action="#"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleFormSubmit();
                    }}
                  >
                    <div>
                      <span for="password" class="passwordSpan ">
                        Date Firma
                      </span>
                      <div className="textFieldsContainer">
                        <TextField
                          size="small"
                          name="NumarulDeInregistrare"
                          label="Nr. Comanda "
                          placeholder="Nr. Comanda "
                          value={formData.NumarulDeInregistrare ?? ""}
                          onChange={handleInputChange}
                          required
                          onInvalid={(F) =>
                            F.target.setCustomValidity(
                              "Câmpul  este obligatoriu"
                            )
                          }
                          onInput={(F) => F.target.setCustomValidity("")}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Data Dosar"
                            value={formData.DataDeInregistrare}
                            onChange={handleDateChange}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        </LocalizationProvider>
                        <TextField
                          size="small"
                          name="EMAIL_FIRMA"
                          label="Email Firma"
                          value={formData.EMAIL_FIRMA ?? ""}
                          onChange={handleInputChange}
                          multiline
                          required
                          onInvalid={(F) =>
                            F.target.setCustomValidity(
                              "Câmpul  este obligatoriu"
                            )
                          }
                          onInput={(F) => F.target.setCustomValidity("")}
                        />

                        {selectedTicketJuridic &&
                          additionalFieldsConfig[
                          selectedTicketJuridic?.status_name_ro
                          ] &&
                          renderAdditionalFields(
                            selectedTicketJuridic?.status_name_ro
                          )}
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      className="saveModificiatiButton"
                    >
                      Salveaza Modificarile
                    </Button>

                    <div>
                      <label for="password" class="atasamenteLabel ">
                        Atasamente
                      </label>
                      <div className="filesContainer">
                        {uploadsAttachTichet && uploadsAttachTichet.length > 0
                          ? uploadsAttachTichet.map((obj) => (
                            <Tooltip key={obj.file_id} title={obj.FILE_NAME}>
                              <div
                                className="fileContent"
                                style={{
                                  border:
                                    obj.Checked === "1"
                                      ? "1px solid rgb(0, 255, 200)"
                                      : "gray",
                                }}
                                onClick={(e) => {
                                  console.log(obj);
                                  e.stopPropagation();
                                  selectJuridicFiles(
                                    obj.juridic_id,
                                    obj.file_id,
                                    obj.Checked
                                  );
                                }}
                              >
                                <DescriptionIcon
                                  color="primary"
                                  className="descripritonIcon"
                                />
                                <Typography
                                  variant="subtitle2"
                                  className="typoSubitle "
                                >
                                  <b>{obj.FILE_NAME}</b>
                                </Typography>

                                <Tooltip title="Vizualizeaza fisierul">
                                  <button
                                    className="viewFileButton"
                                    onClick={(e) => {
                                      window.open(
                                        pathAPI +
                                        "uploads/ticketing/" +
                                        obj.FILE_NAME
                                      );
                                      e.stopPropagation();
                                    }}
                                  >
                                    <VisibilityIcon></VisibilityIcon>
                                  </button>
                                </Tooltip>
                              </div>
                            </Tooltip>
                          ))
                          : null}

                        <Tooltip title="Adauga un atasament">
                          <div
                            className="addAttachment"
                            onClick={uploadAttachTicket}
                          >
                            <input
                              ref={fileRefTicket}
                              className="file-input"
                              type="file"
                              onChange={(e) => handleFileChange(e, "Ticket")}
                            />
                            <AddIcon
                              color="primary"
                              className="addIconJuridic"
                            />
                            <Typography
                              variant="subtitle2"
                              className="typoSubitle "
                            ></Typography>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <label class="checkBoxLanguage" for="limba">
                      <input
                        type="checkbox"
                        id="limba"
                        checked={limbaDraft}
                        onChange={handleCheckboxLanguageChange}
                      />
                      Limba engleza
                    </label>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={handleGenerazaClick}
                      disabled={isGenereazaDisabled}
                      className="saveModificiatiButton"
                    >
                      GENERAZA
                    </Button>
                    {pdfFilename && (
                      <div className="generatedPdf">
                        <span
                          onClick={(e) => {
                            window.open(
                              pathAPI +
                              "dashboard/juridic/output-pdf/" +
                              pdfFilename
                            );
                            e.stopPropagation();
                          }}
                        >
                          {pdfFilename}
                        </span>
                      </div>
                    )}
                    <LoadingButton
                      loading={isSendMailLoading}
                      onClick={trimiteRaport}
                      disabled={isTrimiteDisabled}
                      variant="contained"
                      className="saveModificiatiButton"
                    >
                      Trimite
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
      <Loader loading={loader} />
    </div>
  );
}
