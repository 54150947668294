import axios from "axios";
import pathAPI from "../pathAPI";

const axiosInstance = axios.create({
  baseURL: pathAPI,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      config.data.append("token", localStorage.getItem("token"));
      config.data.append("email", localStorage.getItem("email"));
      return config;
    }

    config.data = {
      ...config.data,
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
