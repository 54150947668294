import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import Rating from "@mui/material/Rating";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TablePagination } from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Session from "./../../Components/Session";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FeedbackChart from "./charts";
import FeedbacksTeams from "./feedbacksTeams";
import axiosInstance from "../../Helpers/axiosInstance";
import pathAPI from "../../pathAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "20%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const sortOrder = {
  DESC: "DESC",
  ASC: "ASC",
};

const typeOrder = {
  DESC: "DESC",
  ASC: "ASC",
};
const Feedback = () => {
  const [feedBacksFrom, setFeedbacksFrom] = React.useState([]);
  const [feedBacksTo, setFeedbacksTo] = React.useState([]);
  const [feedbacks, setFeedbacks] = React.useState([]);
  const [fromFilter, setFromFilter] = React.useState(null);
  const [toFilter, setToFilter] = React.useState(null);
  const [teamFilter, setTeamFilter] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [page, setPage] = React.useState(1);
  const [currentSortOrder, setCurrentSortOrder] = React.useState(null);
  const [currentTypeOrder, setCurrentTypeOrder] = React.useState(null);
  const [datePicked, setDatePicked] = React.useState(null);
  const [datePickedEnd, setDatePickedEnd] = React.useState(null);
  const [typeValue, setTypeValue] = React.useState("");
  const [teams, setTeams] = React.useState("");
  const [selectedId, setSelectedId] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(0);

  const [teamMembers, setTeamMembers] = React.useState("");
  var auth = false;
  if (Session([999, 333])) {
    auth = true;
  }
  const handleSort = (event, property) => {
    if (currentSortOrder === sortOrder.ASC) {
      setCurrentSortOrder(sortOrder.DESC);
    } else {
      setCurrentSortOrder(sortOrder.ASC);
    }
  };
  const handleTypeSort = (event, property) => {
    if (currentTypeOrder === typeOrder.ASC) {
      setCurrentTypeOrder(typeOrder.DESC);
    } else {
      setCurrentTypeOrder(typeOrder.ASC);
    }
  };
  const getFeedbackFrom = () => {
    let params = new FormData();
    params.append("fb_from", "getFbFrom");
    axiosInstance
      .post(`${pathAPI}dashboard/feedback/getDistinctFeedbacks`, params)
      .then((res) => setFeedbacksFrom(res.data))
      .catch((err) => console.error(err));
  };
  const getFeedbackTo = () => {
    let params = new FormData();
    params.append("fb_from", "getFbTo");
    axiosInstance
      .post(`${pathAPI}dashboard/feedback/getDistinctFeedbacks`, params)
      .then((res) => setFeedbacksTo(res.data))
      .catch((err) => console.error(err));
  };

  const getTeams = () => {
    axios
      .post(pathAPI + "dashboard/feedback/getTeams", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        setTeams(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFeedbacks = () => {
    let params = new FormData();
    if (fromFilter) {
      params.append("fb_from", fromFilter.fb_from);
    }
    if (toFilter) {
      params.append("fb_to", toFilter.fb_to);
    }
    if (teamMembers) {
      params.append("more_fb_to", teamMembers);
    }
    if (typeValue) {
      params.append("fb_type", typeValue.sortTypes);
    }
    if (currentSortOrder) {
      params.append("sortOrder", currentSortOrder);
    }
    if (currentTypeOrder) {
      params.append("typeOrder", currentTypeOrder);
    }
    if (datePicked) {
      params.append("start_date", datePicked);
    }
    if (datePickedEnd) {
      params.append("end_date", datePickedEnd);
    }
    if (rowsPerPage) {
      params.append("per_page", rowsPerPage);
    }
    if (page) {
      params.append("page", page);
    }
    axiosInstance
      .post(`${pathAPI}dashboard/feedback/getFeedbacks`, params)
      .then((res) => {
        setFeedbacks(res.data.feedbacks);
        setTotalCount(res.data.totalPages);
      })
      .catch((err) => console.error(err));
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setSelectedId(id);

    setOpen(true);
  };

  const handleClose = () => {
    setSelectedId(null);

    setOpen(false);
  };

  React.useEffect(() => {
    getFeedbackFrom();
    getFeedbackTo();
  }, []);

  React.useEffect(() => {
    getFeedbacks();
    getTeams();
  }, [
    fromFilter,
    toFilter,
    typeValue,
    currentSortOrder,
    currentTypeOrder,
    datePicked,
    datePickedEnd,
    rowsPerPage,
    page,
    totalCount,
    teamMembers,
    teamFilter,
  ]);

  const handleDelete = (id) => {
    let params = new FormData();
    params.append("type", "delete");
    params.append("id", id);

    axiosInstance
      .post(`${pathAPI}dashboard/feedback/getFeedbacks`, params)
      .then(
        (res) => setFeedbacks(res.data.feedbacks),
        getFeedbacks(),
        handleClose
      )
      .catch((err) => console.error(err));
  };
  const handleChangeFbFrom = (event, value) => {
    setFromFilter(value);
  };

  const handleChangeFbTo = (event, value) => {
    console.log(value);
    setToFilter(value);
  };

  const handleChangeFilterTeam = (event, value) => {
    setTeamFilter(value);
    if (value) {
      getTeamMembers(value.id);
    } else {
      setTeamMembers(null);
    }
  };
  const getTeamMembers = (id) => {
    axios
      .post(pathAPI + "dashboard/feedback/getTeamMembers", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        teamId: id,
      })
      .then((res) => {
        const emails = res.data.map((member) => member.email);
        setTeamMembers(emails);
      })
      .catch((err) => console.error(err));
  };

  const handleChangeType = (event, value) => {
    console.log(value);
    setTypeValue(value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const options = [
    { label: "Monitors", sortTypes: 2 },
    { label: "Agents", sortTypes: 1 },
  ];

  return (
    <div className="">
      {auth ? (
        <>
          <div className="" style={{ height: "auto" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "2vh",
                marginTop: "2vh",
                gap: "1vw",
                justifyContent: "center",
              }}
            >
              <Autocomplete
                id="size-small-standard"
                size="small"
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.fb_from
                }
                options={feedBacksFrom}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChangeFbFrom}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Feedback from"
                    color="primary"
                  />
                )}
              />
              <Autocomplete
                id="size-small-standard"
                size="small"
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.fb_to
                }
                options={feedBacksTo}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChangeFbTo}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Feedback to" color="primary" />
                )}
              />
              <Autocomplete
                id="size-small-standard"
                size="small"
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                options={teams}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChangeFilterTeam}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select team" color="primary" />
                )}
              />
              <Autocomplete
                id="size-small-standard"
                size="small"
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChangeType}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Type" />
                )}
              />
              <Button
                size="small"
                variant="contained"
                style={{
                  backgroundColor: "#0075ba",
                  color: "white",
                  width: "3vw",
                }}
                onClick={() => {
                  setFromFilter(null);
                  setToFilter(null);
                  setDatePicked(null);
                  setDatePickedEnd(null);
                  setTeamMembers("");
                  setTeamFilter("");
                }}
              >
                Clear
              </Button>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date From"
                  views={["year", "month", "day"]}
                  value={datePicked}
                  onChange={(newValue) => {
                    setDatePicked(newValue);
                    setPage(1);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date To"
                  views={["year", "month", "day"]}
                  value={datePickedEnd}
                  onChange={(newValue) => {
                    setDatePickedEnd(newValue);
                    setPage(1);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>

            <TableContainer component={Paper}>
              <Table aria-label="simple table" stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ backgroundColor: "#0075ba", color: "white" }}
                    >
                      #
                    </TableCell>
                    <StyledTableCell
                      style={{ backgroundColor: "#0075ba", color: "white" }}
                    >
                      Feedbacks From
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: "#0075ba", color: "white" }}
                    >
                      Feedbacks To
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: "#0075ba", color: "white" }}
                      align="center"
                    >
                      Comment
                    </StyledTableCell>

                    <StyledTableCell
                      style={{
                        backgroundColor: "#0075ba",
                        color: "white",
                      }}
                    >
                      Type
                    </StyledTableCell>

                    <TableCell
                      style={{ backgroundColor: "#0075ba", color: "white" }}
                    >
                      <TableSortLabel
                        active={true}
                        style={{ color: "white" }}
                        direction={
                          currentSortOrder === sortOrder.ASC ? "asc" : "desc"
                        }
                        onClick={(event) => handleSort(event, "rating")}
                      >
                        Rating
                      </TableSortLabel>
                    </TableCell>

                    <StyledTableCell
                      style={{ backgroundColor: "#0075ba", color: "white" }}
                      align="center"
                    >
                      Date
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ backgroundColor: "#0075ba", color: "white" }}
                      align="center"
                    >
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedbacks.map((feedback, index) => (
                    <StyledTableRow key={feedback.id}>
                      <StyledTableCell style={{ width: "5%" }}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{feedback.fb_from}</StyledTableCell>
                      <StyledTableCell>{feedback.fb_to}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip title={feedback.comment}>
                          <Button
                            sx={{
                              m: 1,
                              textTransform: "none",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            {feedback.comment && feedback.comment.length > 15
                              ? feedback.comment.substring(0, 15) + "..."
                              : feedback.comment}
                          </Button>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "auto" }}>
                        {feedback.fb_type === "1"
                          ? "Agents"
                          : feedback.fb_type === "2"
                            ? "Monitors"
                            : ""}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "5%" }}>
                        <Rating
                          name="disabled"
                          value={Number(feedback.stars)}
                          readOnly
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(feedback.date)
                          .toLocaleDateString("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(/(\w+) (\d+), (\d+)/, "$2 $1, $3")
                          .toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <DeleteForeverIcon
                          onClick={() => handleClickOpen(feedback.id)}
                          style={{
                            color: "red",
                            fontSize: 30,
                            cursor: "pointer",
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={parseInt(totalCount)}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>

            <Dialog open={open} onClick={handleClose} maxWidth="sm" fullWidth>
              <Alert severity="warning">
                <AlertTitle>Atentie</AlertTitle>
                Confirma stergerea feedback-ului`.
              </Alert>
              <Box position="absolute" top={0} right={0}>
                <IconButton>
                  <CloseIcon />
                </IconButton>
              </Box>

              <DialogActions sx={{ backgroundColor: "rgb(255, 244, 229)" }}>
                <Button
                  color="primary"
                  onClick={handleClose}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  color="warning"
                  onClick={() => handleDelete(selectedId)}
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <div style={{ width: "500px" }}>
              {toFilter !== null ? (
                <FeedbackChart feedbacks1={toFilter}></FeedbackChart>
              ) : (
                <p style={{ color: "#0075ba", fontWeight: 700 }}>
                  Select Feedback To to load the graph for it !
                </p>
              )}
            </div>
            <div></div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Feedback;
