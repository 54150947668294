// reducers/commentReducer.js
const initialState = {
  comment: "", // Initial comment value
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COMMENT":
      return {
        ...state,
        comment: action.payload,
      };
    default:
      return state;
  }
};

export default commentReducer;
