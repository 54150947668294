import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button, TableFooter, Divider } from "@mui/material";
import pathAPI from "../../pathAPI";
import "./../Ticketing/Ticketing.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Loader from "../../Helpers/Loader";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { Autocomplete, Alert } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import Slide from "@mui/material/Fade";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const OpenTicket = (props) => {
  const [gpsDriver, setGpsDriver] = useState([]);
  const [visibilityFor, setVisibilityFor] = useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const [gpsVehicles, setGpsVehicles] = useState([]);
  const [gpsTrailers, setGpsTrailers] = useState([]);
  const switchLabel = { inputProps: { "aria-label": "Color switch demo" } };
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [loader, setLoader] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [selectedSwitcher, setSelectedSwitcher] = useState(null);
  const [selectedVin, setSelectedVin] = useState(null);
  const [formValidity, setFormValidity] = useState({
    driversInsert: true,
    driversPrenumeInsert: true,
    driversTelnumInsert: true,
    driversCNPInsert: true,
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const handleWarningDialogClose = () => {
    setWarningDialogOpen(false);
  };

  const initialFormData = {
    driversInsert: null,
    driversPrenumeInsert: null,
    driversTelnumInsert: null,
    driversCNPInsert: null,

    trailersVin: null,
    trailersCarPlate: null,
    VEHICLESVinUpdate: null,
  };
  const handleOptionSelect = async (event, value, vehicleID) => {
    const prevSelectedOptions = getVisibilityForOptions(vehicleID).map(
      (option) => option.name
    );
    const names = value.map((option) => option.name);

    setSelectedOptions(names); // Store the array of selected options directly

    const optionsAdded = names.filter(
      (name) => !prevSelectedOptions.includes(name)
    );
    const optionsRemoved = prevSelectedOptions.filter(
      (name) => !names.includes(name)
    );

    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");

      if (optionsAdded.length > 0) {
        const payload = {
          token,
          email,
          name: optionsAdded.join(","), // Convert to a comma-separated string
          vehicleId: vehicleID,
        };

        const response = await axios.post(
          pathAPI + "/dashboard/gps/insertVisibility/",
          payload
        );
        if (response.data) {
          getControlDetails();

          setMessageError(null);
          getVisibilityFor();
        } else {
          setMessageError(response.data.message);
        }
      } else if (optionsRemoved.length > 0) {
        const defaultValue = getVisibilityForOptions(vehicleID)
          .filter((option) => optionsRemoved.includes(option.name)) // Filter out options that were removed
          .map((option) => option.id);

        const defaultValueString = defaultValue.join(","); // Convert to a comma-separated string

        const payload = {
          token,
          email,
          id: defaultValueString,
          vehicleId: vehicleID,
          dateStop: null,
        };
        const response = await axios.post(
          pathAPI + "/dashboard/gps/updateVisibility/",
          payload
        );
        if (response.data) {
          getControlDetails();
          setMessageError(null);
          getVisibilityFor();
        } else {
          setMessageError(response.data.message);
        }
      }
    } catch (error) {
      setLoader(false);
      console.log("Request failed.");
    }
  };
  const [formData, setFormData] = useState(initialFormData);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormValidity((prevValidity) => ({
      ...prevValidity,
      [name]: Boolean(value),
    }));
  };

  const dialogContent = (id, value1, value2, tableName, telnum, driverCard) => {
    if (id && value1 && value2 && tableName === "drivers") {
      setSelectedOption({
        id: id,
        name: value1,
        tableName: tableName,
        surname: value2,
        telnum: telnum,
        driverCard: driverCard,
      });
    } else if (tableName === "trailers") {
      setSelectedOption({
        id: id,
        vin: value1,
        tableName: tableName,
        carPlate: value2,
      });
    } else if (id && value2 === "VEHICLES") {
      setSelectedOption({
        id: id,
        vin: value1,
        tableName: value2,
      });
    } else {
      setSelectedOption(null);
    }

    setFormData(initialFormData);
  };

  const insertNewValue = (tableName) => {
    if (formData[tableName + "Insert"] || formData[tableName + "Vin"]) {
      setLoader(true);
      const payload = {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableName: tableName.toUpperCase(),
      };
      if (formData[tableName + "Insert"]) {
        let isFormValid = true;
        if (!formData.driversInsert) {
          setFormValidity((prevValidity) => ({
            ...prevValidity,
            driversInsert: false,
          }));
          isFormValid = false;
        }
        if (!formData.driversPrenumeInsert) {
          setFormValidity((prevValidity) => ({
            ...prevValidity,
            driversPrenumeInsert: false,
          }));
          isFormValid = false;
        }
        if (!formData.driversTelnumInsert) {
          setFormValidity((prevValidity) => ({
            ...prevValidity,
            driversTelnumInsert: false,
          }));
          isFormValid = false;
        }
        if (!formData.driversCNPInsert) {
          setFormValidity((prevValidity) => ({
            ...prevValidity,
            driversCNPInsert: false,
          }));
          isFormValid = false;
        }

        if (!isFormValid) {
          setLoader(false);
          return;
        }
        payload.name = formData[tableName + "Insert"];
        payload.surname = formData[tableName + "PrenumeInsert"];
        payload.telnum = formData[tableName + "TelnumInsert"];
        payload.driverCard = formData[tableName + "CNPInsert"];
      } else if (formData[tableName + "Vin"]) {
        payload.vin = formData[tableName + "Vin"];
        payload.carPlate = formData[tableName + "CarPlate"];
      }
      axios
        .post(pathAPI + "/dashboard/gps/basicInsert/", payload)
        .then(function (response) {
          if (response.data.success) {
            getControlDetails();
            setFormData({
              ...formData,
              driversInsert: null,
              driversPrenumeInsert: null,
              driversCNPInsert: null,
              driversTelnumInsert: null,
              trailersVin: null,
              trailerCarPlate: null,
            });
            setMessageError(null);
          } else {
            setMessageError(response.data.message);
          }
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          console.log("Request failed.");
        });
    }
  };

  const updateValue = (tableName) => {
    console.log(formData, formData[tableName + "telnum"]);
    setMessageError(null);
    const payload = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      tableName: tableName.toUpperCase(),
      valueId: selectedOption.id,
    };
    if (
      formData[tableName + "Update"] ||
      formData[tableName + "PrenumeUpdate"] ||
      formData[tableName + "telnum"] ||
      formData[tableName + "driverCard"]
    ) {
      console.log("salut");
      payload.nume = formData[tableName + "Update"] || selectedOption.name;
      payload.surname =
        formData[tableName + "PrenumeUpdate"] || selectedOption.surname;
      payload.telnum = formData[tableName + "telnum"] || selectedOption.telnum;
      payload.driverCard =
        formData[tableName + "driverCard"] || selectedOption.driverCard;
    } else if (
      formData[tableName + "VinUpdate"] ||
      formData[tableName + "CarPlateUpdate"]
    ) {
      payload.vin = formData[tableName + "VinUpdate"] || selectedOption.vin;
      payload.carPlate =
        formData[tableName + "CarPlateUpdate"] || selectedOption.carPlate;
    }
    if (
      formData[tableName + "VinUpdate"] ||
      formData[tableName + "CarPlateUpdate"] ||
      formData[tableName + "PrenumeUpdate"] ||
      formData[tableName + "Update"] ||
      formData[tableName + "VinUpdate"] ||
      formData[tableName + "telnum"] ||
      formData[tableName + "driverCard"]
    ) {
      setLoader(true);

      axios
        .post(pathAPI + "/dashboard/gps/basicUpdate/", payload)
        .then(function (response) {
          getControlDetails();
          setLoader(false);

          if (response.data.success) {
            setSelectedOption(null);
            setMessageError(null);
          } else {
            setMessageError(response.data.message);
          }
        })
        .catch(function (error) {
          setLoader(false);
          if (error.response && error.response.data) {
          } else {
            console.log("Request failed.");
          }
        });
    }
  };

  const updateVehicle = (newValue, id, fieldName) => {
    setLoader(true);
    setMessageError(null);
    const payload = {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: id,
      // newDriverId: newDriverId,
    };
    if (fieldName === "trailer") {
      payload.fieldName = fieldName;
      payload.trailerId = newValue;
    } else if (fieldName === "switcher") {
      payload.fieldName = fieldName;
      payload.visibility = newValue;
    } else {
      payload.fieldName = fieldName;
      payload.newDriverId = newValue;
    }
    console.log(payload);
    axios
      .post(pathAPI + "/dashboard/gps/assignDriver/", payload)
      .then(function (response) {
        getControlDetails();
        setLoader(false);

        if (response.data.success) {
          setAlertMessage(response.data.message);
          setAlertVisible(true);
          setTimeout(() => {
            setAlertVisible(false);
          }, 1000);
          setSelectedOption(null);
          setMessageError(null);
          setSelectedSwitcher(null);
          setSelectedVin(null);
        } else {
          console.log(response.data.message);
          setMessageError(response.data.message);
          // show warning dialog
          setWarningDialogOpen(true);
        }
      })
      .catch(function (error) {
        setLoader(false);
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          setMessageError(error.response.data.message);
          // show warning dialog
          setWarningDialogOpen(true);
        } else {
          console.log("Request failed.");
        }
      });
  };

  const deleteValue = () => {
    setSelectedOption(null);
    setConfirmDialog(null);
    setLoader(true);
    axios
      .post(pathAPI + "/dashboard/gps/basicDelete/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableName: selectedOption.tableName.toUpperCase(),
        valueId: selectedOption.id,
      })
      .then(function (response) {
        getControlDetails();
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Request failed.");
      });
  };

  const getControlDetails = () => {
    setLoader(true);
    axios
      .post(pathAPI + "/dashboard/gps/getAllFromGPS/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "DRIVERS,TRAILERS,VEHICLES",
      })

      .then(function (response) {
        setLoader(false);
        setGpsDriver(response.data.DRIVERS);
        setGpsVehicles(response.data.VEHICLES);
        setGpsTrailers(response.data.TRAILERS);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Request failed.");
      });
  };
  const handleAutocompleteChange = (event, newValue, name) => {
    setFormData({
      ...formData,
      [name]: newValue,
    });
    console.log("form", formData.userReported.id);
  };
  const getVisibilityFor = () => {
    axios
      .post(pathAPI + "dashboard/gps/getGpsVisibilityName", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        setVisibilityFor(res.data);
        console.log(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getVisibilityFor();
    getControlDetails();
  }, []);
  const getVisibilityForOptions = (gpsVehicleId) => {
    if (visibilityFor.length > 0 && gpsVehicleId) {
      return visibilityFor.filter(
        (option) => option.veh_id === gpsVehicleId && option.dateStop === null
      );
    }
    return [];
  };
  const getUniqueOptions = (options) => {
    const uniqueNamesSet = new Set();
    const uniqueOptions = [];
    options.forEach((option) => {
      if (!uniqueNamesSet.has(option.name)) {
        uniqueNamesSet.add(option.name);
        uniqueOptions.push(option);
      }
    });
    return uniqueOptions;
  };
  const uniqueOptions = getUniqueOptions(visibilityFor);

  return (
    <>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={1}
      >
        {props.activeTab === "1" ? (
          <Grid item xs={4}>
            <TableContainer component={Paper}>
              <Table className="optionControlTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Nume</TableCell>
                    <TableCell>Prenume</TableCell>
                    <TableCell>Telefon</TableCell>
                    <TableCell>CNP</TableCell>

                    <TableCell>Added Date</TableCell>
                    <TableCell>Editează</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gpsDriver !== null &&
                    gpsDriver.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.surname}</TableCell>
                        <TableCell>{row.telnum}</TableCell>
                        <TableCell>{row.driverCard}</TableCell>

                        <TableCell>{row.addDate}</TableCell>

                        <TableCell>
                          <IconButton
                            onClick={() => {
                              dialogContent(
                                row.id,
                                row.name,
                                row.surname,
                                "drivers",
                                row.telnum,
                                row.driverCard
                              );
                              setMessageError(null);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <TextField
                        size="small"
                        name="driversInsert"
                        label="Nume"
                        value={formData.driversInsert ?? ""}
                        onChange={handleInputChange}
                        error={!formValidity.driversInsert}
                        helperText={
                          !formValidity.driversInsert &&
                          "Acest câmp este obligatoriu"
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ marginLeft: "1rem" }}
                        size="small"
                        name="driversPrenumeInsert"
                        label="Prenume"
                        value={formData.driversPrenumeInsert ?? ""}
                        onChange={handleInputChange}
                        error={!formValidity.driversPrenumeInsert}
                        InputProps={{
                          endAdornment: !formValidity.driversPrenumeInsert && (
                            <ErrorOutlineIcon color="error" />
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ marginLeft: "1rem" }}
                        size="small"
                        name="driversTelnumInsert"
                        label="Telefon"
                        value={formData.driversTelnumInsert ?? ""}
                        onChange={handleInputChange}
                        error={!formValidity.driversTelnumInsert}
                        InputProps={{
                          endAdornment: !formValidity.driversTelnumInsert && (
                            <ErrorOutlineIcon color="error" />
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={{ marginLeft: "1rem" }}
                        size="small"
                        name="driversCNPInsert"
                        label="CNP"
                        value={formData.driversCNPInsert ?? ""}
                        onChange={handleInputChange}
                        error={!formValidity.driversCNPInsert}
                        InputProps={{
                          endAdornment: !formValidity.driversCNPInsert && (
                            <ErrorOutlineIcon color="error" />
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Button
                        onClick={() => insertNewValue("drivers")}
                        variant="contained"
                      >
                        Adaugă
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
              <p style={{ color: "red", padding: "1rem" }}>{messageError}</p>
            </TableContainer>

            <h2 style={{ marginTop: "5px" }}>GPS Trailers</h2>

            <TableContainer component={Paper}>
              <Table className="optionControlTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Vin</TableCell>
                    <TableCell>Car Plate</TableCell>
                    <TableCell>Added Date</TableCell>
                    <TableCell>Editează</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gpsTrailers !== null &&
                    gpsTrailers.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.vin}</TableCell>
                        <TableCell>{row.carPlate}</TableCell>
                        <TableCell>{row.addDate}</TableCell>

                        <TableCell>
                          <IconButton
                            onClick={() => {
                              dialogContent(
                                row.id,
                                row.vin,
                                row.carPlate,
                                "trailers"
                              );
                              setMessageError(null);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <Box display="flex">
                        <TextField
                          size="small"
                          name="trailersVin"
                          label="VIN"
                          value={formData.trailersVin ?? ""}
                          onChange={handleInputChange}
                        />
                        <TextField
                          sx={{ marginLeft: "1rem" }}
                          size="small"
                          name="trailersCarPlate"
                          label="Car Plate"
                          value={formData.trailersCarPlate ?? ""}
                          onChange={handleInputChange}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => insertNewValue("trailers")}
                        variant="contained"
                      >
                        Adaugă
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
              <p style={{ color: "red", padding: "1rem" }}>{messageError}</p>
            </TableContainer>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <TableContainer component={Paper}>
              <Table className="optionControlTable">
                <TableHead>
                  <TableRow>
                    <TableCell>Car Plate</TableCell>
                    <TableCell>VIN</TableCell>
                    <TableCell>Driver ID</TableCell>
                    <TableCell>Trailer ID</TableCell>
                    <TableCell>Last Update</TableCell>
                    <TableCell>Fuel in Liter</TableCell>
                    <TableCell>GPS Connected</TableCell>
                    <TableCell>Ignition</TableCell>
                    <TableCell>Online</TableCell>
                    <TableCell>Visibility For</TableCell>

                    <TableCell>Visibility</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gpsVehicles !== null &&
                    gpsVehicles.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.carPlate}</TableCell>
                        <TableCell
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            dialogContent(row.id, row.vin, "VEHICLES");
                            setMessageError(null);
                          }}
                        >
                          {row.vin}
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            size="small"
                            options={gpsDriver}
                            getOptionLabel={(option) =>
                              `${option.name} ${option.surname}`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Driver Name"
                                variant="outlined"
                              />
                            )}
                            value={gpsDriver.find(
                              (driver) => driver.id === row.DriverId
                            )}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                const driverId = newValue.id;
                                const oldDriverId = row.id;
                                updateVehicle(driverId, oldDriverId, "driver");
                              } else {
                                updateVehicle(null, row.id, "driver");
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            size="small"
                            options={gpsTrailers}
                            getOptionLabel={(option) => option.carPlate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Trailer Car Plate"
                                variant="outlined"
                              />
                            )}
                            value={
                              gpsTrailers.find(
                                (trailer) => trailer.id === row.TrailerId
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              const trailerId = newValue?.id || null;
                              const id = row.id;
                              console.log(trailerId, id);
                              updateVehicle(trailerId, id, "trailer");
                            }}
                          />
                        </TableCell>
                        <TableCell>{row.lastUpdate}</TableCell>
                        <TableCell>{row.Fuel1InLiter}</TableCell>
                        <TableCell>
                          {row.IsGpsConnected === "1" ? (
                            <div className="greenCircle">●</div>
                          ) : (
                            <div className="redCircle">●</div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.IsIgnitionOn === "1" ? (
                            <div className="greenCircle">●</div>
                          ) : (
                            <div className="redCircle">●</div>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.IsOnline === "1" ? (
                            <div className="greenCircle">●</div>
                          ) : (
                            <div className="redCircle">●</div>
                          )}
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            multiple
                            size="small"
                            options={uniqueOptions}
                            getOptionLabel={(option) => `${option.name}`}
                            defaultValue={getVisibilityForOptions(row.id)}
                            onChange={(event, newValue) =>
                              handleOptionSelect(event, newValue, row.id)
                            }
                            filterOptions={(options, { inputValue }) => {
                              const filteredOptions = options.filter(
                                (option) =>
                                  !getVisibilityForOptions(row.id).some(
                                    (defaultValueOption) =>
                                      defaultValueOption.name === option.name
                                  )
                              );
                              return filteredOptions;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Visibility For"
                                variant="outlined"
                              />
                            )}
                            disableClearable={true}
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={row.visibility === "1"}
                            {...switchLabel}
                            onChange={(event) => {
                              const visibility = event.target.checked
                                ? "1"
                                : "0";
                              setSelectedSwitcher({
                                id: row.id,
                                visibility: visibility,
                                carPlate: row.carPlate,
                              });
                              setMessageError(null);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <p style={{ color: "red", padding: "1rem" }}>{messageError}</p>
            </TableContainer>
            <div>
              <Dialog
                open={warningDialogOpen}
                onClose={handleWarningDialogClose}
                PaperProps={{
                  style: {
                    backgroundColor: "#FFD54F",
                    borderRadius: "8px",
                    padding: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative",
                  },
                }}
              >
                <Alert
                  variant="filled"
                  severity="warning"
                  style={{
                    backgroundColor: "#FFD54F",
                    color: "#000000",
                    flex: 1,
                  }}
                >
                  {messageError}
                </Alert>
                <IconButton
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    color: "#000000",
                  }}
                  onClick={handleWarningDialogClose}
                >
                  <CloseIcon />
                </IconButton>
              </Dialog>
            </div>
          </Grid>
        )}
      </Grid>
      <Dialog
        className="ticket-dialog"
        open={selectedOption !== null}
        onClose={() => {
          dialogContent();
          setMessageError(null);
        }}
      >
        {selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">
                {selectedOption.tableName === "drivers"
                  ? selectedOption.name
                  : selectedOption.vin}
              </p>
            </div>
            <div className="ticket-details">
              <TextField
                size="small"
                name={
                  selectedOption.tableName === "drivers"
                    ? selectedOption.tableName + "Update"
                    : selectedOption.tableName + "VinUpdate"
                }
                className="input-dialog-control"
                label={selectedOption.tableName === "drivers" ? "Nume" : "VIN"}
                value={
                  selectedOption.tableName === "drivers"
                    ? formData[selectedOption.tableName + "Update"] ??
                    selectedOption.name
                    : formData[selectedOption.tableName + "VinUpdate"] ??
                    selectedOption.vin
                }
                onChange={handleInputChange}
              />
            </div>
            {selectedOption.tableName !== "VEHICLES" ? (
              <div className="ticket-details" style={{ marginTop: "2rem" }}>
                <TextField
                  size="small"
                  name={
                    selectedOption.tableName === "drivers"
                      ? selectedOption.tableName + "PrenumeUpdate"
                      : selectedOption.tableName + "CarPlateUpdate"
                  }
                  className="input-dialog-control"
                  label={
                    selectedOption.tableName === "drivers"
                      ? "surname"
                      : "Car Plate"
                  }
                  value={
                    selectedOption.tableName === "drivers"
                      ? formData[selectedOption.tableName + "PrenumeUpdate"] ??
                      selectedOption.surname
                      : formData[selectedOption.tableName + "CarPlateUpdate"] ??
                      selectedOption.carPlate
                  }
                  onChange={handleInputChange}
                />
                <p style={{ color: "red" }}>{messageError}</p>
              </div>
            ) : (
              ""
            )}
            {selectedOption.tableName === "drivers" ? (
              <div className="ticket-details" style={{ marginTop: "2rem" }}>
                <TextField
                  size="small"
                  name={selectedOption.tableName + "telnum"}
                  className="input-dialog-control"
                  label={"Telefon"}
                  value={
                    formData[selectedOption.tableName + "telnum"] ??
                    selectedOption.telnum
                  }
                  onChange={handleInputChange}
                />
                <TextField
                  sx={{ marginTop: "2rem" }}
                  size="small"
                  name={selectedOption.tableName + "driverCard"}
                  className="input-dialog-control"
                  label={"CNP"}
                  value={
                    formData[selectedOption.tableName + "driverCard"] ??
                    selectedOption.driverCard
                  }
                  onChange={handleInputChange}
                />
                <p style={{ color: "red" }}>{messageError}</p>
              </div>
            ) : (
              ""
            )}
            <div className="ticket-dialog-control">
              <Button
                onClick={() => updateValue(selectedOption.tableName)}
                variant="contained"
              >
                Salvează
              </Button>
              {selectedOption.tableName !== "VEHICLES" ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setConfirmDialog(selectedOption.name)}
                >
                  Șterge
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="outlined"
                onClick={() => setSelectedOption(null, setMessageError(null))}
              >
                Anulează
              </Button>
            </div>
            <p style={{ color: "red", padding: "1rem" }}>{messageError}</p>

            <Divider />
          </div>
        )}
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={confirmDialog !== null}
        onClose={() => setConfirmDialog(null)}
      >
        {confirmDialog !== null && selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi valoarea: {confirmDialog}</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteValue()}
              >
                Șterge
              </Button>
              <Button variant="outlined" onClick={() => setConfirmDialog(null)}>
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>

      <Dialog
        className="switcher-dialog"
        open={selectedSwitcher || selectedVin !== null}
        onClose={() => {
          setSelectedSwitcher(null);
          setSelectedVin(null);
        }}
      >
        {selectedSwitcher ? (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Actualizeaza vizibilitate</p>
            </div>
            <div className="ticket-details">
              <p>
                Esti sigur ca doresti sa
                {selectedSwitcher.visibility === "1" ? (
                  <span style={{ color: "green", fontWeight: "700" }}>
                    {" "}
                    activezi{" "}
                  </span>
                ) : (
                  <span style={{ color: "red", fontWeight: "700" }}>
                    {" "}
                    dezactivezi{" "}
                  </span>
                )}
                vizibilitatea pentru {selectedSwitcher.carPlate}
              </p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(102, 187, 106)" }}
                onClick={() =>
                  updateVehicle(
                    selectedSwitcher.visibility,
                    selectedSwitcher.id,
                    "switcher"
                  )
                }
              >
                Da
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSelectedSwitcher(null)}
              >
                Anulează
              </Button>
            </div>
          </div>
        ) : (
          "selected vin"
        )}
      </Dialog>

      <Dialog
        className="switcher-dialog"
        open={alertVisible}
        onClose={() => setSelectedSwitcher(null)}
        PaperProps={{
          style: {
            position: "absolute",
            top: 20,
          },
        }}
        TransitionComponent={Slide}
        transitionDuration={1000}
        keepMounted
      >
        <Alert severity="success">{alertMessage}</Alert>
      </Dialog>

      <Loader loading={loader} />
    </>
  );
};

export default OpenTicket;
