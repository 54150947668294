import React, { useState, useEffect } from "react";
import axios from "axios";
import pathAPI from "../../pathAPI";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
  const [token, setToken] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isntLoggedIn, setIsntLoggedIn] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    const checkData = {
      email: localStorage.getItem("email"),
      token: localStorage.getItem("token"),
    };
    if (checkData.email && checkData.token) {
      setIsntLoggedIn(false);
    }

    if (isntLoggedIn) {
      if (window.location.search) {
        localStorage.removeItem("email");
        localStorage.clear();
        // window.location.reload(true);
      } else {
        navigate(`/`);
      }
    }
  }, [isntLoggedIn]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get("token");

    if (tokenFromURL) {
      setToken(tokenFromURL);

      axios
        .post(pathAPI + "dashboard/users/verify", { token: tokenFromURL })
        .then((response) => {
          if (response.data.status === 1) {
            setIsTokenValid(true);
          } else {
            setIsTokenValid(false);
            console.error(response.data.message);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        })
        .catch((error) => {
          setIsTokenValid(false);
          console.error(error);
        });
    }
  }, []);

  const handlePasswordReset = () => {
    axios
      .post(pathAPI + "dashboard/users/resetPassword", { token, password })
      .then((response) => {
        navigate(`/login`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (isTokenValid === null) {
    return <p>Verifying...</p>;
  }

  return (
    <>
      <div className="loginPage">
        <div className="box">
          <div class="boxHeader">
            <h2>Reset Password</h2>
          </div>
          <div class="boxContent">
            <Box noValidate>
              {isTokenValid ? (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    autoFocus
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Confirm new Password"
                    type="password"
                    id="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <Button
                    onClick={handlePasswordReset}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    value="Login"
                  >
                    Reset Password
                  </Button>
                </>
              ) : (
                <>
                  <p>
                    Link-ul de resetare este expirat, contacteaza echipa IT
                    pentru un alt link de resetare a parolei !{" "}
                  </p>
                </>
              )}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
