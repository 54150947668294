import AdaugaCurs from "./AdaugaCurs/AdaugaCurs";
import ControlCurs from "./ControlCurs/ControlCurs";

export default function ControlOptiuni() {

    return (
        <div className="gridIsh">

            <AdaugaCurs />
            <ControlCurs />
        </div>
    );
}
