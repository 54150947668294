import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Ticketing.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import {
  TextField,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { Autocomplete, TablePagination } from "@mui/material";
import pathAPI from "../../pathAPI";
import Filters from "./Filters.jsx";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "./../../Helpers/Loader";
import GlobalAlert from "../../Helpers/Alert";
import ticketingSound from "./ticketing-notification.mp3";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import Switch from "@mui/material/Switch";
import TuneIcon from "@mui/icons-material/Tune";
import CustomizedHook from "./autocompleteCustom";
import TicketDetailsModal from "./TicketDetailsComponent/TicketDetailsModal.jsx";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBozJdokIJiE0IV8dHfh8kzptI8Ll3gw-s",
  authDomain: "leadership-fc025.firebaseapp.com",
  projectId: "leadership-fc025",
  storageBucket: "leadership-fc025.appspot.com",
  messagingSenderId: "216875783056",
  appId: "1:216875783056:web:a2ea20cb992450219b9915",
  measurementId: "G-53G9HZYFGB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function writeUserData(ticketNumber) {
  const min = 1;
  const max = 999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const db = getDatabase();
  set(ref(db, "ticketing/" + ticketNumber), {
    lastUpdate: randomNumber,
  });
}

const Tickets = ({
  selectedTicketNumber,
  setSelectedTicketNumber,
  setValue,
}) => {
  const audioRef = useRef(null);

  const playSound = () => {
    const audio = audioRef.current;
    audio.play();
  };
  const [isCCFilterVisible, setCCFilterVisible] = useState(false);
  const [isResponsabilFilterVisible, setIsResponsabilFilterVisible] =
    useState(false);
  const [isDeschisDeFilterVisible, setIsDeschisDeFilterVisible] =
    useState(false);

  const [actualStatus, setActualStatus] = useState(null);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [commentForChat, setCommentForChat] = useState("");

  const [ticketsList, setTicketsList] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [ticketComments, setTicketComments] = useState(null);
  const [status, setStatus] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [ticketCCs, setTicketCCs] = useState(null);
  const [selectedTicketCCs, setSelectedTicketCCs] = useState(null);
  const [note, setNote] = useState(null);
  const [statusTicket, setStatusTicket] = useState(null);
  const [initialStatusTicket, setInitialStatusTicket] = useState(null);
  const [statusRights, setStatusRights] = useState(true);
  const [commEditableMode, setCommEditableMode] = useState(false);
  const titleEditRef = useRef(null);
  const [titleEdit, setTitleEdit] = useState(null);
  const [titleEditAux, setTitleEditAux] = useState(null);
  const [subjectEditAux, setSubjectEditAux] = useState(null);
  const [subjectEdit, setSubjectEdit] = useState(null);
  const [subjectsId, setSubjectId] = useState(null);
  const [distinctSubjects, setDistinctSubjects] = useState(null);
  const [isDeleteAttachamentOpen, setIsDeleteAttachamentOpen] =
    React.useState(false);

  const subjectEditRef = useRef(null);

  const [uploadsNote, setUploadsNote] = useState(null);
  const [uploadsAttachTichet, setUploadsAttachTichet] = useState(null);
  const fileRef = useRef();
  const fileRefTicket = useRef();
  const [loader, setLoader] = useState(false);
  const [shouldResetQuill, setShouldResetQuill] = useState(false);

  const [alertUploadNoteNok, setAlertUploadNoteNok] = useState(false);
  const [alertNoChange, setAlertNoChange] = useState(false);
  const [alertSucces, setAlertSucces] = useState(false);
  const [subjectEditez, setSubjectEdited] = useState(false);
  const [fileToDeleteFromAttach, setFileToDeleteFromAttach] = useState("");
  const [eventForDeleteFromAttach, setEventForDeleteFromAttach] = useState("");
  //-----------filters---------------//
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedTitlu, setSelectedTitlu] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedEmitator, setSelectedEmitator] = useState(null);
  const [selectedReceptor, setSelectedReceptor] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRaportat, setSelectedRaportat] = useState(null);
  const [selectedCC, setSelectedCC] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const [subjects, setSubjects] = useState([]);
  const comment = useSelector((state) => state.comment.comment);

  //----------data filters-----------//
  const [ticketsFilter, setTicketsFilter] = useState([]);
  const [titluFilter, setTitluFilter] = useState([]);
  const [subiectFilter, setSubiectFilter] = useState([]);
  const [emitatorFilter, setEmitatorFilter] = useState([]);
  const [receptorFilter, setReceptorFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [raportatFilter, setRaportatFilter] = useState([]);
  const [ccFilter, setCcFilter] = useState([]);
  const [resetEditor, setResetEditor] = useState(false);

  const [personFilter, setPersonFilter] = useState([]);
  const initialFormData = {
    comment: null,
    status: null,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [checkIsNotMobile, setCheckIsNotMobile] = useState(false);

  const statusIcons = {
    SCAZUTA: (
      <Tooltip title="SCAZUTA" arrow>
        <ThumbUpAltOutlinedIcon
          style={{ fill: "rgb(0, 255, 50)", fontSize: "30px" }}
        />
      </Tooltip>
    ),
    NORMALA: (
      <Tooltip title="NORMALA" arrow>
        <ThumbUpAltOutlinedIcon
          style={{ fill: "rgb(0 255 50)", fontSize: "30px" }}
        />
      </Tooltip>
    ),
    RIDICATA: (
      <Tooltip title="RIDICATA" arrow>
        <WarningOutlinedIcon style={{ fill: "orange", fontSize: "30px" }} />
      </Tooltip>
    ),
    URGENTA: (
      <Tooltip title="URGENTA" arrow>
        <PriorityHighOutlinedIcon style={{ fill: "red", fontSize: "30px" }} />
      </Tooltip>
    ),
  };
  useEffect(() => {
    const storedCCFilterVisibility = JSON.parse(
      localStorage.getItem("ccFilter")
    );
    const storedResponsabilFilterVisibility = JSON.parse(
      localStorage.getItem("responsabilFilter")
    );
    const storedDeschisDeFilterVisibility = JSON.parse(
      localStorage.getItem("deschisDeFilter")
    );
    if (storedCCFilterVisibility !== null) {
      setCCFilterVisible(storedResponsabilFilterVisibility);
    }
    if (storedResponsabilFilterVisibility !== null) {
      setIsResponsabilFilterVisible(storedResponsabilFilterVisibility);
    }
    if (storedDeschisDeFilterVisibility !== null) {
      setIsDeschisDeFilterVisible(storedDeschisDeFilterVisibility);
    }
    if (window.innerWidth >= 768) {
      setCheckIsNotMobile(true);
    }
  }, []);
  function cutURL() {
    const originalUrl = window.location.href;

    const url = new URL(originalUrl);
    if (url.search !== "") {
      // Remove the last route
      const currentUrl = window.location.href;
      const modifiedUrl = currentUrl.split("?")[0];

      // window.location.href = modifiedUrl + "?ticket=''";
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDataForFilters = () => {
    axios
      .post(pathAPI + "dashboard/ticketing/getAllFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "SUBJECTS,TEAMS,STATUS",
      })
      .then(function (response) {
        const distinctSubjects = Object.values(
          response.data.SUBJECTS.reduce((uniqueSubjects, subject) => {
            if (!uniqueSubjects[subject.NAME]) {
              uniqueSubjects[subject.NAME] = subject;
            }
            return uniqueSubjects;
          }, {})
        );
        setSubiectFilter(distinctSubjects);
        setReceptorFilter(response.data.TEAMS);
        setStatusFilter(response.data.STATUS);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });

    axios
      .post(
        pathAPI + "dashboard/ticketing/getAllFromFiltersTickets/",
        {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
        }
      )
      .then(function (response) {
        setTicketsFilter(response.data.TICKETS);
        setTitluFilter(response.data.TITLURI);
        setEmitatorFilter(response.data.USERS);
        setRaportatFilter(response.data.REPORTED);
        setCcFilter(response.data.CC);
        setPersonFilter(response.data.USERS);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
    // axios
    //   .post(
    //     pathAPI + "dashboard/ticketing/getDistinctSubjects/",
    //     {
    //       token: localStorage.getItem("token"),
    //       email: localStorage.getItem("email"),
    //     }
    //   )
    //   .then(function (response) {
    //     setDistinctSubjects(response.data);
    //     console.log("fff", distinctSubjects);
    //   })
    //   .catch(function (error) {
    //     console.log("Request failed.");
    //   });
  };

  // const handleInputChange = (event) => {
  //   const value = event;

  //   setFormData({
  //     ...formData,
  //     ["comment"]: value,
  //   });
  // };
  const handleInputChange = (value) => {
    setFormData({
      ...formData,
      comment: value,
    });
  };
  const handleInputChangeQuill = (comment) => {
    // console.log(comment)
    setFormData({
      ...formData,
      ["comment"]: comment,
    });
  };

  // const handleAutocompleteChange = (event, newValue, name) => {
  //     setFormData({
  //         ...formData,
  //         [name]: newValue
  //     });
  // };
  useEffect(() => {
    axios
      .post(pathAPI + "dashboard/ticketing/getUsersFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setUsers(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  }, []);

  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }
  const getTicketDetails = () => {
    setLoader(true);

    // Fetch users
    axios
      .post(pathAPI + "dashboard/ticketing/getUsersFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((response) => {
        const users = response.data;

        // Fetch ticket details
        axios
          .post(pathAPI +
            `/dashboard/ticketing/getTicketDetails/`,
            {
              token: localStorage.getItem("token"),
              email: localStorage.getItem("email"),
              ticketNumber: selectedTicketNumber,
            }
          )
          .then((response) => {
            if (response.data.ticketDetails[0]) {
              setTicketData(response.data.ticketDetails[0]);
              setTicketComments(response.data.ticketComments);
              getSubjects(response.data.ticketDetails[0].team_id);
              setSubjectEdit(response.data.ticketDetails[0].subject_name);
              setTicketCCs(response.data.CC);
              setSelectedTicketCCs(
                users.filter((user) =>
                  response.data.CC.map((cc) => cc.EMAIL).includes(user.email)
                )
              );
              setNote(response.data.ticketDetails[0].note);
              setInitialStatusTicket({
                ID: response.data.ticketDetails[0].status_id,
                NAME: response.data.ticketDetails[0].status_name,
              });
              setStatusTicket({
                ID: response.data.ticketDetails[0].status_id,
                NAME: response.data.ticketDetails[0].status_name,
              });
              setUploadsNote(response.data.UploadsNote);
              setUploadsAttachTichet(response.data.UploadsTickets);
              if (
                localStorage.getItem("email") ===
                response.data.ticketDetails[0].from_email ||
                localStorage.getItem("email") ===
                response.data.ticketDetails[0].user_reported ||
                checkRoles([999, 409])
              ) {
                setStatusRights(false);
              }
            }
            setLoader(false);
          })
          .catch((error) => {
            console.log("Request failed.");
            setLoader(false);
          });
      })
      .catch((error) => {
        console.log("Request failed.");
        setLoader(false);
      });
  };
  const handleGetCommentToSend = (commentToSend) => {
    setCommentForChat(commentToSend);
    handleSubmit(null, null, null, commentToSend);
  };

  const handleSubmit = (event, type, fileId, commentToSend2) => {
    setShouldResetQuill(true);

    if (event) {
      event.preventDefault();
    }
    // setLoader(true);

    let commentType = type;
    if (type !== "file") {
      commentType = "comment";
    }
    let commentToSend = commentToSend2;
    if (type === "file") {
      commentToSend = fileId;
    }
    if (statusTicket.NAME !== initialStatusTicket.NAME) {
    }
    if (
      statusTicket.NAME !== initialStatusTicket.NAME ||
      (commentToSend !== "" && commentToSend !== undefined)
    ) {
      axios
        .post(pathAPI + "/dashboard/ticketing/addComment/", {
          token: localStorage.getItem("token"),
          email: localStorage.getItem("email"),
          comment: commentToSend,
          ticketId: ticketData.ticket_id,
          status: statusTicket.ID,
          type: commentType,
        })
        .then(function (response) {
          writeUserData(ticketData.ticket_number);
          setFormData(initialFormData);
          getTicketDetails();
          // setLoader(false);
          setAlertSucces(true);
          getTickets();
          setShouldResetQuill(false);
          sendMessage();
        })
        .catch(function (error) {
          // setLoader(false);
          console.log("Request failed.");
        });
    } else {
      setAlertNoChange(true);
      // setLoader(false);
    }
  };
  const sendMessage = () => {
    setResetEditor(true);
    setTimeout(() => {
      setResetEditor(false);
    }, 0);
  };
  const editTitle = (idTitle) => {
    setLoader(true);
    axios
      .post(pathAPI + "/dashboard/ticketing/editTitle/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id: idTitle,
        title: titleEdit,
      })
      .then(function (response) {
        setFormData(initialFormData);
        getTicketDetails();
        setTitleEditAux(null);
        setLoader(false);
        setAlertSucces(true);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Request failed.");
      });
  };
  const editSubject = (subjectId) => {
    setLoader(true);
    axios
      .post(pathAPI + "/dashboard/ticketing/editSubject/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        id: subjectId,
        subject: subjectsId,
      })
      .then(function (response) {
        setFormData(initialFormData);
        getTicketDetails();
        setSubjectEditAux(null);
        setLoader(false);
        setAlertSucces(true);
        setSubjectEdited(true);
        setSubjectId(null);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Request failed.");
      });
  };

  const updateNote = () => {
    let params = new FormData();
    params.append("ticket", selectedTicketNumber);
    params.append("note", note);
    params.append("token", localStorage.getItem("token"));
    params.append("email", localStorage.getItem("email"));
    axios
      .post(pathAPI + "/dashboard/ticketing/updateNote/", params)
      .then(() => {
        console.log("Nota updatata!");
      });
  };

  const handleFileChange = (e, field) => {
    const event = e;
    if (e.target.files) {
      let params = new FormData();
      params.append(field + "-0", e.target.files[0]);
      params.append("token", localStorage.getItem("token"));
      params.append("email", localStorage.getItem("email"));
      params.append("ticketID", ticketData.ticket_id);
      axios
        .post(
          pathAPI + "dashboard/ticketing/uploadNotaInterna/",
          params
        )
        .then(function (response) {
          if (response?.data.last_inserted_ids) {
            const lastInsertedIds = response?.data?.last_inserted_ids;
            if (lastInsertedIds) {
              lastInsertedIds.forEach((fileId) => {
                const type = "file";
                handleSubmit(event, type, fileId);
              });
            }
          }
          getTicketDetails();
        })
        .catch(function (error) {
          setAlertUploadNoteNok(true);
        });
    }
  };

  const uploadClick = () => {
    fileRef.current.click();
  };
  const openDeleteAttachamentOpen = (event, file) => {
    // setTagToDeleteIndex(tagIndex);
    setFileToDeleteFromAttach(file);
    setEventForDeleteFromAttach(event);
    setIsDeleteAttachamentOpen(true);
  };

  const closeDeleteAttachamentOpen = () => {
    setIsDeleteAttachamentOpen(false);
    // setTagToDeleteIndex(null);
  };
  const uploadAttachTicket = () => {
    fileRefTicket.current.click();
  };

  const deleteFileNota = () => {
    const event = eventForDeleteFromAttach;
    const file = fileToDeleteFromAttach;
    event.stopPropagation();
    setLoader(true);
    let params = new FormData();
    params.append("file", file);
    params.append("token", localStorage.getItem("token"));
    params.append("email", localStorage.getItem("email"));
    axios
      .post(
        pathAPI + "dashboard/ticketing/deleteFileNota/",
        params
      )
      .then(function (response) {
        closeDeleteAttachamentOpen();

        getTicketDetails();
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Request failed.");
      });
  };

  const getSubjects = (id) => {
    setLoader(true);
    let params = new FormData();
    if (id) {
      params.append("team", id);
      axios
        .post(pathAPI + "/dashboard/ticketing/getSubjects/", params)
        .then(function (response) {
          setSubjects(response.data);
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          console.log("Request failed.");
        });
    }
  };
  const getTickets = () => {
    setLoader(true);
    let params = new FormData();
    params.append("token", localStorage.getItem("token"));
    params.append("email", localStorage.getItem("email"));
    if (selectedTicket) params.append("ticket", selectedTicket.ID);
    if (selectedTitlu) params.append("titlu", selectedTitlu.summary);
    if (selectedSubject) params.append("subject", selectedSubject.ID);
    if (selectedEmitator) params.append("emitator", selectedEmitator.ID);
    if (selectedReceptor) params.append("receptor", selectedReceptor.ID);
    if (selectedStatus) params.append("status", selectedStatus.ID);
    if (selectedRaportat) params.append("reported", selectedRaportat.ID);
    if (selectedCC) params.append("cc", selectedCC.id);
    if (selectedPerson) params.append("person", selectedPerson.ID);
    axios
      .post(
        pathAPI + "dashboard/ticketing/getAllTickets/",
        params
      )
      .then(function (response) {
        setTicketsList(response.data);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Request failed.");
      });

    axios
      .post(pathAPI + "dashboard/ticketing/getAllFrom/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableNames: "STATUS",
      })
      .then(function (response) {
        setStatus(response.data.STATUS);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
    const urlParams = new URLSearchParams(window.location.search);
    const ticket = urlParams.get("ticket");
    if (ticket) {
      setSelectedTicketNumber(ticket);
    }
    getDataForFilters();
    setPage(0);
  };
  const [isTicketDetailModalOpen, setIsTicketDetailModalOpen] = useState(false);

  const handleTicketClick = (ticketNumber) => {
    setSelectedTicketNumber(ticketNumber);
    setIsTicketDetailModalOpen(true);
  };

  const closeTicketDetailModal = () => {
    setSelectedTicketNumber(null);
    setIsTicketDetailModalOpen(false);
    console.log("closing");
    cutURL();
    const url = new URL(window.location.href);
    url.searchParams.delete("ticket");

    // Replace the current URL without triggering a page reload
    window.history.replaceState({}, "", url.toString());
  };

  useEffect(() => {
    if (selectedTicketNumber) {
      setIsTicketDetailModalOpen(true);
    } else {
      setSelectedTicketNumber(null);
    }
  }, [selectedTicketNumber]);
  useEffect(() => {
    getTickets();
    const interval = setInterval(getTickets, 1000 * 60 * 3);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [
    selectedTicket,
    selectedTitlu,
    selectedSubject,
    selectedEmitator,
    selectedReceptor,
    selectedStatus,
    selectedRaportat,
    selectedCC,
    selectedPerson,
    subjectEditez,
  ]);

  useEffect(() => {
    if (selectedTicketNumber) {
      const currentTitle = document.title;
      const playNotificationSound = () => {
        if (document.hidden) {
          const audio = audioRef.current;
          audio.src = ticketingSound;
          playSound();
          const currentTitle = document.title;
          const newTitle = "Comentariu nou";
          for (let i = 0; i < 10; i++) {
            setTimeout(() => {
              if (i % 2 === 0) {
                document.title = currentTitle;
              } else {
                document.title = newTitle;
              }
            }, i * 500);
          }
        } else {
          document.title = currentTitle;
        }
      };
      const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
          document.title = currentTitle;
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
      getTicketDetails();

      const db = getDatabase();
      const starCountRef = ref(
        db,
        "ticketing/" + selectedTicketNumber + "/lastUpdate"
      );
      onValue(starCountRef, (snapshot) => {
        if (actualStatus != snapshot._node.value_ && actualStatus) {
          // alert();
          getTicketDetails();
          playNotificationSound();
        }
        setActualStatus(snapshot._node.value_);
      });
    }
  }, [selectedTicketNumber, actualStatus]);
  const toggleFilterVisibility = (filterName) => {
    switch (filterName) {
      case "ccFilter":
        setCCFilterVisible((prevVisibility) => !prevVisibility);
        localStorage.setItem(filterName, JSON.stringify(!isCCFilterVisible));
        break;
      case "responsabilFilter":
        setIsResponsabilFilterVisible((prevVisibility) => !prevVisibility);
        localStorage.setItem(
          filterName,
          JSON.stringify(!isResponsabilFilterVisible)
        );
        break;
      case "deschisDeFilter":
        setIsDeschisDeFilterVisible((prevVisibility) => !prevVisibility);
        localStorage.setItem(
          filterName,
          JSON.stringify(!isDeschisDeFilterVisible)
        );
        break;
      default:
        break;
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <div className="ticketsDisplay">
      <audio ref={audioRef} />
      <Filters
        ticketsFilter={ticketsFilter}
        titluFilter={titluFilter}
        subiectFilter={subiectFilter}
        emitatorFilter={emitatorFilter}
        receptorFilter={receptorFilter}
        statusFilter={statusFilter}
        raportatFilter={raportatFilter}
        ccFilter={ccFilter}
        distinctSubject={distinctSubjects}
        personFilter={personFilter}
        selectedTicket={selectedTicket}
        selectedTitlu={selectedTitlu}
        selectedSubject={selectedSubject}
        selectedEmitator={selectedEmitator}
        selectedReceptor={selectedReceptor}
        selectedStatus={selectedStatus}
        selectedRaportat={selectedRaportat}
        selectedCC={selectedCC}
        selectedPerson={selectedPerson}
        setSelectedTicket={setSelectedTicket}
        setSelectedTitlu={setSelectedTitlu}
        setSelectedSubject={setSelectedSubject}
        setSelectedEmitator={setSelectedEmitator}
        setSelectedReceptor={setSelectedReceptor}
        setSelectedStatus={setSelectedStatus}
        setSelectedRaportat={setSelectedRaportat}
        setSelectedCC={setSelectedCC}
        setSelectedPerson={setSelectedPerson}
        isCCVisible={isCCFilterVisible}
        isResponsabilFilterVisible={isResponsabilFilterVisible}
        isDeschisDeFilterVisible={isDeschisDeFilterVisible}
        handleFilterButtonClick={handleFilterButtonClick}
      />{" "}
      <div className="boxFilterIcon">
        <TuneIcon
          className="filterIcon"
          sx={{ left: 0, cursor: "pointer" }}
          onClick={handleFilterButtonClick}
        >
          Filter
        </TuneIcon>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="headerTable" style={{ color: "white" }}>
                Status
              </TableCell>
              <TableCell className="headerTable" style={{ color: "white" }}>
                Ticket
              </TableCell>
              <TableCell className="headerTable" style={{ color: "white" }}>
                Last Update
              </TableCell>

              <TableCell className="headerTable" style={{ color: "white" }}>
                Titlu
              </TableCell>
              <TableCell className="headerTable" style={{ color: "white" }}>
                Subject
              </TableCell>
              <TableCell className="headerTable" style={{ color: "white" }}>
                From Email
              </TableCell>

              <TableCell className="headerTable" style={{ color: "white" }}>
                Assigned Team
              </TableCell>
              <TableCell className="headerTable" style={{ color: "white" }}>
                Urgency
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ticketsList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.ticket_number}
                  className={
                    row.ticket_number === selectedTicketNumber
                      ? "selectedTicket"
                      : ""
                  }
                >
                  <TableCell>
                    {row.status === "2" ? (
                      <Tooltip title="REZOLVAT" arrow>
                        <TaskAltIcon
                          style={{ fill: "rgb(0, 255, 200)", fontSize: "30" }}
                        />
                      </Tooltip>
                    ) : row.status === "3" ? (
                      <Tooltip title="INCHIS-NEREZOLVAT" arrow>
                        <HighlightOffOutlinedIcon
                          style={{ fill: "#f34646", fontSize: "30" }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="DESCHIS" arrow>
                        <PendingOutlinedIcon
                          style={{ fill: "#FFD700", fontSize: "30" }}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell
                    className="clickable"
                    // onClick={() => setSelectedTicketNumber(row.ticket_number)}
                    onClick={() => handleTicketClick(row.ticket_number)}
                  >
                    <span>{row.ticket_number}</span>
                  </TableCell>
                  <TableCell>{row.LAST_UPDATE_DATE}</TableCell>

                  <TableCell>
                    <span>{row.summary}</span>
                  </TableCell>
                  <TableCell
                    className="clickable"
                    onClick={() => handleTicketClick(row.ticket_number)}
                  >
                    <span>{row.subject_name}</span>
                  </TableCell>
                  <TableCell>{row.from_email}</TableCell>
                  <TableCell>{row.assigned_user_email}</TableCell>

                  <TableCell>{statusIcons[row.urgency_name] || null}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={ticketsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Tichete"
      />
      {/* ************************************************************************************************ */}
      {/* {isTicketDetailModalOpen && ( */}
      {/* <div className="columnDiv">
                    <Typography>Fisiere atasate tichetului:</Typography>
                    <div className="rowDiv">
                      {uploadsAttachTichet && uploadsAttachTichet !== []
                        ? uploadsAttachTichet.map((obj, index) => (
                            <Tooltip title={obj.FILE_NAME}>
                              <div
                                className="fileUploaded"
                                onClick={() => {
                                  window.open(
                                    pathAPI +
                                      "uploads/ticketing/" +
                                      obj.FILE_NAME
                                  );
                                }}
                              >
                                <DescriptionIcon color="primary" />
                                <Typography variant="subtitle2">
                                  <b>file {index + 1}</b>
                                </Typography>
                                <Tooltip title="Eliminati fisierul">
                                  <button
                                    className="xButton"
                                    onClick={
                                      (e) => {
                                        e.stopPropagation();
                                        openDeleteAttachamentOpen(
                                          e,
                                          obj.FILE_NAME
                                        );
                                      }
                                      // deleteFileNota(e, obj.FILE_NAME)
                                    }
                                  >
                                    <span className="buttonFileText">x</span>
                                  </button>
                                </Tooltip>
                              </div>
                            </Tooltip>
                          ))
                        : null}
                      <div className="uploadNote">
                        <Tooltip title="Ataseaza un nou fisier tichetului">
                          <IconButton onClick={uploadAttachTicket}>
                            <AttachFileIcon />
                          </IconButton>
                        </Tooltip>
                        <input
                          ref={fileRefTicket}
                          className="file-input"
                          type="file"
                          onChange={(e) => handleFileChange(e, "Ticket")}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
      <TicketDetailsModal
        isOpen={isTicketDetailModalOpen}
        ticketNumber={selectedTicketNumber}
        onClose={closeTicketDetailModal}
        ticketData={ticketData}
        ticketComments={ticketComments}
        options={users}
        selectedTicketCCs={selectedTicketCCs}
        uploadsAttachTichet={uploadsAttachTichet}
        openDeleteAttachamentOpen={openDeleteAttachamentOpen}
        handleFileChange={handleFileChange}
        note={note}
        setNote={setNote}
        updateNote={updateNote}
        uploadsNote={uploadsNote}
        deleteFileNota={deleteFileNota}
        handleInputChange={handleInputChange}
        formData={formData}
        handleSubmit={handleSubmit}
        statusRights={statusRights}
        status={status}
        subjectEditAux={subjectEditAux}
        subjects={subjects}
        subjectEdit={subjectEdit}
        setSubjectEdit={setSubjectEdit}
        setSubjectId={setSubjectId}
        subjectEditRef={subjectEditRef}
        setSubjectEditAux={setSubjectEditAux}
        editSubject={editSubject}
        titleEditAux={titleEditAux}
        titleEditRef={titleEditRef}
        titleEdit={titleEdit}
        setTitleEdit={setTitleEdit}
        setTitleEditAux={setTitleEditAux}
        editTitle={editTitle}
        statusTicket={statusTicket}
        setStatusTicket={setStatusTicket}
        isNotMobile={checkIsNotMobile}
        handleInputChangeQuill={handleInputChangeQuill}
        setFormData={setFormData}
        shouldResetQuill={shouldResetQuill}
        handleGetCommentToSend={handleGetCommentToSend}
        resetEditor={resetEditor}
      />
      {/* )} */}
      {/* ************************************************************************************************ */}
      <Dialog
        className="ticket-dialog"
        // open={selectedTicketNumber !== null && ticketData !== null}
        open={false}
        onClose={() => {
          setSelectedTicketNumber(null);
          setSubjectEdited(false);
          cutURL();
          setActualStatus(null);
          setFormData({
            ...formData,
            ["comment"]: "",
          });
          const url = new URL(window.location.href);
          url.searchParams.delete("ticket");

          // Replace the current URL without triggering a page reload
          window.history.replaceState({}, "", url.toString());
        }}
      >
        {ticketData !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <div>
                <div className="rowDiv">
                  {ticketData.subject_name !== subjectEditAux ? (
                    <p className="ticket-title">{ticketData.subject_name}</p>
                  ) : (
                    <div className="commEditMode">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Autocomplete
                          options={subjects.map((subjects) => subjects.NAME)}
                          value={subjectEdit}
                          onChange={(event, newValue) => {
                            setSubjectEdit(newValue);
                            const selectedSubject = subjects.find(
                              (subject) => subject.NAME === newValue
                            );
                            if (selectedSubject) {
                              const subjectId = selectedSubject.ID;
                              setSubjectId(subjectId);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: "300px" }}
                              {...params}
                              inputRef={subjectEditRef}
                              size="small"
                              multiline
                              fullWidth
                              value={subjectEdit}
                              onChange={(e) => setSubjectEdit(e.target.value)}
                            />
                          )}
                        />
                        <IconButton
                          onClick={() => setSubjectEditAux(null)}
                          color="error"
                        >
                          <CancelIcon />
                        </IconButton>
                        <IconButton
                          color="success"
                          onClick={() => editSubject(ticketData.ticket_id)}
                        >
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </div>
                    </div>
                  )}
                  {checkRoles([999, 409]) ? (
                    <IconButton
                      onClick={() => {
                        setSubjectEdit(ticketData.subject_name);
                        setSubjectEditAux(ticketData.subject_name);
                        subjectEditRef.current.focus();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </div>
                <div className="rowDiv">
                  {ticketData.summary !== titleEditAux ? (
                    <p className="ticket-title">{ticketData.summary}</p>
                  ) : (
                    <div className="commEditMode">
                      <TextField
                        inputRef={titleEditRef}
                        value={titleEdit}
                        size="small"
                        multiline
                        fullWidth
                        onChange={(e) => setTitleEdit(e.target.value)}
                      />
                      <IconButton
                        onClick={() => setTitleEditAux(null)}
                        color="error"
                      >
                        <CancelIcon />
                      </IconButton>
                      <IconButton
                        color="success"
                        onClick={() => editTitle(ticketData.ticket_id)}
                      >
                        <CheckCircleOutlineIcon />
                      </IconButton>
                    </div>
                  )}

                  <IconButton
                    onClick={() => {
                      setTitleEdit(ticketData.summary);
                      setTitleEditAux(ticketData.summary);
                      titleEditRef.current.focus();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
              <p className="ticket-number">{ticketData.ticket_number}</p>
            </div>
            <div className="ticket-details">
              <div className="ticket-details-container">
                <div className="ticket-details-box">
                  <p>
                    Stare:{" "}
                    <span className="ticketDetails">
                      {ticketData.status_name}
                    </span>
                  </p>
                  <p>
                    Prioritate:{" "}
                    <span className="ticketDetails">
                      {ticketData.urgency_name}
                    </span>
                  </p>
                  <p>
                    Departament:{" "}
                    <span className="ticketDetails">
                      {ticketData.team_name}
                    </span>
                  </p>
                  <p>
                    Data crearii:{" "}
                    <span className="ticketDetails">
                      {ticketData.create_date}
                    </span>
                  </p>
                </div>
                <div className="ticket-details-box">
                  <p>
                    Deschis de:{" "}
                    <span className="ticketDetails">
                      {ticketData.from_email}
                    </span>
                  </p>
                  <p>
                    Raportat lui:{" "}
                    <span className="ticketDetails">
                      {ticketData.user_reported}
                    </span>
                  </p>

                  <CustomizedHook
                    options={users}
                    selectedTicketCCs={selectedTicketCCs}
                    ticketId={ticketData.ticket_id}
                    ticketData={ticketData}
                  />
                  <p>
                    Ultima actualizare:{" "}
                    <span className="ticketDetails">
                      {ticketData.LAST_UPDATE_DATE}
                    </span>
                  </p>
                  {/* <p>Deschis de: {ticketData.from_email}</p> */}
                </div>
              </div>
              <div className="ticket-details-container">
                <div className="ticket-details-box">
                  <div className="columnDiv">
                    <div className="crbTopUpload">
                      <Typography>Fisiere atasate tichetului:</Typography>
                      <div className="uploadNote">
                        <Tooltip title="Ataseaza un nou fisier tichetului">
                          <IconButton onClick={uploadAttachTicket}>
                            <AttachFileIcon />
                          </IconButton>
                        </Tooltip>
                        <input
                          ref={fileRefTicket}
                          className="file-input"
                          type="file"
                          onChange={(e) => handleFileChange(e, "Ticket")}
                        />
                      </div>
                    </div>
                    <div className="rowDiv crbAtasamente">
                      {uploadsAttachTichet && uploadsAttachTichet !== []
                        ? uploadsAttachTichet.map((obj, index) => (
                          <Tooltip title={obj.FILE_NAME}>
                            <div
                              className="fileUploaded"
                              onClick={() => {
                                window.open(
                                  pathAPI +
                                  "uploads/ticketing/" +
                                  obj.FILE_NAME
                                );
                              }}
                            >
                              <DescriptionIcon color="primary" />
                              <Typography variant="subtitle2">
                                <b>
                                  {obj.FILE_NAME.replace("Ticket_", "")
                                    .replace(/\d{15}/, "")
                                    .replace(/\d{14}/, "")
                                    .replace(/\d{10}/, "")}
                                </b>
                              </Typography>
                              <Tooltip title="Eliminati fisierul">
                                <button
                                  className="xButton"
                                  onClick={
                                    (e) => {
                                      e.stopPropagation();
                                      openDeleteAttachamentOpen(
                                        e,
                                        obj.FILE_NAME
                                      );
                                    }
                                    // deleteFileNota(e, obj.FILE_NAME)
                                  }
                                >
                                  <span className="buttonFileText">x</span>
                                </button>
                              </Tooltip>
                            </div>
                          </Tooltip>
                        ))
                        : null}
                    </div>
                  </div>
                </div>
                <div className="ticket-details-box">
                  {localStorage.getItem("email") === ticketData.from_email ? (
                    <div className="columnDiv">
                      <div className="crbTopUpload">
                        <TextField
                          size="small"
                          name="note"
                          label="Notă internă"
                          value={note}
                          fullWidth
                          onChange={(e) => setNote(e.target.value)}
                          onBlur={updateNote}
                          multiline
                        />
                        <div className="uploadNote">
                          <Tooltip title="Ataseaza un nou fisier notei interne">
                            <IconButton onClick={uploadClick}>
                              <AttachFileIcon />
                            </IconButton>
                          </Tooltip>
                          <input
                            ref={fileRef}
                            className="file-input"
                            type="file"
                            onChange={(e) =>
                              handleFileChange(e, "Nota_interna")
                            }
                          />
                        </div>
                      </div>
                      <div className="rowDiv crbAtasamente">
                        {uploadsNote && uploadsNote !== []
                          ? uploadsNote.map((obj, index) => (
                            <Tooltip title={obj.FILE_NAME}>
                              <div
                                className="fileUploaded"
                                onClick={() => {
                                  window.open(
                                    pathAPI +
                                    "uploads/ticketing/" +
                                    obj.FILE_NAME
                                  );
                                }}
                              >
                                <DescriptionIcon color="success" />
                                <Typography variant="subtitle2">
                                  <b>
                                    {obj.FILE_NAME.replace("Ticket_", "")
                                      .replace(/\d{15}/, "")
                                      .replace(/\d{14}/, "")
                                      .replace(/\d{10}/, "")}
                                  </b>
                                </Typography>
                                <Tooltip title="Eliminati fisierul">
                                  <button
                                    className="xButton"
                                    onClick={(e) =>
                                      deleteFileNota(e, obj.FILE_NAME)
                                    }
                                  >
                                    <span className="buttonFileText">x</span>
                                  </button>
                                </Tooltip>
                              </div>
                            </Tooltip>
                          ))
                          : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {ticketComments !== null && (
              <div className={"ticket-comments"}>
                {ticketComments.map((row, index) => (
                  <div
                    key={index}
                    className={
                      "ticket-comment" +
                      (localStorage.getItem("email") === row.user_email
                        ? " reported"
                        : selectedTicketCCs.some(
                          (cc) => cc.email === row.user_email
                        )
                          ? " selected"
                          : "")
                    }
                  >
                    <div
                      className="ticket-comment-header"
                      style={{
                        background: selectedTicketCCs.some(
                          (cc) => cc.email === row.user_email
                        )
                          ? "rgb(131 237 131 / 33%)"
                          : localStorage.getItem("themeMode") === "darkMode" &&
                            row.is_reported_by_user === "1"
                            ? "rgb(231 228 55 / 41%)"
                            : localStorage.getItem("themeMode") === "lightMode" &&
                              row.is_reported_by_user === "1"
                              ? "rgb(247 242 0 / 20%)"
                              : localStorage.getItem("email") === row.user_email
                                ? "rgb(60 225 219 / 22%)"
                                : "rgb(60 225 219 / 22%)",
                      }}
                    >
                      <p>{row.user_name}</p>
                      {selectedTicketCCs.some(
                        (cc) => cc.email === row.user_email
                      ) ? (
                        <p
                          style={{
                            background: "rgb(0 245 0 / 19%)",
                            padding: "0.3rem 1rem 0.3rem 1rem",
                            borderRadius: "5px",
                            color: "rgb(23 231 23)",
                          }}
                        >
                          CC
                        </p>
                      ) : row.is_reported_by_user === "1" ? (
                        <p
                          style={{
                            background: "rgb(245 228 14 / 15%)",
                            padding: "0.3rem 1rem 0.3rem 1rem",
                            borderRadius: "5px",
                            color: "rgb(255 165 0)",
                          }}
                        >
                          Raportat
                        </p>
                      ) : localStorage.getItem("email") === row.user_email ? (
                        <p
                          style={{
                            background: "rgb(20 229 222 / 27%)",
                            padding: "0.3rem 1rem 0.3rem 1rem",
                            borderRadius: "5px",
                            color: "rgb(27 180 239)",
                          }}
                        >
                          Inițiator
                        </p>
                      ) : (
                        <p
                          style={{
                            background: "rgb(20 229 222 / 27%)",
                            padding: "0.3rem 1rem 0.3rem 1rem",
                            borderRadius: "5px",
                            color: "rgb(27 180 239)",
                          }}
                        >
                          Inițiator
                        </p>
                      )}
                    </div>
                    <div className="ticket-body">
                      <p dangerouslySetInnerHTML={{ __html: row.COMMENT }}></p>
                      <span style={{ fontSize: "13px" }}>
                        {row.comment_date}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <form className="ticket-answear" onSubmit={handleSubmit}>
              {/* <TextField
                                size="small"
                                name="comment"
                                className="commentBox"
                                label="Adauga comentariu"
                                fullWidth
                                value={formData.comment ?? ''}
                                onChange={handleInputChange}
                                multiline
                            // disabled = {statusRights}
                            /> */}

              {/* <ReactQuill
                className="messageContainer "
                value={formData.comment ?? ""}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                placeholder="Adauga comentariu"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["clean"],
                    [{ color: [] }],
                    [{ size: [] }],
                    ["emoji"],
                  ],
                  "emoji-toolbar": true,
                  "emoji-shortname": true,
                }}
                formats={[
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "color",
                  "size",
                  "emoji",
                ]}
              /> */}
              <div className="ticket-answear-acction">
                <Autocomplete
                  size="small"
                  name="status"
                  id="status"
                  options={status}
                  value={statusTicket}
                  disabled={statusRights}
                  getOptionLabel={(option) => option.NAME}
                  renderInput={(params) => (
                    <TextField {...params} label="Stare tichet" />
                  )}
                  onChange={(event, newValue) => setStatusTicket(newValue)}
                />
                <Button variant="contained" type="submit">
                  Trimite
                </Button>
              </div>
            </form>
          </div>
        )}
      </Dialog>
      <Loader loading={loader} />
      <GlobalAlert
        openAlert={alertUploadNoteNok}
        setOpenAlert={setAlertUploadNoteNok}
        color={"error"}
        message={"Tipul fisierului nu corespunde."}
      />
      <GlobalAlert
        openAlert={alertNoChange}
        setOpenAlert={setAlertNoChange}
        color={"warning"}
        message={"Nu aveti nicio modificare pe care sa o trimiteti."}
      />
      <GlobalAlert
        openAlert={alertSucces}
        setOpenAlert={setAlertSucces}
        color={"success"}
        message={"Actiune reusita."}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 16, display: "flex", flexDirection: "column" }}>
          <Typography>Optiuni Filtre</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ flex: 1 }}>CC Filter</span>
            <Switch
              checked={isCCFilterVisible}
              onClick={() => toggleFilterVisibility("ccFilter")}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ flex: 1 }}>Responsabil</span>
            <Switch
              checked={isResponsabilFilterVisible}
              onClick={() => toggleFilterVisibility("responsabilFilter")}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ flex: 1 }}>Deschis De</span>
            <Switch
              checked={isDeschisDeFilterVisible}
              onClick={() => toggleFilterVisibility("deschisDeFilter")}
            />
          </div>
        </div>
      </Popover>
      <Dialog
        open={isDeleteAttachamentOpen}
        onClose={closeDeleteAttachamentOpen}
      >
        <DialogTitle>Confirma stergerea</DialogTitle>
        <DialogContent>Esti sigur/a ca vrei sa faci asta ?</DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteAttachamentOpen} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteFileNota} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Tickets;
