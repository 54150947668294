import React, { useState, useEffect } from "react";
import Session from "./../../Components/Session.js";
import axios from "axios";
import { styled } from "@mui/material/styles";
import "./gpsVehicles.css";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import pathAPI from "../../pathAPI.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: "20%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function GpsVehicles() {
  const [GpsVehicles, setGpsVehicles] = useState([]);

  const getGpsVehicles = () => {
    axios
      .post(pathAPI + "dashboard/gps/getGpsVehicles", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then((res) => {
        setGpsVehicles(res.data);
        console.log(res.data);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getGpsVehicles();
  }, []);

  var auth = false;
  if (Session([999, 800])) {
    auth = true;
  }

  return (
    <div>
      {auth ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table" stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  #
                </TableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Device Id
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  VIN
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Last Update
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Fuel in Liter
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  GPS Connected
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Ignition
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  Online
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "#0075ba", color: "white" }}
                >
                  TrailerId
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {GpsVehicles.map((GpsVehicles, index) => (
                <StyledTableRow key={GpsVehicles.id}>
                  <StyledTableCell style={{ width: "5%" }}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{GpsVehicles.idDevice}</StyledTableCell>
                  <StyledTableCell>{GpsVehicles.vin}</StyledTableCell>
                  <StyledTableCell>{GpsVehicles.lastUpdate}</StyledTableCell>
                  <StyledTableCell>{GpsVehicles.Fuel1InLiter}</StyledTableCell>
                  <StyledTableCell>
                    {GpsVehicles.IsGpsConnected === "1" ? (
                      <div className="greenCircle">●</div>
                    ) : (
                      <div className="redCircle">●</div>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {GpsVehicles.IsIgnitionOn === "1" ? (
                      <div className="greenCircle">●</div>
                    ) : (
                      <div className="redCircle">●</div>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {GpsVehicles.IsOnline === "1" ? (
                      <div className="greenCircle">●</div>
                    ) : (
                      <div className="redCircle">●</div>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{GpsVehicles.TrailerId}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  );
}
export default GpsVehicles;
