import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

import {
  getNewsletterData,
  sendNewsletterEmails,
} from "../../../Actions/Newsletters/Newsletters";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar, roRO } from "@mui/x-data-grid";
import { getTransportatori } from "../../../Actions/Transportatori/Transportatori";
const Newsletters = () => {
  const data = useSelector((state) => state.newsletter.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsletterData());
    dispatch(getTransportatori());
  }, [dispatch]);

  const COLUMN_MAPPING = {
    tip_transport: "Tip transport",
    numar_dosar: "Numar dosar",
    specificatie_marfa: "Specificatie Marfa",
    localitate_plecare: "Localitate Plecare",
    localitate_sosire: "Localitate Sosire ",
    tara_incarcare: "Tara Incarcare",
    tara_descarcare: "Tara Descarcare",
    ambalaj: "Ambalaj",
    min_data_incarcare: "Min Data Incarcare",
    max_data_descarcare: "Max Data Descarcare",
    user_fullname: "User",
  };
  const columns = [
    ...Object.keys(COLUMN_MAPPING).map((field) => ({
      field: field,
      headerName: COLUMN_MAPPING[field],
      flex: 1,
    })),
  ];
  const handleSendEmails = () => {
    dispatch(sendNewsletterEmails());
  };
  return (
    <div>
      <Button variant="contained" onClick={handleSendEmails}>
        Send Emails
      </Button>

      {data ? (
        <DataGrid
          localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          autoHeight
        />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default Newsletters;
