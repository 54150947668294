import React, { useState, useEffect } from "react";
import "./DetailsCard.css";

const DetailsCard = ({ ticketData }) => {
  return (
    <>
      <div class="container">
        <div class="card">
          <div class="container-card bg-green-box">
            <p class="card-title">Detalii</p>

            <p class="card-description">
              <span>Status:</span> {ticketData?.status_name}
            </p>
            <p class="card-description">
              <span>Prioritate:</span> {ticketData?.urgency_name}
            </p>
            <p class="card-description">
              <span>Departament:</span> {ticketData?.team_name}
            </p>
            <p class="card-description">
              <span>Data crearii:</span> {ticketData?.create_date}
            </p>
            <p class="card-description">
              <span>Deschis de:</span> {ticketData?.from_email}
            </p>
            <p class="card-description">
              <span>Raportat lui:</span> {ticketData?.user_reported}
            </p>
            <p class="card-description">
              <span>Ultima actualizare:</span> {ticketData?.LAST_UPDATE_DATE}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsCard;
