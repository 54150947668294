import { CircularProgress, Dialog,  DialogContent, Typography} from "@mui/material";
import React from "react";
import './Helpers.css'

const Loader = ({loading}) => {
    return(
        <Dialog open = {loading}>
            <DialogContent>
                <div className="columnCenterDiv">
                    <Typography variant="h5">Loading...</Typography>
                    <CircularProgress/>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default Loader;