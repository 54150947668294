import React from "react";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
const ExportToCsv = ({ data, headers, filename }) => {
  const convertToCSV = (arr) => {
    const header = headers.join(",");
    const csv = arr.map((row) => {
      return Object.values(row)
        .map((value) => `"${value}"`)
        .join(",");
    });
    return [header, ...csv].join("\n");
  };

  const handleExport = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `${filename}.csv`);
  };
  console.log("data csv", data);
  return (
    <Button
      type="submit"
      variant="outlined"
      className="exportCSVbtn"
      onClick={handleExport}
      size="small"
      style={{ marginLeft: "5px" }}
      endIcon={<DownloadIcon />}
    >
      Export to CSV
    </Button>
  );
};

export default ExportToCsv;
