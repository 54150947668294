import Dialog from "@mui/material/Dialog";
import axios from "axios";
import pathAPI from "../../../../pathAPI";
import React, { useState, useEffect, useRef } from "react";
import ListaPagini from "./ListaPagini";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    Button
} from "@mui/material";
import MyEditor from "../../../../Components/DraftJsEditor/EditorDraft";


const PaginaCurs = ({
    nr_paginaPROPS,
    selectedCursPROPS,
    onClose
}) => {
    const [selectedCurs, setSelectedCurs] = useState(null);
    const [nr_pagina, setNr_pagina] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paginaCurs, setPaginaCurs] = useState();
    const [listaPaginiOpen, setListaPaginiOpen] = useState(false);
    const [editMode, setEditMode] = useState(null);
    const [contentModificat, setContentModificat] = useState(null);

    const handleTextChange = (newText) => {
        setContentModificat(newText);
    };

    const updateContentPage = () => {
        setLoading(true);
        axios
            .post(pathAPI + "dashboard/cursuri/updateContentPage", {
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
                contentModificat: contentModificat,
                id_pagina: nr_pagina
            })
            .then(function (response) {
                setLoading(false);
                // console.log(response.data)
            })
            .catch(function (error) {
                console.error("Request failed:", error);
                setLoading(false);
            });
    };

    const handleEditMode = (event) => {
        const checked = event.target.checked;
        setEditMode(checked);
    };

    const getPaginaCurs = () => {
        if (!nr_pagina && !selectedCurs?.id) {
            return;
        }
        setLoading(true);
        axios
            .post(pathAPI + "dashboard/cursuri/getPaginaCurs", {
                token: localStorage.getItem("token"),
                email: localStorage.getItem("email"),
                id_curs: selectedCurs?.id,
                id_pagina: nr_pagina
            })
            .then(function (response) {
                setPaginaCurs(response.data.pagina);
                setLoading(false);
                // console.log(response.data.data)
            })
            .catch(function (error) {
                console.error("Request failed:", error);
                setLoading(false);
            });
    };

    function closeListaPagini() {
        setListaPaginiOpen(false);
    }

    function openListaPagini() {
        setListaPaginiOpen(true);
    }

    const handleChangePagina = (nrPaginaNou) => {
        setNr_pagina(nrPaginaNou);
    };

    const checkIsMobile = () => {
        if (window.innerWidth >= 768) {
            setListaPaginiOpen(true);
        } else {
            setListaPaginiOpen(false);
        }
    }

    function checkRoles(array) {
        let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
        for (let i = 0; i < array.length; i++) {
            if (activeRoles.includes(array[i])) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (nr_pagina !== 0) {
            getPaginaCurs();
        }
    }, [nr_pagina]);

    useEffect(() => {
        if (selectedCurs) {
            getPaginaCurs();
        }
    }, [selectedCurs]);

    useEffect(() => {
        setSelectedCurs(selectedCursPROPS);
        setNr_pagina(nr_paginaPROPS)
    }, [selectedCursPROPS]);

    useEffect(() => {
        checkIsMobile();
    }, []);

    return (
        <>
            <Dialog
                className="cursuri-dialog dialogBody ticketingBodyDialog"
                open={selectedCurs !== null}
                onClose={() => {
                    setSelectedCurs(null);
                    // stopInterval();
                    setNr_pagina(null);
                    checkIsMobile();
                    onClose();
                }}
            >
                <div className="boxChat">
                    <div
                        className={`${listaPaginiOpen ? "ticketDetailsTrue " : ""}phone`}
                        id="phoneContainer"
                        style={{ height: "100%" }}
                    >
                        {selectedCurs && (
                            <>
                                <div className="message-header">
                                    <div
                                        className="go-back-btn"
                                        onClick={listaPaginiOpen ? closeListaPagini : onClose}
                                    >
                                        {listaPaginiOpen && closeListaPagini ? (
                                            <ArrowBackIcon />
                                        ) : (
                                            "B"
                                        )}
                                    </div>
                                    <div
                                        className="info-ticket"
                                        id="info-ticket"
                                        onClick={openListaPagini}
                                    >
                                        <p className="info-ticket-title">{selectedCurs.nume_curs}</p>
                                    </div>
                                    {checkRoles([999, 859]) && (
                                        <div className="themeSwitch" style={{ margin: "0 24px 0 0" }}>
                                            <label className="switch">
                                                <input
                                                    id="themeSwitch"
                                                    type="checkbox"
                                                    checked={editMode}
                                                    onChange={handleEditMode}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    )}
                                </div>

                                <div className="messages">
                                    <div className="mainContentCurs">
                                        {paginaCurs ? (
                                            <>
                                                {editMode ? (
                                                    <>

                                                        <MyEditor showToolbar={true} onContentChange={handleTextChange} defaultValue={paginaCurs.content} />
                                                        <Button
                                                            variant="contained"
                                                            type="submit"
                                                            onClick={updateContentPage}
                                                        >
                                                            Salveaza modificarea
                                                        </Button>

                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="cursPageContent"
                                                            dangerouslySetInnerHTML={{
                                                                __html: paginaCurs.content,
                                                            }}
                                                        ></div>
                                                        <div style={{ textAlign: "right" }}>
                                                            <span>Ultima actualizare: {paginaCurs.last_update}</span>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="cursPageContent"
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedCurs.descriere,
                                                    }}
                                                ></div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <p>Lansare: {selectedCurs.lansare}</p>
                                                    <p>Ultima actualizare: {selectedCurs.ultima_actualizare}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <ListaPagini
                                    onChangePagina={handleChangePagina}
                                    selectedCursPROPS={selectedCurs}
                                    editModePROPS={editMode}
                                />
                            </>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default PaginaCurs;