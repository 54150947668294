import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React from "react";
import Session from "../../Components/Session.js";
import Echipamente from "./Components/Echipamente.jsx";
import ListaEchipamente from "./Components/ListaEchipamente.jsx";
import TeamManager from "./Components/Teams.jsx";
import Users from "./Components/Users.jsx";

const UsersMain = () => {
  var auth = false;
  if (Session([999, 500, 509])) {
    auth = true;
  }

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {auth ? (
        <div className="box">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="Tab salarizare">
                {checkRoles([999]) ? (
                  <Tab label="Utilizatori" value="1" />
                ) : null}
                {checkRoles([999]) ? <Tab label="Echipe" value="2" /> : null}
                {checkRoles([999]) ? <Tab label="Echipamente" value="3" /> : null}
                {checkRoles([999]) ? <Tab label="Lista Echipamente" value="4" /> : null}
              </TabList>
            </Box>

            {checkRoles([999, 509]) ? (
              <TabPanel value="1">
                <Users />
              </TabPanel>
            ) : null}
            {checkRoles([999, 1]) ? (
              <TabPanel value="2">
                <TeamManager></TeamManager>
              </TabPanel>
            ) : null}
            {checkRoles([999, 1]) ? (
              <TabPanel value="3">
                <Echipamente></Echipamente>
              </TabPanel>
            ) : null}
            {checkRoles([999, 1]) ? (
              <TabPanel value="4">
                <ListaEchipamente></ListaEchipamente>
              </TabPanel>
            ) : null}
          </TabContext>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default UsersMain;
