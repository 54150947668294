import React, { useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";

import { useDispatch, useSelector } from "react-redux"; // Import useDispatch hook
import Session from "../../../Components/Session";
import DataGridReusableCrud, {
  renderCellExpand,
} from "../../../Components/DataGridCrud/DataGridCrud";
import pathAPI from "../../../pathAPI";
import SnackbarComponent from "../../../Components/Alerts/SnackbarComponent";
import {
  deleteTransportator,
  getCountryCodes,
  getTransportatori,
  updateTransportator,
} from "../../../Actions/Transportatori/Transportatori";
import { getNewsletterData } from "../../../Actions/Newsletters/Newsletters";
const Transportatori = () => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [selectedCountries, setSelectedCuntries] = React.useState("");
  var auth = false;
  if (Session([999, 400, 709])) {
    auth = true;
  }
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newsletter.transportatori);
  const countries = useSelector((state) => state.newsletter.countries);

  useEffect(() => {
    dispatch(getCountryCodes());
  }, [dispatch]);
  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }

  const handleRutasChange = (event, values) => {
    const selectedCodValues = values.map((option) => option.cod);
    setSelectedCuntries(selectedCodValues);
  };
  const parentColumns = [
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      editable: true,
      renderCell: renderCellExpand,
    },
    {
      field: "ruta_name",
      headerName: "Rute",
      flex: 2,
      editable: true,
      renderCell: (params) => {
        const { ruta } = params.row;

        const selectedRutas = ruta ? ruta.map((route) => route.name) : [];
        const formattedRutas = selectedRutas.map((r) => r).join(" ");

        return <p>{formattedRutas}</p>;
      },
      renderEditCell: (params) => {
        const { ruta } = params.row;
        const selectedRutas = ruta ? ruta.map((route) => route.name) : [];
        return (
          <Autocomplete
            multiple
            id="tags-standard"
            options={countries}
            getOptionLabel={(option) => option.cod}
            defaultValue={selectedRutas.map((ruta) =>
              countries.find((country) => country.cod === ruta)
            )}
            onChange={handleRutasChange}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Favorites"
              />
            )}
          />
        );
      },
    },
  ];
  const handleUpdate = (requestData) => {
    dispatch(updateTransportator(requestData, selectedCountries)).then(
      (res) => {
        setSnackbarSeverity("success");
        setSnackbarMessage("Actualizare reușită!");
        setSnackbarOpen(true);
        dispatch(getTransportatori());
      }
    );
  };
  const handleDelete = (transportatorId) => {
    console.log(transportatorId);
    dispatch(deleteTransportator(transportatorId)).then((res) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Actualizare reușită!");
      setSnackbarOpen(true);
      dispatch(getTransportatori());
    });
  };
  const shouldRefresh = () => {
    dispatch(getNewsletterData()).then((res) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Actualizare reușită!");
      setSnackbarOpen(true);
      dispatch(getTransportatori());
    });
  };
  return (
    <>
      {auth ? (
        <div>
          <DataGridReusableCrud
            initialRows={data}
            parentColumns={parentColumns}
            updateDataCallBack={handleUpdate}
            deleteDataCallBack={handleDelete}
            insertDataAxios={
              pathAPI + "/dashboard/newsletter_comenzi/insertTransportator/"
            }
            shouldRefresh={shouldRefresh}
          ></DataGridReusableCrud>

          {
            <SnackbarComponent
              open={snackbarOpen}
              message={snackbarMessage}
              severity={snackbarSeverity}
              onClose={() => setSnackbarOpen(false)}
            />
          }
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Transportatori;
