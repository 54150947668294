var pathAPI;

if (window.location.href.includes("ls.euroteamgb.com")) {
    pathAPI = "https://api.euroteamgb.com/";
} else if (window.location.href.includes("lsdev.euroteamgb.com")) {
    pathAPI = "https://apidev.euroteamgb.com/";
} else if (window.location.href.includes("localhost:3000")) {
    pathAPI = "http://localhost:80/";
} else {
    console.error("Unknown environment: " + window.location.href);
    pathAPI = ""; // Default or error handling
}
// silence is golden
export default pathAPI;
