import React from "react";
import Chart from "react-apexcharts";
import Dialog from "@mui/material/Dialog";
import {
  TextField,
  Button,
  TableFooter,
  Divider,
  Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import EditIcon from "@mui/icons-material/Edit";
import pathAPI from "../../pathAPI";
import axios from "axios";
import GlobalAlert from "../../Helpers/Alert";

const MainOptiuni = (props) => {
  const {
    data,
    tableName,
    agentiData,
    clientiData,
    moneda,
    onCloseDialog,
    ticketsData,
    getAllData,
    tipPenalizare,
    dovezi,
  } = props;
  const initialFormData = {
    id: data.id,
    agent: data.agent,
    client: data.client_id,
    facturi: data.facturi,
    totalPrejudiciu: data.totalPrejudiciu,
    moneda: data.moneda,
    observatii: data.observatii,
    tichetNr: data.tichetNr,
    tipPenalizare: data.tipPenalizare,
    dovezi: data.dovezi,
    rezolvare: data.rezolvare,
    sumaPenalizare: data.sumaPenalizare,
    furnizor: data.furnizor,
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [isModified, setIsModified] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(null);

  const [selectedOption, setSelectedOption] = React.useState(data);
  const [selectedAgent, setSelectedAgent] = React.useState(null);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [selectedMoneda, setSelectedMoneda] = React.useState(null);
  const [selectedTicket, setSelectedTicket] = React.useState(null);
  const [selectedTipPenalizare, setSelectedTipPenalizare] =
    React.useState(null);
  const [selectedDovezi, setSelectedDovezi] = React.useState(null);

  const [alertNok, setAlertNok] = React.useState(false);
  const [errorMessage, setErrorMessages] = React.useState("");
  const [succesMessage, setSuccesMessage] = React.useState("");
  const handleInputChange = (event) => {
    console.log("data", data);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
    setIsModified(true);
  };
  const dialogContent = () => {
    setSelectedOption(null);
    onCloseDialog(null);
  };

  const handleAgentChange = (event, value) => {
    setSelectedAgent(value);
    setFormData({
      ...formData,
      agent: value,
    });
    setIsModified(true);
  };

  const handleClientChange = (event, value) => {
    setSelectedClient(value);
    setFormData({
      ...formData,
      client: value.ID,
    });
    setIsModified(true);
  };

  const handleMonedaChange = (event, value) => {
    setSelectedMoneda(value);
    setFormData({
      ...formData,
      moneda: value,
    });
    setIsModified(true);
  };

  const handleTicketChange = (event, value) => {
    setSelectedTicket(value);
    setFormData({
      ...formData,
      tichetNr: value,
    });
    setIsModified(true);
  };
  const handleTipPenalizareChange = (event, value) => {
    setSelectedTipPenalizare(value);
    setFormData({
      ...formData,
      tipPenalizare: value,
    });
    setIsModified(true);
  };
  const handleDovezi = (event, value) => {
    setSelectedDovezi(value);
    setFormData({
      ...formData,
      dovezi: value,
    });
    setIsModified(true);
  };
  const updateValue = (tableName) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    console.log("21412", tableName);
    const postData = {
      token: token,
      email: email,
      formData: formData,
      tableName: tableName.toUpperCase(),
    };
    // setLoader(true);
    setSelectedOption(null);
    axios
      .post(pathAPI + "/dashboard/penalizari/updateTepe/", postData)
      .then((response) => {
        setAlertNok(true);
        setSuccesMessage(response.data);
        getAllData();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setAlertNok(true);
          setErrorMessages(error.response.data);
          setSuccesMessage(null);
        } else {
          console.error(error);
        }
      });
  };

  const deleteValue = () => {
    setSelectedOption(null);
    setConfirmDialog(null);
    axios
      .post(pathAPI + "/dashboard/penalizari/basicDelete/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
        tableName: tableName.toUpperCase(),
        valueId: selectedOption.id,
      })
      .then(function (response) {
        getAllData();
        setAlertNok(true);
      })
      .catch(function (error) {
        setAlertNok(true);
      });
  };

  return (
    <>
      <Dialog
        className="ticket-dialog"
        open={selectedOption !== null}
        onClose={() => {
          dialogContent();
        }}
      >
        {selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">{selectedOption.agent}</p>
            </div>
            {tableName === "tepe" || tableName === "PENALIZARI" ? (
              <div className="ticket-details" style={{ marginTop: "2rem" }}>
                <Autocomplete
                  size="small"
                  options={agentiData.map((agentiData) => agentiData.name)}
                  value={selectedOption.agent}
                  onChange={handleAgentChange} // Add the change handler
                  isOptionEqualToValue={(option, value) => option !== value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Agent"
                      variant="outlined"
                      required
                    />
                  )}
                />
                <Autocomplete
                  sx={{ marginTop: "0.5rem" }}
                  size="small"
                  options={clientiData}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.NAME;
                  }}
                  value={selectedOption.client}
                  onChange={handleClientChange}
                  isOptionEqualToValue={(option, value) =>
                    option === value || option.inputValue === value.inputValue
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Client"
                      variant="outlined"
                      required
                    />
                  )}
                />
                {tableName === "tepe" ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                      sx={{ marginTop: "0.5rem", marginRight: "0.5rem" }}
                      size="small"
                      name="facturi"
                      label="Facturi"
                      value={formData["facturi"] ?? selectedOption.facturi}
                      onChange={handleInputChange}
                    />

                    <TextField
                      sx={{ marginTop: "0.5rem" }}
                      size="small"
                      name="totalPrejudiciu"
                      label="Total Prejudiciu"
                      value={
                        formData["totalPrejudiciu"] ??
                        selectedOption.totalPrejudiciu
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                ) : (
                  ""
                )}
                <Autocomplete
                  sx={{ marginTop: "0.5rem" }}
                  size="small"
                  options={moneda.map((moneda) => moneda.name)}
                  value={selectedOption.moneda}
                  onChange={handleMonedaChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Moneda"
                      variant="outlined"
                      required
                    />
                  )}
                />
                {tableName === "tepe" ? (
                  <TextField
                    sx={{ marginTop: "0.5rem" }}
                    size="small"
                    name="observatii"
                    label="Observatii"
                    value={formData["observatii"] ?? selectedOption.observatii}
                    onChange={handleInputChange}
                  />
                ) : (
                  ""
                )}
                <Autocomplete
                  sx={{ marginTop: "0.5rem" }}
                  size="small"
                  options={ticketsData.map((ticket) => ticket.ticket_number)}
                  value={selectedOption.tichetNr}
                  onChange={handleTicketChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Ticket#"
                      variant="outlined"
                      required
                    />
                  )}
                />{" "}
                {tableName === "PENALIZARI" ? (
                  <>
                    <Autocomplete
                      sx={{ marginTop: "0.5rem" }}
                      size="small"
                      options={tipPenalizare.map((type) => type.name)}
                      value={selectedOption.tipPenalizare}
                      onChange={handleTipPenalizareChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Tip Penalizare"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                    <Autocomplete
                      sx={{ marginTop: "0.5rem" }}
                      size="small"
                      options={dovezi.map((dovezi) => dovezi.name)}
                      value={selectedOption.dovezi}
                      onChange={handleDovezi}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Dovezi"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        sx={{ marginTop: "0.5rem" }}
                        size="small"
                        name="furnizor"
                        label="Furnizor"
                        value={formData["furnizor"] ?? selectedOption.furnizor}
                        onChange={handleInputChange}
                      />
                      <TextField
                        sx={{ marginTop: "0.5rem" }}
                        size="small"
                        name="sumaPenalizare"
                        label="Suma Penalizare"
                        value={
                          formData["sumaPenalizare"] ??
                          selectedOption.sumaPenalizare
                        }
                        onChange={handleInputChange}
                      />
                      <TextField
                        sx={{ marginTop: "0.5rem" }}
                        size="small"
                        name="rezolvare"
                        label="Rezolvare"
                        value={
                          formData["rezolvare"] ?? selectedOption.rezolvare
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            <div className="ticket-dialog-control">
              <Button
                onClick={() => updateValue(tableName)}
                variant="contained"
                disabled={!isModified}
              >
                Salvează
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setConfirmDialog(selectedOption.facturi)}
              >
                Șterge
              </Button>
              <Button variant="outlined" onClick={() => dialogContent(null)}>
                Anulează
              </Button>
            </div>

            <Divider />
          </div>
        )}
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={confirmDialog !== null}
        onClose={() => setConfirmDialog(null)}
      >
        {confirmDialog !== null && selectedOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">Ștergere valoare</p>
            </div>
            <div className="ticket-details">
              <p>Urmează să ștergi valoarea: {confirmDialog}</p>
            </div>
            <div className="ticket-dialog-control">
              <Button
                variant="outlined"
                color="error"
                onClick={() => deleteValue()}
              >
                Șterge
              </Button>
              <Button variant="outlined" onClick={() => setConfirmDialog(null)}>
                Anulează
              </Button>
            </div>
          </div>
        )}
      </Dialog>
      <GlobalAlert
        openAlert={alertNok}
        setOpenAlert={setAlertNok}
        color={succesMessage ? "success" : "error"}
        message={errorMessage || succesMessage}
      />
    </>
  );
};

export default MainOptiuni;
