import React, { useState } from "react";
import { TextField, IconButton, Button, Divider } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import EditIcon from "@mui/icons-material/Edit";
import { Padding } from "@mui/icons-material";

const filter = createFilterOptions();

const AutocompleteWithCrud = (props) => {
  const {
    data,
    selectedValue,
    setSelectedValue,
    onSelectedOptionChange = () => {}, /// this param is ooptional
    optionName,
    onUpdate,
    label,
    fields,
  } = props;
  const [open, toggleOpen] = React.useState(false);
  const [openDeleteValue, setOpenDeleteValue] = React.useState(false);
  const [deleteOption, setDeleteOption] = React.useState(null);
  const [updatedName, setUpdatedName] = React.useState("");
  const [updatedData, setUpdatedData] = React.useState({});

  const [openEditValue, setopenEditValue] = React.useState(false);
  const [editOption, setEditOption] = React.useState(null);
  const [dialogValue, setDialogValue] = React.useState({
    NAME: "",
  });
  const handleClose = () => {
    setDialogValue({
      NAME: "",
    });
    toggleOpen(false);
  };
  const handleDeleteOption = (option) => {
    setDeleteOption(option);
    setOpenDeleteValue(true);
  };
  const handleEditValue = (option) => {
    setEditOption(option);
    setDialogValue({
      NAME: option[optionName], // Assuming optionName corresponds to "NAME"
    });
    setUpdatedData({}); // Reset updatedData
    setopenEditValue(true);
  };
  const handleOptionInsert = (event, name) => {
    event.preventDefault();
    props.handleOptionInsert(event, name);
    handleClose();
  };

  const handleDeleteData = (option) => {
    props.handleDeleteData(option);
    setOpenDeleteValue(false);
    setopenEditValue(false);
  };

  const updateValue = () => {
    if (editOption && Object.keys(updatedData).length > 0) {
      const updatedId = editOption.id;
      props.onUpdate(updatedId, updatedData);
    }
    setopenEditValue(false);
  };
  const handleInputChange = (field, value) => {
    setDialogValue({
      ...dialogValue,
      [field]: value,
    });
    setUpdatedData({
      ...updatedData,
      [field]: value,
    });
  };
  return (
    <>
      <Autocomplete
        size="small"
        value={selectedValue}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue[optionName],
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              NAME: newValue.inputValue,
            });
          } else {
            setSelectedValue(newValue);
            onSelectedOptionChange(newValue.id);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          if (inputValue !== "") {
            const isExistingOption = options.some((option) =>
              typeof option === "object"
                ? option[optionName] === inputValue
                : option === inputValue
            );

            if (!isExistingOption) {
              filtered.push({
                inputValue: inputValue,
                [optionName]: `Add "${inputValue}"`,
              });
            }
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option[optionName];
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props} style={{ display: "flex", alignItems: "center" }}>
            {option[optionName]}
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
              }}
            >
              <IconButton
                onClick={() => handleEditValue(option)}
                edge="end"
                size="small"
              >
                <EditIcon />
              </IconButton>
            </div>
          </li>
        )}
        freeSolo
        renderInput={(params) => <TextField {...params} label={label} />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={(event) => handleOptionInsert(event, dialogValue)}>
          <DialogTitle>Adauga valoare noua</DialogTitle>
          <DialogContent>
            {fields.map((field, index) => (
              <div style={{ margin: "10px" }}>
                <TextField
                  style={{ marginBottom: "2px" }}
                  key={index}
                  size="small"
                  name={field}
                  className="input-dialog-control"
                  label={field}
                  value={dialogValue[field] ?? ""}
                  onChange={(event) =>
                    setDialogValue({
                      ...dialogValue,
                      [field]: event.target.value,
                    })
                  }
                />
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openDeleteValue}
        onClose={() => setOpenDeleteValue(false)}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirma Stergerea</DialogTitle>
        <DialogContent>
          Esti sigur ca vrei sa stergi valoarea : {deleteOption?.[optionName]}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteValue(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteData(deleteOption);
              setOpenDeleteValue(false);
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className="ticket-dialog"
        open={openEditValue}
        onClose={() => setopenEditValue(false)}
      >
        {editOption !== null && (
          <div className="ticket-container">
            <div className="ticket-header">
              <p className="ticket-subject">
                {"Editeaza valoarea: " + editOption?.[optionName]}
              </p>
            </div>
            <div className="ticket-details">
              {fields.map((field, index) => (
                <TextField
                  style={{ padding: "5px" }}
                  key={index}
                  size="small"
                  name={`valueUpdate-${field}`}
                  className="input-dialog-control"
                  label={field}
                  value={updatedData[field] ?? dialogValue?.[field]}
                  onChange={(event) =>
                    handleInputChange(field, event.target.value)
                  }
                />
              ))}
            </div>
            <div className="ticket-dialog-control">
              <Button onClick={() => updateValue()} variant="contained">
                Salvează
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleDeleteOption(editOption)}
              >
                Șterge
              </Button>
              <Button
                variant="outlined"
                onClick={() => setopenEditValue(false)}
              >
                Anulează
              </Button>
            </div>
            <Divider />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default AutocompleteWithCrud;
