import axios from "axios";
import React, { useEffect, useState } from "react";
import pathAPI from "../../pathAPI";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Tesa = () => {
  const [salariiTesa, setSalariiTesa] = useState([]);

  const getTesa = () => {
    axios
      .post(pathAPI + "/dashboard/salarizare/getTesa/", {
        token: localStorage.getItem("token"),
        email: localStorage.getItem("email"),
      })
      .then(function (response) {
        setSalariiTesa(response.data);
      })
      .catch(function (error) {
        console.log("Request failed.");
      });
  };

  useEffect(() => {
    getTesa();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="Salarii tesa">
          <TableHead>
            <TableRow>
              <TableCell>Nume</TableCell>
              <TableCell>Salariu net</TableCell>
              <TableCell>Ultima actualizare</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salariiTesa.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.nume_prenume}</TableCell>
                <TableCell>{row.salariu_net}</TableCell>
                <TableCell>{row.yearMonth}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="infoTable">
        <span>Rezultate: {salariiTesa.length}</span>
      </div>
    </>
  );
};
export default Tesa;
