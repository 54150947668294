import React, { useEffect } from "react";
import Session from "./../../Components/Session.js";
import axios from "axios";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OpenTicket from "./OpenTicket.jsx";
import Tickets from "./Tickets.jsx";
import OptionsControl from "./OptionsControl.jsx";
import "./Ticketing.css";
import Statistics from "./Statistics.jsx";

const Ticketing = () => {
  var auth = false;
  if (Session([999, 400, 409])) {
    auth = true;
  }

  const [value, setValue] = React.useState("2");
  const [selectedTicketNumber, setSelectedTicketNumber] = React.useState(null);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ticket = urlParams.get("ticket");
    if (ticket) {
      setValue("1");
    }
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function checkRoles(array) {
    let activeRoles = JSON.parse(localStorage.getItem("activeRoles"));
    for (let i = 0; i < array.length; i++) {
      if (activeRoles.includes(array[i])) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {auth ? (
        <div className="box">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Deschide ticket" value="2" />
                <Tab label="Tichete" value="1" />
                {checkRoles([999, 409]) ? (
                  <Tab label="Control opțiuni" value="3" />
                ) : null}
                {checkRoles([999, 409]) ? (
                  <Tab label="Statistici" value="4" />
                ) : null}
              </TabList>
            </Box>
            <TabPanel value="2">
              <OpenTicket
                setSelectedTicketNumber={setSelectedTicketNumber}
                setValue={setValue}
              />
            </TabPanel>
            <TabPanel value="5"></TabPanel>
            <TabPanel value="1">
              <Tickets
                selectedTicketNumber={selectedTicketNumber}
                setSelectedTicketNumber={setSelectedTicketNumber}
                setValue={setValue}
              />
            </TabPanel>
            {checkRoles([999, 409]) ? (
              <TabPanel value="3">
                <OptionsControl />
              </TabPanel>
            ) : null}
            {checkRoles([999, 409]) ? (
              <TabPanel value="4">
                <Statistics />
              </TabPanel>
            ) : null}
          </TabContext>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Ticketing;
