import React, { useState, useEffect } from "react";
// import "./Sidebar.css";
import { UilBars } from "@iconscout/react-unicons";
import { Link, useLocation } from "react-router-dom";
import Session from "../Session.js";
const Sidebar = ({
  handleLinkClick,
  handleOpenMenu,
  isAuth,
  sendThemeMode,
}) => {
  const [themeMode, setThemeMode] = useState(true);
  const [themeStorage, setThemeStorage] = useState(false);
  var auth = false;
  if (Session([999, 666, 222, 1, 333])) {
    auth = true;
  }
  isAuth(auth);

  const handleThemeMode = (event) => {
    const checked = event.target.checked;
    setThemeMode(checked);
    sendThemeMode(checked);
  };

  const checkRls = (arrRls) => {
    var acRls = localStorage.getItem("activeRoles");
    if (acRls !== "undefined") {
      if (JSON.parse(acRls).includes(arrRls)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const location = useLocation();

  useEffect(() => {
    setThemeStorage(localStorage.getItem("themeMode"));
    if (themeStorage) {
      if (themeStorage == "darkMode") {
        setThemeMode(true);
      }
      if (themeStorage == "lightMode") {
        setThemeMode(false);
      }
    }
  }, [themeStorage]);

  return (
    <>
      {auth ? (
        <>
          <div className="sidebar" id="sidebar">
            <div className="logo">
              {localStorage.getItem("themeMode") === "lightMode" ? (
                <img
                  style={{ width: "10rem", marginLeft: "0.5rem" }}
                  alt="EURO TEAM"
                  src="https://euroteamgb.com/wp-content/uploads/2020/12/euroteam_blue@1500x-320x90.png"
                />
              ) : (
                <img
                  style={{ width: "10rem", marginLeft: "0.5rem" }}
                  alt="EURO TEAM Dark Mode"
                  src="https://euroteamgb.com/wp-content/uploads/2020/12/euroteam_white@1500x.png"
                />
              )}
              <div className="toggleSideBar">
                <span id="toggleSideBarMobile">
                  <UilBars onClick={() => handleOpenMenu("")} />
                </span>
              </div>
            </div>
            <div className="navbarContainer">
              <ul className="navbar">
                <li>
                  <Link
                    className={location.pathname === "/" ? "active" : ""}
                    to="/"
                    onClick={() => handleLinkClick("Dashboard")}
                  >
                    <span>Dashboard</span>
                  </Link>
                </li>

                {checkRls(999) || checkRls(850) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/academie" ? "active" : ""
                      }
                      to="/academie"
                      onClick={() => handleLinkClick("Cursuri")}
                    >
                      <span>Academie</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}

                {checkRls(999) || checkRls(333) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/feedback" ? "active" : ""
                      }
                      to="/feedback"
                      onClick={() => handleLinkClick("Feedback")}
                    >
                      <span>Feedback</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) || checkRls(400) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/ticketing" ? "active" : ""
                      }
                      to="/ticketing"
                      onClick={() => handleLinkClick("Ticketing")}
                    >
                      <span>Ticketing</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {/* {checkRls(999) || checkRls(500) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/salarizare" ? "active" : ""
                      }
                      to="/salarizare"
                      onClick={() => handleLinkClick("Salarizare")}
                    >
                      <span>Salarizare</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )} */}
                {checkRls(999) || checkRls(888) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/juridic" ? "active" : ""
                      }
                      to="/juridic"
                      onClick={() => handleLinkClick("Juridic")}
                    >
                      <span>Juridic</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) || checkRls(500) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/salarizareBeta" ? "active" : ""
                      }
                      to="/salarizareBeta"
                      onClick={() => handleLinkClick("SalarizareBeta")}
                    >
                      <span>Salarizare</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/salarizareNew" ? "active" : ""
                      }
                      to="/salarizareNew"
                      onClick={() => handleLinkClick("SalarizareNew")}
                    >
                      <span>SalarizareNew</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) || checkRls(100) ? (
                  <li>
                    <Link
                      className={location.pathname === "/csv" ? "active" : ""}
                      to="/csv"
                      onClick={() => handleLinkClick("CSV Compare")}
                    >
                      <span>CSV Compare</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) || checkRls(800) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/gpsVehicles" ? "active" : ""
                      }
                      to="/gpsVehicles"
                      onClick={() => handleLinkClick("GPS Vehicles")}
                    >
                      <span>GPS Vehicles</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) || checkRls(401) || checkRls(709) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/penalizari" ? "active" : ""
                      }
                      to="/penalizari"
                      onClick={() => handleLinkClick("Penalizari")}
                    >
                      <span>Penalizari</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(1) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/survey" ? "active" : ""
                      }
                      to="/survey"
                      onClick={() => handleLinkClick("Sondaje")}
                    >
                      <span>Sondaje</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) || checkRls(150) ? (
                  <li>
                    <Link
                      className={
                        location.pathname === "/fisiere" ? "active" : ""
                      }
                      to="/fisiere"
                      onClick={() => handleLinkClick("Fisiere")}
                    >
                      <span>Fisiere</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {checkRls(999) || checkRls(439) ? (
                  <>
                    <li>
                      <Link
                        className={
                          location.pathname === "/newsletters" ? "active" : ""
                        }
                        to="/newsletters"
                        onClick={() => handleLinkClick("Newsletters")}
                      >
                        <span>Newsletters</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {checkRls(999) ? (
                  <>
                    <li>
                      <Link
                        className={
                          location.pathname === "/users" ? "active" : ""
                        }
                        to="/users"
                        onClick={() => handleLinkClick("Users")}
                      >
                        <span>Users</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          location.pathname === "/register" ? "active" : ""
                        }
                        to="/register"
                        onClick={() => handleLinkClick("Ad. Utilizator")}
                      >
                        <span>Ad. Utilizator</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {/* <div className="togglerDarkModeLightMode p-4 ">
                  <label className="darkModeToggleLabel">
                    <input
                      id="themeSwitch"
                      type="checkbox"
                      checked={themeMode}
                      onChange={handleThemeMode}
                      class="checkBoxDarkModeInput"
                    ></input>

                    <NightlightIcon className="moon"></NightlightIcon>
                    <LightModeIcon className="sun"></LightModeIcon>

                    <span className="iconToggle"></span>
                  </label>
                </div> */}
              </ul>
            </div>
            <div className="dashboardInfo">
              <div className="infoBox">
                <div className="themeSwitch">
                  <label className="switch">
                    <input
                      id="themeSwitch"
                      type="checkbox"
                      checked={themeMode}
                      onChange={handleThemeMode}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="versionInfo">Version 1.0.0 ice</div>
              </div>
            </div>
          </div>
          {/* <div className="togglerDarkModeLightMode relative end-0 mr-6 w-10">
            <label className="darkModeToggleLabel">
              <input
                id="themeSwitch"
                type="checkbox"
                checked={themeMode}
                onChange={handleThemeMode}
                class="checkBoxDarkModeInput"
              ></input>

              <NightlightIcon className="moon"></NightlightIcon>
              <LightModeIcon className="sun"></LightModeIcon>

              <span className="iconToggle"></span>
            </label>
          </div> */}
          {/* <div className="bars" onClick={() => setExpaned(!expanded)}>
            <UilBars />
          </div>
          <motion.div
            className="sidebar"
            variants={sidebarVariants}
            animate={window.innerWidth <= 768 ? `${expanded}` : ""}
          >

            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>

            <div className="menu">
              {user ? (
                <>
                  <Link className="menuItem" to="#">
                    <UilUsersAlt />
                    <span>Hello, {user}</span>
                  </Link>
                  <Link
                    className={
                      location.pathname === "/" ? "menuItem active" : "menuItem"
                    }
                    to="/"
                  >
                    <UilEstate />
                    <span>Dashboard</span>
                  </Link>
                  {checkRls(999) || checkRls(222) ? (
                    <Link
                      className={
                        location.pathname === "/robot-facturi"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/robot-facturi"
                    >
                      <UilClipboardAlt />
                      <span>Robot Facturi</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) || checkRls(666) ? (
                    <Link
                      className={
                        location.pathname === "/calificare-clienti"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/calificare-clienti"
                    >
                      <UilClipboardAlt />
                      <span>Calificare Clienti</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) || checkRls(333) ? (
                    <Link
                      className={
                        location.pathname === "/feedback"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/feedback"
                    >
                      <UilClipboardAlt />
                      <span>Feedback</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) || checkRls(400) ? (
                    <Link
                      className={
                        location.pathname === "/ticketing"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/ticketing"
                    >
                      <UilClipboardAlt />
                      <span>Ticketing</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) || checkRls(500) ? (
                    <Link
                      className={
                        location.pathname === "/salarizare"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/salarizare"
                    >
                      <UilClipboardAlt />
                      <span>Salarizare</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) || checkRls(333) ? (
                    <Link
                      className={
                        location.pathname === "/csv"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/csv"
                    >
                      <UilClipboardAlt />
                      <span>CSV Compare</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) ? (
                    <Link
                      className={
                        location.pathname === "/gpsVehicles"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/gpsVehicles"
                    >
                      <UilClipboardAlt />
                      <span>GPS Vehicles</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) || checkRls(401) || checkRls(709) ? (
                    <Link
                      className={
                        location.pathname === "/penalizari"
                          ? "menuItem active"
                          : "menuItem"
                      }
                      to="/penalizari"
                    >
                      <UilClipboardAlt />
                      <span>Penalizari</span>
                    </Link>
                  ) : (
                    <></>
                  )}
                  {checkRls(999) ? (
                    <>
                      <Link
                        className={
                          location.pathname === "/users"
                            ? "menuItem active"
                            : "menuItem"
                        }
                        to="/users"
                      >
                        <UilClipboardAlt />
                        <span>Users</span>
                      </Link>
                      <Link
                        className={
                          location.pathname === "/register"
                            ? "menuItem active"
                            : "menuItem"
                        }
                        to="/register"
                      >
                        <UilClipboardAlt />
                        <span>Ad. Utilizator</span>
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <> </>
              )}
              <div onClick={logOut} className="menuItem">
                <UilSignOutAlt />
                <span>Logout</span>
              </div>
            </div>
          </motion.div> */}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sidebar;
